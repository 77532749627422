import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Button, AppBar, Toolbar, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { decodeToken } from 'utils/decodeJWT';
import SuchanaPrakashanService from 'services/suchanaPrakashan';

const useStyles = makeStyles((theme) => ({
  row: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    width: '100%',
  },
  buttonFontSize: {
    fontSize: '14px',
    color: '#ffffff',
    fontWeight: 'bold',
    // margin: '0 5px',
    padding: '10px 15px',
    textShadow: '0px 2px 4px #08e',

    '&:hover': {
      background: '#00A10D',
      transition: 'all .3s',
    },
  },

  AppBar: {
    backgroundColor: theme.palette.primary.main,
    // backgroundSize: 'cover',
  },
  loginButton: {
    background: 'rgba(125, 125, 125, 0.4)',
    color: '#fff',
    borderRadius: '25px',
    padding: '10px 25px',

    '&:hover': {
      background: '#879565',
      boxShadow: '0px 2px 10px #888888',
    },
  },
  menu: {
    marginTop: 45, paddingTop: '0 !important', paddingBottom: '0 !important',
  },
  menuItem: {
	  backgroundColor: '#fff', color: '#141',
	  '&hover': {
	  	backgroundColor: '#aba',
	  }
  }
}));

const SearchAppBar = () => {
  const history = useHistory();

  const [username, setUsername] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [prakashanList, setPrakashanList] = useState(null);

  const logout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    setUsername(null);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      let decoded = decodeToken(accessToken);
      setUsername(decoded.username);

      if (Date.now() / 1000 < decoded.exp) {
        setIsLoggedIn(true);
      }
    }
  }, []);

  useEffect(() => {
    const fetchPrakashanList = async () => {
      let prakashanResult = await SuchanaPrakashanService.getPrakashanList();

      setPrakashanList(prakashanResult.results);
    };

    fetchPrakashanList();
  }, []);

  const classes = useStyles();

  function refreshPage() {
    window.location.reload();
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePrakashanClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePrakashanClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <AppBar position="static" color="default" className={classes.AppBar}>
        <Grid item sm={12} xs={12} className={classes.container}>
          <Toolbar>
            <Grid className={classes.grow}>
              <Button
                color="inherit"
                onClick={() => {
                  history.push('/');
                  refreshPage();
                }}
                className={classes.buttonFontSize}
              >
                गृह पृष्ट
              </Button>
              {isLoggedIn && (
                <>
                  <Button
                    color="inherit"
                    onClick={() => history.push('/data-entry-list')}
                    className={classes.buttonFontSize}
                  >
                    डाटा ईन्ट्री
                  </Button>
                  <Button color="inherit" onClick={() => history.push('/details')} className={classes.buttonFontSize}>
                    विवरण
                  </Button>
                </>
              )}
              <Button color="inherit" onClick={() => history.push('/suchana')} className={classes.buttonFontSize}>
                सूचना
              </Button>
              <Button color="inherit" onClick={handlePrakashanClick} className={classes.buttonFontSize}>
                प्रकाशन <i className="fa fa-caret-down" style={{ marginLeft: 5 }}></i>
              </Button>
              <Menu
                id="prakashan-menu"
                className={classes.menu}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handlePrakashanClose}
              >
                {prakashanList &&
                  prakashanList.map((item, index) => {
                    const id = item['id'];
                    const category = item['category_title'];
                    return (
                      <MenuItem
                        className={classes.menuItem}
                        onClick={() => history.push(`/prakashan?id=${id}&category=${category}`)}
                        key={id}
                      >
                        {category}
                      </MenuItem>
                    );
                  })}
              </Menu>
              <Button color="inherit" onClick={() => history.push('/contact-us')} className={classes.buttonFontSize}>
                सम्पर्क
              </Button>
            </Grid>

            {isLoggedIn ? (
              <>
                <Button color="inherit" className={classes.buttonFontSize}>
                  {username}
                </Button>
                <Button color="inherit" onClick={logout} className={[classes.buttonFontSize, classes.loginButton]}>
                  बाहिर
                </Button>
              </>
            ) : (
              <Button
                color="inherit"
                onClick={() => history.push('/login')}
                className={[classes.buttonFontSize, classes.loginButton]}
              >
                लग-इन गर्नुहोस्
              </Button>
            )}
          </Toolbar>
        </Grid>
      </AppBar>
    </div>
  );
};

export default SearchAppBar;
