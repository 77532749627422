const animalsIcons = {
  'गाई': 'https://images.unsplash.com/photo-1563308294-6d63bb78d61e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'दुध दिने गाई': 'https://images.unsplash.com/photo-1573731399281-6540bc50ed91?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'भैंसी': 'https://images.unsplash.com/photo-1615715106360-cc53a07be485?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'दुध दिने भैंसी': 'https://images.unsplash.com/photo-1521140714186-512186ea3d3b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'भेंडा': 'https://images.unsplash.com/photo-1554755209-85e44182e019?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'बाख्रा': 'https://images.unsplash.com/photo-1533072665733-5c736d26b63b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'बोका/खसी': 'https://images.unsplash.com/photo-1524024973431-2ad916746881?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'बंगुर': 'https://images.unsplash.com/photo-1543360308-efbc91e9247f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'कुखुरा': 'https://images.unsplash.com/photo-1569396327972-6231a5b05ea8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'फुल पार्ने कुखुरा': 'https://images.unsplash.com/photo-1569396327972-6231a5b05ea8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'हांस': 'https://images.unsplash.com/photo-1465153690352-10c1b29577f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'फुल पार्ने हांस': 'https://images.unsplash.com/photo-1551712345-d33b4e6afb69?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'खरायो': 'https://images.unsplash.com/photo-1585110396000-c9ffd4e4b308?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'माछा': 'https://images.unsplash.com/photo-1522069169874-c58ec4b76be5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
}

const foodCropsNameList = {
  'धान': 'https://images.unsplash.com/photo-1564493031643-4be2c4347a17?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'मकै': 'https://images.unsplash.com/photo-1604343574001-a9d95eb6384a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'गहुँ': 'https://images.unsplash.com/photo-1437252611977-07f74518abd7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'कोदो': 'https://images.unsplash.com/photo-1602018342965-6dd19657e145?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'जौँ': 'https://images.unsplash.com/photo-1415381850596-1d29bce989f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'फापर': 'https://images.unsplash.com/photo-1613758235306-69cc0a3f614d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
};

const cashCropsNameList = {
  'तेलहन': 'https://images.unsplash.com/photo-1588144214291-01f2808940fc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'आलु': 'https://images.unsplash.com/photo-1518977676601-b53f82aba655?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'रवर': 'https://images.unsplash.com/photo-1621842778584-23948f2dbcc7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'ऊखु': 'https://images.unsplash.com/photo-1605810978644-1fa3b63ecd4e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'जुट': 'https://images.unsplash.com/photo-1610428011552-734764c290ee?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'कपास': 'https://images.unsplash.com/photo-1577451345851-8ef9c2c1c97f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
};

const beanCropsNameList = {
  'मुसुरो': 'https://images.unsplash.com/photo-1614373532201-c40b993f0013?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
  'चना': 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fevegro.com%2Fwp-content%2Fuploads%2F2020%2F07%2F500-channa-brown-chana-flipkart-channa-dal-original-imaeymjjzzzn6xz9.jpeg&f=1&nofb=1',
  'रहर': 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fkhetibazaar.com%2Fwp-content%2Fuploads%2F2019%2F07%2Fyellow-lentils-arhar-dal-500x500.jpg&f=1&nofb=1',
  'मास': 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F1.bp.blogspot.com%2F-wnYbCyphRUI%2FUF5UzatO9MI%2FAAAAAAAAPzA%2FEPQh2Z4FJYY%2Fs1600%2F1-IMG_5355.JPG&f=1&nofb=1',
  'खेसरी': 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi1.wp.com%2Fsarahmir.files.wordpress.com%2F2013%2F04%2Fimag0270.jpg&f=1&nofb=1',
  'गहत': 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fthulo.com%2Fimages%2Fdetailed%2F154%2F2g_l8on-mt.jpg&f=1&nofb=1',
  'भटमास': 'https://images.unsplash.com/photo-1572457224112-06d191bb6d01?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=50&q=80',
};

const otherCropsNameList = {
  'फलफुल': 'https://images.unsplash.com/photo-1552841847-0e031d33a283?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  'तरकारी': 'https://images.unsplash.com/photo-1574316071802-0d684efa7bf5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  'चिया': 'https://images.unsplash.com/photo-1602943543714-cf535b048440?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  'कफी': 'https://images.unsplash.com/photo-1598198152469-172cd80d7a24?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  'खुर्सानी': 'https://images.unsplash.com/photo-1518006959466-0db0b6b4c1d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  'अलैची': 'https://images.unsplash.com/photo-1613634710886-62212b2af1b1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  'अदुवा': 'https://images.unsplash.com/photo-1598404594102-a633f3adaa30?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  'लसुन': 'https://images.unsplash.com/photo-1541788707294-a1cb80d42dce?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  'बेसार': 'https://images.unsplash.com/photo-1615485500834-bc10199bc727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
};


const cropsIcons = {...foodCropsNameList, ...cashCropsNameList, ...beanCropsNameList, ...otherCropsNameList};

export default {
  animalsIcons,
  cropsIcons,
}
