const config = {
  officeName: 'उदयपुरगढी गाउँपालिका, गाउँकार्यपालिकाको कार्यालय',
  appName: 'कृषि तथा पशुपन्छी तथ्याङ्क व्यवस्थापन प्रणाली',
  provinceName: 'कोशी प्रदेश',
  officeLocation: 'उदयपुर, नेपाल',
  officeContact: '027-691276',
  officeEmail: 'udayapurgadhirm@gmail.com',
  url: 'udaypurgadhikrisibibhag.com/',
};

export default config;
