import { Button, Container } from '@material-ui/core';
import SearchAppBar from 'components/Appbar';
import Banner from 'components/Banner';
import Copyright from 'components/Copyright';
import AdminBudgetForm from 'components/Forms/admin/AdminBudgetForm';
import PrastabanaAdminForm from 'components/Forms/admin/PrastabanaAdmin';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import PrastabanaService from 'services/prastabana';
import PrastabanaDoc from './PrastabanaDoc';
import AdminAgreementPrastabana from 'components/Forms/admin/AdminAgreementPrastabana';
import ApplicationAdmin from 'components/Forms/admin/ApplicationAdmin';
import OfficeDetails from 'components/Forms/admin/OfficeDetails';
import ApplicationEvaluation from 'components/Forms/admin/ApplicationEvaludation';
import MachineForm from 'components/Forms/admin/MachineForm';
import OtherSite from 'components/Forms/admin/OtherSite';
import AppBusiness from 'components/Forms/admin/AppBusiness';
import ApplicatioAgreementForm from 'components/Forms/admin/ApplicatioAgreementForm';
import AdminActivity from 'components/Forms/admin/AdminActivity';
import ApplicationDoc from './ApplicationDoc';

const SingleApplication = () => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(2);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await PrastabanaService.getApplicationData();
      if (res?.count) {
        setActiveStep(2);
      }
      const adminBudget = await PrastabanaService.getApplicationAdminListView();
      if (adminBudget?.count) {
        setActiveStep(3);
      }
      const agreement = await PrastabanaService.getOfficialDetailView();
      if (agreement?.count) {
        setActiveStep(4);
      }
      const evalu = await PrastabanaService.getApplicantEvaluationView();
      if (evalu?.count) {
        setActiveStep(5);
      }
      const machine = await PrastabanaService.getMachineryEquipmentView();
      if (machine?.count) {
        setActiveStep(6);
      }
      const other = await PrastabanaService.getOtherSiteVerificationDetailView();
      if (other?.count) {
        setActiveStep(7);
      }
      const busi = await PrastabanaService.getApplicantBusinessEvaluationView();
      if (busi?.count) {
        setActiveStep(8);
      }
      const agree = await PrastabanaService.getApplicationAgreementView();
      if (agree?.count) {
        setActiveStep(9);
      }
      const acti = await PrastabanaService.getActivityView();
      if (acti?.count) {
        setActiveStep(10);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading</div>;
  }
  const openModal = () => {
    setOpen(true);
  };
  return (
    <div>
      <Banner />
      <SearchAppBar title="दर्ता भएको डाटा" />
      <Container maxWidth="lg" style={{ marginTop: 50 }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="contained" onClick={openModal}>
            Open details
          </Button>
        </div>
        {activeStep === 2 && <ApplicationAdmin id={id} setActiveStep={setActiveStep} />}
        {activeStep === 3 && <OfficeDetails id={id} setActiveStep={setActiveStep} />}
        {activeStep === 4 && <ApplicationEvaluation id={id} setActiveStep={setActiveStep} />}
        {activeStep === 5 && <MachineForm id={id} setActiveStep={setActiveStep} />}
        {activeStep === 6 && <OtherSite id={id} setActiveStep={setActiveStep} />}
        {activeStep === 7 && <AppBusiness id={id} setActiveStep={setActiveStep} />}
        {activeStep === 8 && <ApplicatioAgreementForm id={id} setActiveStep={setActiveStep} />}
        {activeStep === 9 && <AdminActivity id={id} setActiveStep={setActiveStep} />}
        {activeStep === 10 && <div>Submitted</div>}
        {open && <ApplicationDoc id={id} open={open} setOpen={setOpen} />}
      </Container>
      <Copyright website="उदयपुरगढी गाउँपालिका" link="#" />
    </div>
  );
};

export default SingleApplication;
