import { Button, ButtonGroup, CircularProgress, Grid, MenuItem, TextField, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import KrishiService from 'services/krishiService';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const WorkCost = ({ setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [projectList, setProjectList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await KrishiService.getIrri();
      setProjectList(res?.results);
    };
    fetchData();
  }, []);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          beneficiary_group_activities: '',
          cost: '',
          irrigation_application: projectList && projectList[0]?.id,
          item_description: '',
          office_activities: '',
          quantity: '',
          person: getUserInfo().person_id,
        }}
        onSubmit={async (values) => {
          try {
            const response = await KrishiService.addWorkCost(values);
            if (response?.id) {
              setActiveStep(4);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="beneficiary_group_activities"
                name="beneficiary_group_activities"
                label="लाभग्राही समूहको क्रियाकलापहरू"
                fullWidth
                required
                value={formik.values.beneficiary_group_activities}
                onChange={formik.handleChange}
                error={
                  formik.touched.beneficiary_group_activities && Boolean(formik.errors.beneficiary_group_activities)
                }
                helperText={formik.touched.beneficiary_group_activities && formik.errors.beneficiary_group_activities}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="cost"
                name="cost"
                label="लागत"
                fullWidth
                type="number"
                required
                value={formik.values.cost}
                onChange={formik.handleChange}
                error={formik.touched.cost && Boolean(formik.errors.cost)}
                helperText={formik.touched.cost && formik.errors.cost}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="item_description"
                name="item_description"
                label="वस्तुको विवरण"
                fullWidth
                required
                value={formik.values.item_description}
                onChange={formik.handleChange}
                error={formik.touched.item_description && Boolean(formik.errors.item_description)}
                helperText={formik.touched.item_description && formik.errors.item_description}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="office_activities"
                name="office_activities"
                label="कार्यालयको क्रियाकलापहरू"
                fullWidth
                required
                value={formik.values.office_activities}
                onChange={formik.handleChange}
                error={formik.touched.office_activities && Boolean(formik.errors.office_activities)}
                helperText={formik.touched.office_activities && formik.errors.office_activities}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="quantity"
                name="quantity"
                label="मात्रा"
                fullWidth
                required
                type="number"
                value={formik.values.quantity}
                onChange={formik.handleChange}
                error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                helperText={formik.touched.quantity && formik.errors.quantity}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                  बुझाउनुहोस् &nbsp;
                  {isDisabled ? <CircularProgress size={30} /> : null}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default WorkCost;
