export default {
  'लुम्बिनी प्रदेश': {
    कपिलवस्तु: [
      'कपिलवस्तु',
      'बाणगंगा',
      'शिवराज',
      'बुद्धभूमी',
      'कृष्णनगर',
      'महाराजगंज',
      'मायादेवी',
      'शुद्धोधन',
      'यसोधरा',
      'विजयनगर',
    ],
    परासी: [
      'रामग्राम',
      'सुनवल',
      'बर्दघाट',
      'त्रिवेणी सुस्ता',
      'प्रतापपुर',
      'सरावल',
      'पाल्हीनन्दन',
    ],
    रुपन्देही: [
      'बुटवल',
      'तिलोत्तमा',
      'लुम्बिनी सांस्कृतिक',
      'सिद्धार्थनगर',
      'सैनामैना',
      'देवदह',
      'गैडहवा',
      'मायादेवी',
      'कोटहीमाई',
      'मर्चवारीमाई',
      'सियारी',
      'सम्मरीमाई',
      'रोहिणी',
      'शुद्धोधन',
      'ओमसतीया',
      'कञ्चन',
    ],
    अर्घाखाँची: [
      'सितगंगा',
      'सन्धिखर्क',
      'भूमिकास्थान',
      'मालारानी',
      'पाणिनी',
      'छत्रदेव',
    ],
    गुल्मी: [
      'मुसिकोट',
      'रेसुङ्गा',
      'सत्यवती',
      'धुर्कोट',
      'गुल्मीदरवार',
      'मदाने',
      'चन्द्रकोट',
      'मालिका',
      'छत्रकोट',
      'ईस्मा',
      'कालीगण्डकी',
      'रुरु',
    ],
    पाल्पा: [
      'तानसेन',
      'रामपुर',
      'रैनादेवी छहरा',
      'माथागढी',
      'निस्दी',
      'वगनासकाली',
      'रम्भा',
      'पूर्वखोला',
      'तिनाउ',
      'रिब्दीकोट',
    ],
    'दाङ देउखुरी': [
      'घोराही',
      'तुल्सिपुर',
      'लमही',
      'राप्ती',
      'गढवा',
      'बबई',
      'शान्तिनगर',
      'राजपुर',
      'वंगलाचुली',
      'दंगीशरण',
    ],
    प्युठान: [
      'प्यूठान',
      'स्वर्गद्वारी',
      'नौबहिनी',
      'झिमरुक',
      'गौमुखी',
      'ऐरावती',
      'सरुमारानी',
      'मल्लरानी',
      'माण्डवी',
    ],
    रोल्पा: [
      'रोल्पा',
      'सुनिलस्मृति',
      'रुन्टीगढी',
      'लुङ्ग्री',
      'त्रिवेणी',
      'परिवर्तन',
      'गंगादेव',
      'माडी',
      'सुनछहरी',
      'थवाङ',
    ],
    'पूर्वी रूकुम': ['भूमे', 'पुठा उत्तरगंगा', 'सिस्ने'],
    बाँके: [
      'नेपालगञ्ज',
      'कोहलपुर',
      'राप्ती सोनारी',
      'वैजनाथ',
      'खजुरा',
      'जानकी',
      'डुडुवा',
      'नरैनापुर',
    ],
    बर्दिया: [
      'बारबर्दिया',
      'गुलरिया',
      'राजापुर',
      'बासगढी',
      'मधुवन',
      'ठाकुरबाबा',
      'बढैयाताल',
      'गेरुवा',
    ],
  },
};
