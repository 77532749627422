import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   light: "#c7d167",
    //   main: "#879565",
    //   dark: "#2d4e4a",
    //   contrastText: "#fff",
    // },
    // secondary: {
    //   light: "#D3A699",
    //   main: "#724f58",
    //   dark: "#4e282d",
    //   contrastText: "#000",
    // },

    primary: {
      light: "#c7d167",
      main: "#006600",
      dark: "#2d4e4a",
      contrastText: "#fff",
    },
  },
  // fontFamily: font, // as an aside, highly recommend importing roboto font for Material UI projects! Looks really nice
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
