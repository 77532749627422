import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import 'assets/css/table.css';
import { Button, ButtonGroup, FormControl, FormHelperText, Grid, InputLabel, Select } from '@material-ui/core';
import SamuhaSahakariFarmService from 'services/samuhaSahakariFarm';
import { getInputField, getSelectField } from './Land';

import AlertDialog from 'components/AlertDialog';

import { getProvinces, getDistricts, getPalikas } from 'constants/predefinedLists/addresses';

const SamuhaSahakariFarm = () => {
  const history = useHistory();

  const stateVariable = {
    category: null,
    name: null,
    state: null,
    district: null,
    palika: null,
    wardNo: null,
    tole: null,
    panNo: null,
    panRegistrationDate: null,
    registrationNo: null,
    registrationDate: null,
    contactPersonName: null,
    contactNo: null,
  };

  const [myState, setMyState] = useState({ ...stateVariable });

  const [openDialog, setOpenDialog] = useState(false);
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  const onChange = (key, value) => {
    myState[key] = value;
    setMyState({ ...myState });
  };

  const submit = async () => {
    try {
      setIsDisabled(true);
      await SamuhaSahakariFarmService.createData(myState);
      setIsSuccessDialog(true);
      setOpenDialog(true);
      history.push('/samuha-sahakari-farm-entry');
    } catch (e) {
      setIsDisabled(false);
      setIsSuccessDialog(false);
      setOpenDialog(true);
    }
  };

  return (
    <div>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Grid item xs={12}>
        <h3 style={{ marginBottom: '10px' }}>कृषि तथा पशुपालनमा सक्रिय सेवा प्रदायक (समूह/सहकारी/फरम)को विवरण</h3>
        <table className="table">
          <tr>
            <td colSpan="3">
              <FormControl required style={{ width: '24ch' }}>
                <InputLabel htmlFor="category">संगठनको प्रकार</InputLabel>
                <Select native value={myState.category} onChange={(e) => onChange('category', e.target.value)}>
                  <option aria-label="None" value="" />
                  <option value="समूह">समूह</option>
                  <option value="सहकारी">सहकारी</option>
                  <option value="फरम">फरम</option>
                </Select>
                <FormHelperText>
                  (समूह/सहकारी/फरम)
                  <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>
                </FormHelperText>
              </FormControl>
            </td>
          </tr>
          <tr>
            <td colSpan="3">{getInputField('', 'संगठनको नाम', 'name', myState.name, onChange, true)}</td>
          </tr>
          <tr>
            <td colSpan="2">
              {getInputField(
                'सम्पर्क व्यक्ति',
                'सम्पर्क व्यक्ति: नाम',
                'contactPersonName',
                myState.contactPersonName,
                onChange
              )}
            </td>
            <td>{getInputField('संपर्क नम्बर', 'फोन नम्बर', 'contactNo', myState.contactNo, onChange)}</td>
          </tr>
          <tr>
            <td>{getInputField('पान नं.', 'पान नं.', 'panNo', myState.panNo, onChange)}</td>
            <td colSpan="2">
              {getInputField(
                'दर्ता मिति',
                'पान नं. दर्ता गरिएको मिति',
                'panRegistrationDate',
                myState.panRegistrationDate,
                onChange
              )}
            </td>
          </tr>
          <tr>
            <td>{getInputField('दर्ता नं.', 'दर्ता नं.', 'registrationNo', myState.registrationNo, onChange)}</td>
            <td colSpan="2">
              {getInputField(
                'दर्ता मिति',
                'दर्ता नं. दर्ता गरिएको मिति',
                'registrationDate',
                myState.registrationDate,
                onChange
              )}
            </td>
          </tr>
          <tr>
            <td>
              {getSelectField(
                'प्रदेश',
                getProvinces(),
                'state',
                myState.state,
                (key, value) => {
                  myState.district = null;
                  myState.palika = null;
                  onChange(key, value);
                },
                true
              )}
            </td>
            <td colSpan="2">
              {getSelectField(
                'जिल्ला',
                myState.state ? getDistricts(myState.state) : [],
                'district',
                myState.district,
                (key, value) => {
                  myState.palika = null;
                  onChange(key, value);
                },
                true
              )}
            </td>
          </tr>
          <tr>
            <td>
              {getSelectField(
                'पालिका',
                myState.state && myState.district ? getPalikas(myState.state, myState.district) : [],
                'palika',
                myState.palika,
                onChange,
                true
              )}
            </td>
            <td>{getInputField('वडा', 'वडा', 'wardNo', myState.wardNo, onChange, false, 'number')}</td>
            <td>{getInputField('टोल', 'टोल', 'tole', myState.tole, onChange)}</td>
          </tr>
        </table>
        <ButtonGroup color="primary" aria-label="primary button group" style={{ margin: '20px 0', marginTop: 25 }}>
          <Button onClick={() => submit()} disabled={isDisabled}>
            बुझाउनुहोस् &nbsp;
            {isDisabled ? <CircularProgress size={30} /> : null}
          </Button>
        </ButtonGroup>
        <ButtonGroup color="primary" aria-label="primary button group" style={{ margin: '20px 10px', marginTop: 25 }}>
          <Button
            onClick={async () => {
              await history.push('/data-entry-list');
              await history.push('/samuha-sahakari-farm-entry');
            }}
            disabled={isDisabled}
          >
            नयाँ संस्था ईन्ट्री &nbsp;
            {isDisabled ? <CircularProgress size={30} /> : null}
          </Button>
        </ButtonGroup>
      </Grid>
    </div>
  );
};

export default SamuhaSahakariFarm;
