import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import KrishiService from 'services/krishiService';
import { getUserInfo } from 'utils/decodeJWT';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const fields = [
  { id: 'disputes_downstream', label: 'disputes_downstream', type: 'boolean' },
  { id: 'disputes_upstream', label: 'disputes_upstream', type: 'boolean' },
  {
    id: 'duration_of_flow',
    label: 'duration_of_flow',
    type: 'select',

    options: [
      {
        label: 'वर्ष भरी',
        id: 'A',
      },
      {
        label: '६ महिना',
        id: 'B',
      },
      {
        label: 'मौसमी',
        id: 'C',
      },
    ],
  },
  { id: 'upper_system_distance_km', label: 'upper_system_distance_km', type: 'boolean' },
];
const WaterExtraForm = ({ setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [projectList, setProjectList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await KrishiService.getIrri();
      setProjectList(res?.results);
    };
    fetchData();
  }, []);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          irrigation_application: projectList && projectList[0]?.id,
          disputes_downstream: false,
          disputes_upstream: false,
          duration_of_flow: '',
          other_systems_nearby: false,
          person: getUserInfo().person_id,
        }}
        onSubmit={async (values) => {
          try {
            const response = await KrishiService.addWaterExtra(values);
            if (response?.id) {
              setActiveStep(10);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            {fields.map((field) => {
              if (field?.type === 'boolean') {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.maintenance_fund_available}
                          onChange={formik.handleChange}
                          name={field.id}
                          color="primary"
                        />
                      }
                      label={field.label}
                    />
                    {formik.touched.maintenance_fund_available && formik.errors.maintenance_fund_available && (
                      <div style={{ color: 'red', marginTop: '8px' }}>{formik.errors.maintenance_fund_available}</div>
                    )}
                  </Grid>
                );
              } else if (field.type === 'select') {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <TextField
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      fullWidth
                      value={formik.values[field.id]}
                      onChange={formik.handleChange}
                      select
                      error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                      helperText={formik.touched[field.id] && formik.errors[field.id]}
                    >
                      {field.options?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <TextField
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      fullWidth
                      type={field.type === 'number' ? 'number' : 'text'}
                      value={formik.values[field.id]}
                      onChange={formik.handleChange}
                      error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                      helperText={formik.touched[field.id] && formik.errors[field.id]}
                    />
                  </Grid>
                );
              }
            })}
            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                  बुझाउनुहोस् &nbsp;
                  {isDisabled ? <CircularProgress size={30} /> : null}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default WaterExtraForm;
