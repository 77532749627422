import axios from 'axios';

const baseURI = process.env.REACT_APP_API_BASE_URI+'api/';
const url = baseURI + 'statistics/';

class StatisticService {
  static async getAllStatistics() {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}

export default StatisticService;
