import { Grid, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useState } from 'react';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';
import * as Yup from 'yup';
import RenderInput from './RenderInput';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const fields = [
  { id: 'physical_precursors', label: 'भौतिक पूर्वावस्था', type: 'text' },
  { id: 'boring_motor_connection_number', label: 'बोरिङ मोटरको कनेक्सन संख्या', type: 'number' },
  { id: 'boring_motor_is_the_area', label: 'बोरिङ मोटरको क्षेत्र', type: 'text' },
  { id: 'boring_motor_condition', label: 'बोरिङ मोटरको अवस्था', type: 'text' },
  { id: 'tunnel_constrution_number', label: 'सुरंग निर्माणको संख्या', type: 'number' },
  { id: 'tunnel_constrution_is_the_area', label: 'सुरंग निर्माणको क्षेत्र', type: 'text' },
  { id: 'tunnel_constrution_condition', label: 'सुरंग निर्माणको अवस्था', type: 'text' },
  { id: 'irrigation_connection_number', label: 'सिंचाइ जडानको संख्या', type: 'number' },
  { id: 'irrigation_connection_is_the_area', label: 'सिंचाइ जडानको क्षेत्र', type: 'text' },
  { id: 'irrigation_connection_condition', label: 'सिंचाइ जडानको अवस्था', type: 'text' },
  { id: 'compost_pit_number', label: 'कम्पोस्ट पिटको संख्या', type: 'number' },
  { id: 'compost_pit_is_the_area', label: 'कम्पोस्ट पिटको क्षेत्र', type: 'text' },
  { id: 'compost_pit_condition', label: 'कम्पोस्ट पिटको अवस्था', type: 'text' },
  { id: 'correction_number', label: 'सुधारको संख्या', type: 'number' },
  { id: 'correction_is_the_area', label: 'सुधारको क्षेत्र', type: 'text' },
  { id: 'correction_condition', label: 'सुधारको अवस्था', type: 'text' },
  { id: 'fish_pond_number', label: 'माछा पोंडको संख्या', type: 'number' },
  { id: 'fish_pond_is_the_area', label: 'माछा पोंडको क्षेत्र', type: 'text' },
  { id: 'fish_pond_condition', label: 'माछा पोंडको अवस्था', type: 'text' },
  { id: 'mushroom_house_number', label: 'कुकुरमुट्टा घरको संख्या', type: 'number' },
  { id: 'mushroom_house_is_the_area', label: 'कुकुरमुट्टा घरको क्षेत्र', type: 'text' },
  { id: 'mushroom_house_condition', label: 'कुकुरमुट्टा घरको अवस्था', type: 'text' },
  { id: 'bee_hive_number', label: 'महिल्ला घरको संख्या', type: 'number' },
  { id: 'bee_hive_is_the_area', label: 'महिल्ला घरको क्षेत्र', type: 'text' },
  { id: 'bee_hive_condition', label: 'महिल्ला घरको अवस्था', type: 'text' },
  { id: 'nursery_house_number', label: 'नर्सरी घरको संख्या', type: 'number' },
  { id: 'nursery_house_is_the_area', label: 'नर्सरी घरको क्षेत्र', type: 'text' },
  { id: 'nurserty_house_condition', label: 'नर्सरी घरको अवस्था', type: 'text' },
  { id: 'cultivation_land_number', label: 'खेती भूमि संख्या', type: 'number' },
  { id: 'cultivation_land_is_the_area', label: 'खेती भूमि क्षेत्र', type: 'text' },
  { id: 'cultivation_land_condition', label: 'खेती भूमि अवस्था', type: 'text' },
];

const ApplicationPhysicalForm = ({ applicationDetails, setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  const getValidationSchema = (fields) => {
    const shape = {};
    fields.forEach((field) => {
      shape[field.id] = Yup.string().required(`${field.label} is required`);
    });
    return Yup.object().shape(shape);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Grid item xs={12}>
        <h3>भौतिक पूर्वधार</h3>
      </Grid>
      <Formik
        initialValues={{
          app: applicationDetails && applicationDetails?.id,
          physical_precursors: '',
          boring_motor_connection_number: '',
          boring_motor_is_the_area: '',
          boring_motor_condition: '',
          tunnel_constrution_number: '',
          tunnel_constrution_is_the_area: '',
          tunnel_constrution_condition: '',
          irrigation_connection_number: '',
          irrigation_connection_is_the_area: '',
          irrigation_connection_condition: '',
          compost_pit_number: '',
          compost_pit_is_the_area: '',
          compost_pit_condition: '',
          correction_number: '',
          correction_is_the_area: '',
          correction_condition: '',
          fish_pond_number: '',
          fish_pond_is_the_area: '',
          fish_pond_condition: '',
          mushroom_house_number: '',
          mushroom_house_is_the_area: '',
          mushroom_house_condition: '',
          bee_hive_number: '',
          bee_hive_is_the_area: '',
          bee_hive_condition: '',
          nursery_house_number: '',
          nursery_house_is_the_area: '',
          nurserty_house_condition: '',
          cultivation_land_number: '',
          cultivation_land_is_the_area: '',
          cultivation_land_condition: '',
          person: getUserInfo().person_id,
        }}
        validationSchema={getValidationSchema(fields)}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            const response = await PrastabanaService.addPhysicalData(values);
            if (response?.id) {
              setActiveStep(9);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
      >
        {(formik) => <RenderInput fields={fields} formik={formik} isDisabled={isDisabled} />}
      </Formik>
    </div>
  );
};

export default ApplicationPhysicalForm;
