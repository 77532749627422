import Pradesh1 from './provinces/pradesh1';
import Pradesh2 from './provinces/pradesh2';
import Pradesh3 from './provinces/pradesh3';
import Pradesh4 from './provinces/pradesh4';
import Pradesh5 from './provinces/pradesh5';
import Pradesh6 from './provinces/pradesh6';
import Pradesh7 from './provinces/pradesh7';

const addresses = {
  ...Pradesh1,
  ...Pradesh2,
  ...Pradesh3,
  ...Pradesh4,
  ...Pradesh5,
  ...Pradesh6,
  ...Pradesh7,
};
const districts = [
  ...Object.keys({...Pradesh1['प्रदेश नं- १']}),
  ...Object.keys({...Pradesh2['प्रदेश नं- २']}),
  ...Object.keys({...Pradesh3['बागमती प्रदेश']}),
  ...Object.keys({...Pradesh4['गण्डकी प्रदेश']}),
  ...Object.keys({...Pradesh5['लुम्बिनी प्रदेश']}),
  ...Object.keys({...Pradesh6['कर्णाली प्रदेश']}),
  ...Object.keys({...Pradesh7['सुदूर-पश्चिम प्रदेश']}),
]

export const getProvinces = () => Object.keys(addresses);
export const getDistricts = (province) => Object.keys(addresses[province]);
export const getAllDistricts = () => districts;
export const getPalikas = (province, district) => addresses[province][district];

// Usage examples
// console.log(getProvinces());
// console.log(getDistricts('प्रदेश नं- १'));
// console.log(getPalikas('प्रदेश नं- १', 'धनकुटा'));
