export default {
  'कर्णाली प्रदेश': {
    'पश्चिमी रूकुम': [
      'आठबिसकोट',
      'मुसिकोट',
      'चौरजहारी',
      'सानीभेरी',
      'त्रिवेणी',
      'बाँफिकोट',
    ],
    सल्यान: [
      'बनगाड कुपिण्डे',
      'बागचौर',
      'शारदा',
      'कुमाख',
      'कालीमाटी',
      'छत्रेश्वरी',
      'दार्मा',
      'कपुरकोट',
      'त्रिवेणी',
      'सिद्ध कुमाख',
    ],
    डोल्पा: [
      'त्रिपुरासुन्दरी',
      'ठूली भेरी',
      'मुड्केचुला',
      'काईके',
      'शे फोक्सुन्डो',
      'जगदुल्ला',
      'डोल्पो बुद्ध',
      'छार्का ताङसोङ',
    ],
    हुम्ला: [
      'सिमकोट',
      'सर्केगाड',
      'अदानचुली',
      'खार्पुनाथ',
      'ताँजाकोट',
      'चंखेली',
      'नाम्खा',
    ],
    जुम्ला: [
      'चन्दननाथ',
      'तातोपानी',
      'पातारासी',
      'तिला',
      'कनकासुन्दरी',
      'सिंजा',
      'हिमा',
      'गुठिचौर',
    ],
    कालिकोट: [
      'खाँडाचक्र',
      'रास्कोट',
      'तिलागुफा',
      'नरहरिनाथ',
      'पलाता',
      'शुभ कालिका',
      'सान्नी त्रिवेणी',
      'पचालझरना',
      'महावै',
    ],
    मुगु: ['छायाँनाथ रारा', 'खत्याड', 'सोरु', 'मुगुम कार्मारोंग'],
    सुर्खेत: [
      'वीरेन्द्रनगर',
      'गुर्भाकोट',
      'भेरीगंगा',
      'पञ्चपुरी',
      'लेकबेशी',
      'बराहताल',
      'सिम्ता',
      'चौकुने',
      'चिङ्गाड',
    ],
    दैलेख: [
      'दुल्लु',
      'आठबीस',
      'नारायण',
      'चामुण्डा विन्द्रासैनी',
      'गुराँस',
      'भैरवी',
      'नौमुले',
      'महावु',
      'ठाँटीकाँध',
      'भगवतीमाई',
      'डुंगेश्वर',
    ],
    जाजरकोट: [
      'छेडागाड',
      'भेरी',
      'नलगाड',
      'जुनीचाँदे',
      'कुसे',
      'बारेकोट',
      'शिवालय',
    ],
  },
};
