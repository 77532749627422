import React, { useEffect, useRef, useState } from 'react';
import PrastabanaService from 'services/prastabana';
import '../../assets/css/App.css';
import { Box, Button, IconButton, Modal } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  background: 'white',
  boxShadow: 24,
  p: 4,
  maxHeight: '80%', // Adjust max height as needed
  overflowY: 'auto', // Enable vertical scrolling
};
const ApplicationDoc = ({ id, open, setOpen }) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await PrastabanaService.getSingleData(id);

        setData(res);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box style={style}>
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '4px 8px' }}>
              <ReactToPrint
                trigger={() => (
                  <IconButton variant="contained" title="Print" onClick={() => componentRef.current.print()}>
                    <PrintIcon />
                  </IconButton>
                )}
                content={() => componentRef.current}
                documentTitle={'Prastaban-Form'}
                copyStyles
                contentStyle={{
                  marginTop: '500px',
                }}
              />
            </div>

            <div className="py-4" ref={componentRef}></div>
          </>
        </Box>
      </Modal>
    </div>
  );
};

export default ApplicationDoc;
