import React from 'react';
import { useHistory } from 'react-router-dom';
import NepaliDate from 'nepali-date-converter';

import config from 'config';

import { emblemOfNepal, nepalFlag } from 'assets/images';
import appConfig from 'config';

import 'assets/css/banner.css';

const Banner = () => {
  const history = useHistory();

  const currentDate = new NepaliDate(new Date());

  return (
    <div className="banner-root">
      <div className="banner-info">
        <img
          src={emblemOfNepal}
          alt="Nepal's Emblem"
          className="banner-emblem-logo"
          onClick={() => history.push('/')}
        />
      </div>
      <div className="banner-office">
        <p className="banner-text">{config.provinceName}</p>
        <h1 className="banner-title" onClick={() => history.push('/')}>
          {config.appName}{' '}
        </h1>
        <h2 className="banner-subtitle">{config.officeName}</h2>
        <p className="banner-text">{config.officeLocation}</p>
      </div>

      <div className="banner-right">
        <div className="banner-contact">
          <span>
            {config.officeContact} <span className="fa fa-phone ico" />{' '}
          </span>
          <a href={'mailto:' + config.officeEmail}>
            {config.officeEmail} <span className="fa fa-envelope ico" />{' '}
          </a>
          <br></br>
          <span className="current-date">{currentDate.format('ddd, MMMM DD, YYYY', 'np')} </span>
          <span style={{color: '#217C9E',}}>
            <a href={'admin/'} target="_blank" rel="noopener noreferrer">Admin Panel</a>
          </span>
        </div>
        <img src={nepalFlag} alt="Nepal's flag" className="banner-flag" />
      </div>
    </div>
  );
};

export default Banner;
