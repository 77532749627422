import React from 'react';

import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CustomGridRow from './CustomGridRow';

const useStyles = makeStyles({
  rootContainer: {
    border: '2px dashed #cdc',
    borderRadius: 10,
    marginTop: 25,
    marginBottom: 25,
    padding: 25,
    fontSize: '18px',
  },
  personPhoto: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
});

const getArea = (bigaha, kattha, dhur, ropani, aana, dam, paisa) => {
  if (bigaha == null && kattha == null && dhur == null) {
    if (ropani == null && aana == null && dam == null && paisa == null) {
      return '';
    } else {
      return `${ropani}-${aana}-${dam}-${paisa}`;
    }
  } else {
    return `${bigaha}-${kattha}-${dhur}`;
  }
};

const chhaChhaina = (isChha) => {
  return isChha ? 'छ' : 'छैन';
};

const OtherDetails = ({ data }) => {
  const classes = useStyles();

  const landDescriptions = data.land_description;
  const landExtraInfo = data.land_extra_info[0];
  const animals = data.animals;
  const crops = data.crops;
  const cropExtraInfo = data.crop_extra_info[0];

  const otherBusiness = data.other_business;
  const marketSystem = data.market_system;
  const payekaSubidha = data.payeka_subidha;
	const samuhaSahakariFarmAssociation = data.samuha_sahakari_farm_association;

  return (
    <Container className={classes.rootContainer}>
      <Typography variant="h5" color="secondary">
        जमीन तथा जग्गा
      </Typography>
      {landDescriptions.map((item, i) => {
        const { bigaha, kattha, dhur, ropani, aana, dam, paisa } = item;
        return (
          <>
            <CustomGridRow
              dataKeys={['जग्गाको प्रकार', 'क्षेत्रफल', 'बाँझो', 'चरण खर्क']}
              dataValues={[
                item.land_type,
                getArea(bigaha, kattha, dhur, ropani, aana, dam, paisa),
                item.barren_land,
                item.pasture_land,
              ]}
              isGray={i % 2 === 0 ? false : true}
            />
          </>
        );
      })}
      <br></br>
      {landExtraInfo && (
        <CustomGridRow
          dataKeys={['सिंचित क्षेत्र', 'असिंचित क्षेत्र', 'सिचाई प्रकार', 'बाटो पुगेको']}
          dataValues={[
            landExtraInfo.irrigated_area,
            landExtraInfo.non_irrigated_area,
            landExtraInfo.irrigation_type,
            chhaChhaina(landExtraInfo.is_road_connected),
          ]}
          isGray={true}
        />
      )}
      <br></br>
      <Typography variant="h5" color="secondary">
        जनावरहरु
      </Typography>
      {animals.map((item, i) => {
        return (
          <>
            <CustomGridRow
              dataKeys={[i + 1, 'संख्या', 'उत्पादन किसिम', 'औसत उत्पादन']}
              dataValues={[
                item.animal_name + '-' + item.animal_species,
                parseInt(item.hybrid_count + item.local_count),
                item.productivity_type,
                item.average_production,
              ]}
              isGray={i % 2 === 0 ? false : true}
            />
          </>
        );
      })}
      <br></br>
      <Typography variant="h5" color="secondary">
        वालीहरु
      </Typography>
      {crops.map((item, i) => {
        return (
          <>
            <CustomGridRow
              dataKeys={[i + 1, 'लगाईएको जग्गा', 'औसत उत्पादन']}
              dataValues={[item.crop_name + ' (' + item.crop_type + ')', item.own_area, item.total_production]}
              isGray={i % 2 === 0 ? false : true}
            />
          </>
        );
      })}
      <br></br>
      {cropExtraInfo && (
        <CustomGridRow
          dataKeys={['वालीको विउको श्रोत', 'विउ प्रदायक', '']}
          dataValues={[cropExtraInfo.seeds_source, cropExtraInfo.seeds_distributer, '']}
          isGray={false}
        />
      )}
      <CustomGridRow dataKeys={[]} dataValues={[]} isGray={false} />
      <br />
      <Typography variant="h5" color="secondary">
        सरकारी तथा गैहसरकारी निकायबाट पाएको सुबिधा
      </Typography>
      {payekaSubidha.map((item, i) => {
        return (
          <>
            <strong>{item.helping_organization_name}</strong>
            <p>{item.help_description}</p>
            <CustomGridRow
              dataKeys={[
                'उपकरणका लागि आर्थिक सहायता प्राप्त',
                'कृषि ऋणका लागि मद्दत प्राप्त',
                'बजार व्यवस्थापनका लागि मद्दत प्राप्त',              ]}
              dataValues={[
                chhaChhaina(item.got_economic_help_for_tools),
                chhaChhaina(item.got_help_for_agriculture_loan),
                chhaChhaina(item.got_help_for_market_management),
              ]}
              isGray={i % 2 === 0 ? false : true}
            />
            <CustomGridRow
              dataKeys={[
                'राज्यबाट प्राविधिक मद्दत प्राप्त',
                'अन्य मद्दत प्राप्त',
                '',
              ]}
              dataValues={[
                chhaChhaina(item.got_technical_help_from_state),
                chhaChhaina(item.got_other_help),
                '',
              ]}
              isGray={i % 2 + 1 === 0 ? false : true}
            />
          </>
        );
      })}
			<br />
			<Typography variant="h5" color="secondary">
				कृषि तथा पशुपालनमा सक्रिय सेवा प्रदायक संस्थामा आवद्ध
      </Typography>
      {samuhaSahakariFarmAssociation.map((item, i) => {
        return (
          <>
            <strong>{item.helping_organization_name}</strong>
            <p>{item.help_description}</p>
            <CustomGridRow
              dataKeys={[
								'समूहमा आवद्ध',
								'सहकारीमा आवद्ध',
								'फरममा आवद्ध',
              ]}
              dataValues={[
								chhaChhaina(item.is_in_samuha),
								chhaChhaina(item.is_in_sahakari),
								chhaChhaina(item.is_in_farm),
              ]}
              isGray={i % 2 === 0 ? false : true}
            />
            {/* <CustomGridRow */}
            {/*   dataKeys={[ */}
            {/*     'समूह', */}
            {/*     'सहकारी', */}
            {/*     'फरम', */}
            {/*   ]} */}
            {/*   dataValues={[ */}
            {/*     item.samuha, */}
            {/*     item.sahakari, */}
            {/*     item.farm, */}
            {/*   ]} */}
            {/*   isGray={i % 2 + 1 === 0 ? false : true} */}
            {/* /> */}
          </>
        );
      })}
      <br></br>
      <Typography variant="h5" color="secondary" style={{color: marketSystem.length > 0 ? '#F50057' : 'rgba(245,0,87,0.5)'}}>
        मुख्य बजार तथा बजारीकरण प्रणाली
      </Typography>
      {marketSystem.map((item, i) => {
        return (
          <>
            <CustomGridRow
              dataKeys={[`${i + 1}. बजार`, 'बजारीकरण प्रणाली']}
              dataValues={[item.main_market, item.marketing_system]}
              isGray={i % 2 === 0 ? false : true}
            />
          </>
        );
      })}
      <br></br>
      <Typography variant="h5" color="secondary" style={{color: otherBusiness.length > 0 ? '#F50057' : 'rgba(245,0,87,0.5)'}}>
        कृषि जन्य तथा अन्य व्यवसायमा लगानी र आम्दानी
      </Typography>
      {otherBusiness.map((item, i) => {
        return (
          <>
            <CustomGridRow
              dataKeys={[`${i + 1}. व्यवसायको नाम`, 'लगानी', 'वार्षिक आय']}
              dataValues={[item.business_name, item.investment, item.yearly_income]}
              isGray={i % 2 === 0 ? false : true}
            />
          </>
        );
      })}
      <Container></Container>
    </Container>
  );
};

export default OtherDetails;
