import { Button, ButtonGroup, CircularProgress, Grid, MenuItem, TextField, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import KrishiService from 'services/krishiService';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const WorkForm = ({ setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [projectList, setProjectList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await KrishiService.getIrri();
      setProjectList(res?.results);
    };
    fetchData();
  }, []);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          completion_date: '',
          irrigation_application: projectList && projectList[0]?.id,
          percentage_cost: '',
          percentage_cost_beneficiary: '',
          percentage_cost_office: '',
          total_cost: '',
          total_cost_beneficiary: '',
          total_cost_office: '',
          person: getUserInfo().person_id,
        }}
        onSubmit={async (values) => {
          try {
            const response = await KrishiService.addWork(values);
            if (response?.id) {
              setActiveStep(3);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="completion_date"
                name="completion_date"
                label="समाप्ति मिति"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
                value={formik.values.completion_date}
                onChange={formik.handleChange}
                error={formik.touched.completion_date && Boolean(formik.errors.completion_date)}
                helperText={formik.touched.completion_date && formik.errors.completion_date}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="percentage_cost"
                name="percentage_cost"
                label="प्रतिशत लागत"
                fullWidth
                required
                type="number"
                value={formik.values.percentage_cost}
                onChange={formik.handleChange}
                error={formik.touched.percentage_cost && Boolean(formik.errors.percentage_cost)}
                helperText={formik.touched.percentage_cost && formik.errors.percentage_cost}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="percentage_cost_beneficiary"
                name="percentage_cost_beneficiary"
                label="प्रतिशत लागत लाभार्थी"
                fullWidth
                type="number"
                required
                value={formik.values.percentage_cost_beneficiary}
                onChange={formik.handleChange}
                error={formik.touched.percentage_cost_beneficiary && Boolean(formik.errors.percentage_cost_beneficiary)}
                helperText={formik.touched.percentage_cost_beneficiary && formik.errors.percentage_cost_beneficiary}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="percentage_cost_office"
                name="percentage_cost_office"
                label="प्रतिशत लागत कार्यालय"
                fullWidth
                required
                type="number"
                value={formik.values.percentage_cost_office}
                onChange={formik.handleChange}
                error={formik.touched.percentage_cost_office && Boolean(formik.errors.percentage_cost_office)}
                helperText={formik.touched.percentage_cost_office && formik.errors.percentage_cost_office}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="total_cost"
                name="total_cost"
                label="कुल लागत"
                fullWidth
                type="number"
                required
                value={formik.values.total_cost}
                onChange={formik.handleChange}
                error={formik.touched.total_cost && Boolean(formik.errors.total_cost)}
                helperText={formik.touched.total_cost && formik.errors.total_cost}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="total_cost_beneficiary"
                name="total_cost_beneficiary"
                label="कुल लागत लाभार्थी"
                fullWidth
                type="number"
                required
                value={formik.values.total_cost_beneficiary}
                onChange={formik.handleChange}
                error={formik.touched.total_cost_beneficiary && Boolean(formik.errors.total_cost_beneficiary)}
                helperText={formik.touched.total_cost_beneficiary && formik.errors.total_cost_beneficiary}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="total_cost_office"
                name="total_cost_office"
                label="कुल लागत कार्यालय"
                fullWidth
                type="number"
                required
                value={formik.values.total_cost_office}
                onChange={formik.handleChange}
                error={formik.touched.total_cost_office && Boolean(formik.errors.total_cost_office)}
                helperText={formik.touched.total_cost_office && formik.errors.total_cost_office}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                  बुझाउनुहोस् &nbsp;
                  {isDisabled ? <CircularProgress size={30} /> : null}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default WorkForm;
