import { Grid, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useState } from 'react';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';
import * as Yup from 'yup';
import RenderInput from '../RenderInput';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const fields = [
  {
    id: 'applicant_experience',
    label: 'प्रस्तावको व्यवसाय अनुभव',
    type: 'select',
    options: [
      { id: 'less_than_3_years', label: '३ वर्ष भन्दा कम' },
      { id: '3_to_5_years', label: '३ देखि ५ वर्ष सम्म' },
    ],
  },
  {
    id: 'program_location_physical_infrastructure',
    label: 'कार्यक्रम कार्यान्वयन स्थलको भौतिक पूर्वाधारको अवस्था',
    type: 'number',
    min: 0,
    max: 20,
  },
  {
    id: 'road_accessibility',
    label: 'सडक सुविधा भएको',
    type: 'select',
    options: [
      { id: 'yes', label: 'Yes' },
      { id: 'no', label: 'No' },
    ],
  },
  {
    id: 'electricity_accessibility',
    label: 'बिजुलीको सुविधा भएको',
    type: 'select',
    options: [
      { id: 'yes', label: 'Yes' },
      { id: 'no', label: 'No' },
    ],
  },
  {
    id: 'water_accessibility',
    label: 'खाने पानीको सुविधा भएको',
    type: 'select',
    options: [
      { id: 'yes', label: 'Yes' },
      { id: 'no', label: 'No' },
    ],
  },
  {
    id: 'land_ownership',
    label: 'जग्गा आफ्नै भएको',
    type: 'select',
    options: [
      { id: 'yes', label: 'Yes' },
      { id: 'no', label: 'No' },
    ],
  },
  {
    id: 'ownership_of_physical_resources',
    label: 'आफ्नै स्वामित्वको भवन, भौतिक, पूर्वाधार, सवारी साधन, यन्त्र उपकरण भएको',
    type: 'select',
    options: [
      { id: 'yes', label: 'Yes' },
      { id: 'no', label: 'No' },
    ],
  },
  {
    id: 'technical_capacity',
    label: 'प्राबिधिक दक्षता / सम्बन्धित बिषयको तालिम',
    type: 'number',
    min: 0,
    max: 10,
  },
  {
    id: 'training_duration',
    label: 'प्राबिधिक दक्षता / सम्बन्धित बिषयको तालिमको अवधि',
    type: 'select',
    options: [
      { id: 'less_than_7_days', label: '७ दिन अवधि सम्मको तालिम' },
      { id: '7_to_15_days', label: '७ देखि १५ दिन अवधि सम्मको तालिम' },
      { id: '16_to_30_days', label: '१६ देखि ३० दिन अवधि सम्मको तालिम' },
      { id: '1_to_3_months', label: '१ महिना देखि ३ महिना अवधि सम्मको तालिम' },
      { id: 'more_than_3_months', label: '३ महिना भन्दा बढी अवधिको तालिम' },
    ],
  },
  {
    id: 'financial_capacity',
    label: 'बित्तिय क्षमाता',
    type: 'number',
    min: 0,
    max: 4,
  },
  {
    id: 'ability_for_self_funding',
    label: 'स्वलागानी गर्न सक्ने आधार भएको',
    type: 'boolean',
    initialValue: false,
  },
  {
    id: 'possibility_for_external_support',
    label: 'अन्य निकाय बाट सहयोग प्राप्त गर्न सक्ने अवस्था रहेको',
    type: 'boolean',
    initialValue: false,
  },
  {
    id: 'utilization_of_youth_potential',
    label: 'युवाशक्त्तिको उपयोग',
    type: 'number',
    min: 0,
    max: 25,
  },
  {
    id: 'youth_18_to_50_years',
    label: 'युवा (१८ देखि ५० वर्ष )',
    type: 'number',
    min: 0,
  },
  {
    id: 'returnee_migrant_youth',
    label: 'बैदेशिक रोजगारबाट फर्केका युवा',
    type: 'number',
    min: 0,
  },
  {
    id: 'unemployed_youth_with_agriculture_study',
    label: 'कृषि बिषय अध्ययन गरेर बेरोजगार बसेका युवा',
    type: 'number',
    min: 0,
  },
  {
    id: 'experienced_individuals_in_agriculture_sector',
    label: 'कृषि क्षेत्रमा अनुभव प्राप्त व्यक्ति कृषि व्यवसाय सुरु गर्ने इच्छा भएका',
    type: 'number',
    min: 0,
  },
  {
    id: 'educated_unemployed_youth',
    label: 'शिक्षित बेरोजगार युवा',
    type: 'number',
    min: 0,
  },
  {
    id: 'environmental_suitability',
    label: 'वातावरणीय उपयुक्तता',
    type: 'number',
    min: 0,
    max: 6,
  },
  {
    id: 'program_environmental_impact_assessment',
    label: 'प्रस्तावित कार्यक्रमको वातावरणीय प्रभाव आङ्कलन गरिएको',
    type: 'boolean',
    initialValue: false,
  },
  {
    id: 'environmental_impact_mitigation_measures',
    label: 'वातावरणीय प्रभाव न्यूनीकरणका उपायहरु सामेल गरिएको',
    type: 'boolean',
    initialValue: false,
  },
  {
    id: 'farmer_group_membership',
    label: 'स्वकृत कर्याबिधिका अनुसारका थप आधारहरु',
    type: 'number',
    min: 0,
    max: 10,
  },
  {
    id: 'agricultural_farm_registration',
    label: 'व्यवसायीक कृषि फार्म दर्ता भएको',
    type: 'boolean',
    initialValue: false,
  },
  {
    id: 'profitability_and_break_even_analysis',
    label: 'मुनाफा र शमबिन्दुको आधारमा उपौक्ताता र दिगोपना',
    type: 'number',
    min: 0,
    max: 5,
  },
  {
    id: 'socioeconomic_backwardness',
    label: 'व्यवसायीक कृषि फार्म दर्ता भएको',
    type: 'number',
    min: 0,
    max: 10,
  },
  {
    id: 'dalit',
    label: 'दलित',
    type: 'boolean',
    initialValue: false,
  },
  {
    id: 'marginalized',
    label: 'सीमान्तकृत',
    type: 'boolean',
    initialValue: false,
  },
  {
    id: 'single_female_or_male',
    label: 'एकल महिला वा पुरुष',
    type: 'boolean',
    initialValue: false,
  },
  {
    id: 'economically_deprived',
    label: 'आर्थिक रुपमा विपन्न',
    type: 'boolean',
    initialValue: false,
  },
  {
    id: 'total_marks',
    label: 'जम्मा',
    type: 'number',
    min: 0,
    max: 100,
  },
];
const AppBusiness = ({ id, setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  const getValidationSchema = (fields) => {
    const shape = {};

    fields.forEach((field) => {
      if (field.type === 'number' && typeof field.min === 'number' && typeof field.max === 'number') {
        shape[field.id] = Yup.number()
          .required(`${field.label} is required`)
          .min(field.min, `${field.label} must be at least ${field.min}`)
          .max(field.max, `${field.label} must be at most ${field.max}`);
      } else {
        shape[field.id] = Yup.string().required(`${field.label} is required`);
      }
    });

    return Yup.object().shape(shape);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Grid item xs={12}>
        <h3>व्यवसाय योजना मूल्यांकनका आधार</h3>
      </Grid>
      <Formik
        initialValues={{
          application: id,
          applicant_experience: '', // select type
          program_location_physical_infrastructure: '', // number type
          road_accessibility: false, // boolean type
          electricity_accessibility: false, // boolean type
          water_accessibility: false, // boolean type
          land_ownership: false, // boolean type
          ownership_of_physical_resources: false, // boolean type
          technical_capacity: '', // number type
          training_duration: '', // select type
          financial_capacity: '',
          ability_for_self_funding: false,
          possibility_for_external_support: false,
          utilization_of_youth_potential: '',
          youth_18_to_50_years: '',
          returnee_migrant_youth: '',
          unemployed_youth_with_agriculture_study: '',
          experienced_individuals_in_agriculture_sector: '',
          educated_unemployed_youth: '',
          environmental_suitability: '',
          program_environmental_impact_assessment: false,
          environmental_impact_mitigation_measures: false,
          farmer_group_membership: '',
          agricultural_farm_registration: false,
          profitability_and_break_even_analysis: '',
          socioeconomic_backwardness: '',
          dalit: false,
          marginalized: false,
          single_female_or_male: false,
          economically_deprived: false,
          total_marks: '',
          person: getUserInfo().person_id,
        }}
        validationSchema={getValidationSchema(fields)}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            const response = await PrastabanaService.addApplicantBusinessEvaluationView(values);
            if (response?.id) {
              setActiveStep(8);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
      >
        {(formik) => <RenderInput fields={fields} formik={formik} isDisabled={isDisabled} />}
      </Formik>
    </div>
  );
};

export default AppBusiness;
