import React from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  bgSuccess: {
    backgroundColor: '#efe',
    color: '#5a5'
  },
  bgError: {
    backgroundColor: '#fee',
    color: '#a55',
    width: 600,
  },
}));

const errorMessage = `ईन्ट्री गरेको विवरण मिलेन`
const errorMessageDescription = `कृपया पुन: प्रयास गर्नुहोस वा नेटवर्क चेक गर्नुहोस`
const successMessage = ``
const successMessageDescription = `ईन्ट्री गरेको विवरण सुरक्षित साथ अपलोड भएको छ`

export default function AlertDialog({isSuccess, open, handleClose}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <Container className={isSuccess ? classes.bgSuccess : classes.bgError}>
          {<DialogTitle id="alert-dialog-title">{isSuccess ? successMessage : errorMessage}</DialogTitle>}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              { isSuccess ? successMessageDescription : errorMessageDescription }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              {isSuccess ? 'ठिक छ' : 'X'}
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
}
