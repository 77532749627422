import axios from 'axios';
import { makeMappings } from 'utils/convertCase';

const baseUri = process.env.REACT_APP_API_BASE_URI;

const endpoints = {
  irriUrl: 'api/irrigation-applications/',
  workUrl: 'api/work-cost-details/',
  workCostUrl: 'api/work-cost-estimates/',
  pumpUrl: 'api/pump-installation-forms/',
  contributionUrl: 'api/participation-contributions/',
  renovationUrl: 'api/renovation-projects/',
  waterUrl: 'api/water-consumers/',
  nearUrl: 'api/near-projects/',
  waterExtraUrl: 'api/water-source-extras/',
  waterSourceUrl: 'api/water-sources/',
  prevIrrUrl: 'api/previous-irrigation-projects/',
  beniUrl: 'api/beneficiary-details/',
  irrProjectsUrl: 'api/irrigation-projects/',
  consumerUrl: 'api/consumer-applicants/',
  smallIrrUrl: 'api/small-irrigation-agreements/',
};

class KrishiService {
  static accessToken() {
    return localStorage.getItem('access_token');
  }

  static async addData(url, data) {
    const mappings = makeMappings(data);
    const response = await axios.post(url, mappings, {
      headers: {
        Authorization: `Bearer ${KrishiService.accessToken()}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getData(url) {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${KrishiService.accessToken()}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}

Object.entries(endpoints).forEach(([key, value]) => {
  const methodName = key.replace('Url', '');
  KrishiService[`add${methodName.charAt(0).toUpperCase() + methodName.slice(1)}`] = function (data) {
    return KrishiService.addData(baseUri + value, data);
  };
  KrishiService[`get${methodName.charAt(0).toUpperCase() + methodName.slice(1)}`] = function () {
    return KrishiService.getData(baseUri + value);
  };
});

export default KrishiService;
