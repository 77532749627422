import React, { useState, useEffect } from 'react';

import { Container, Grid, IconButton } from '@material-ui/core';
import SearchAppBar from 'components/Appbar';
import Banner from 'components/Banner';
import { makeStyles } from '@material-ui/core/styles';
// import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from 'react-router-dom';
import Copyright from 'components/Copyright';
import PrastabanaService from 'services/prastabana';
import { DataGrid } from '@material-ui/data-grid';
import { getUserInfo } from 'utils/decodeJWT';
import KrishiService from 'services/krishiService';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  titleContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const KrishiTable = () => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);
  // const userRole = getUserInfo().usertype;
  useEffect(() => {
    const fetchData = async () => {
      const res = await KrishiService.getIrri();
      console.log('🚀 ~ fetchData ~ res:', res);
      if (res?.count > 0) {
        setData(res?.results);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      field: 'agriculture_name',
      width: 250,
      headerName: 'Name',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <span style={{ fontWeight: 'bold' }}>{params.row.agriculture_name}</span>;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'address',
      width: 250,
      headerName: 'Address',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <span style={{ fontWeight: 'bold' }}>{params.row.address}</span>;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'date',
      width: 250,
      headerName: 'Date',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <span style={{ fontWeight: 'bold' }}>{params.row.date}</span>;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'contact_number',
      width: 250,
      headerName: 'Contact Number',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <span style={{ fontWeight: 'bold' }}>{params.row.contact_number}</span>;
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  const handleRowClick = (param) => {
    history.push(`/form-details/${param.row.id}`);
  };

  return (
    <>
      <Banner />
      <SearchAppBar title="दर्ता भएको डाटा" />
      <Container maxWidth="lg">
        <Grid item xs={12} className={classes.titleContainer}></Grid>
        <Grid item lg={12} md={9}>
          <div style={{ height: 425, width: '100%' }}>
            {data ? (
              <DataGrid rows={data} columns={columns} pageSize={5} onRowClick={handleRowClick} />
            ) : (
              <div style={{ fontWeight: 'bold', fontSize: '18px', color: '#888888' }}>लोड हुँदैछ...</div>
            )}
          </div>
        </Grid>
        <br />
      </Container>
      <Copyright website="उदयपुरगढी गाउँपालिका" link="#" />
    </>
  );
};

export default KrishiTable;
