import { Grid, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useState } from 'react';
import PrastabanaService from 'services/prastabana';
import RenderInput from './RenderInput';
import { getUserInfo } from 'utils/decodeJWT';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const fields = [
  { id: 'village', label: 'गाँउ नाम', type: 'text' },
  { id: 'ward_no', label: 'वडा नम्बर', type: 'text' },
  { id: 'cost', label: 'लागत', type: 'number' },
  { id: 'estimated_total_cost', label: 'पूरा लागतको अनुमान', type: 'text' },
  { id: 'number_of_percentage', label: 'प्रतिशत संख्या', type: 'text' },
  { id: 'percentage', label: 'प्रतिशत', type: 'text' },
  { id: 'alphabetic', label: 'अक्षरमा', type: 'text' },
  { id: 'barawar_investment', label: 'बरावर लगानी', type: 'text' },
  { id: 'name', label: 'नाम', type: 'text' },
  { id: 'date', label: 'मिति', type: 'text' },
  { id: 'seal_of_institution', label: 'संस्थाको मुहार', type: 'text' },
  { id: 'information_published', label: 'जानकारी प्रकाशित', type: 'text' },
];

const CostSharingForm = ({ applicationDetails, setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  const getValidationSchema = (fields) => {
    const shape = {};
    fields.forEach((field) => {
      shape[field.id] = Yup.string().required(`${field.label} is required`);
    });
    return Yup.object().shape(shape);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Grid item xs={12}>
        <h3>लागत साझेदारीकोप्रतिबद्धता</h3>
      </Grid>
      <Formik
        initialValues={{
          app: applicationDetails && applicationDetails?.id,
          village: '',
          name_of_program_offered: null,
          status: 'In Progess',
          full_address_of_venue: '',
          person: getUserInfo().person_id,
        }}
        validationSchema={getValidationSchema(fields)}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            const response = await PrastabanaService.addCostsharingEntries(values);
            if (response?.id) {
              setActiveStep(4);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
      >
        {(formik) => <RenderInput fields={fields} formik={formik} isDisabled={isDisabled} />}
      </Formik>
    </div>
  );
};

export default CostSharingForm;
