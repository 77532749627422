import React, { useEffect, useRef, useState } from 'react';
import PrastabanaService from 'services/prastabana';
import '../../assets/css/App.css';
import { Box, Button, IconButton, Modal } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  background: 'white',
  boxShadow: 24,
  p: 4,
  maxHeight: '80%', // Adjust max height as needed
  overflowY: 'auto', // Enable vertical scrolling
};
const PrastabanaDoc = ({ id, open, setOpen }) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await PrastabanaService.getSingleData(id);

        setData(res);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box style={style}>
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '4px 8px' }}>
              <ReactToPrint
                trigger={() => (
                  <IconButton variant="contained" title="Print" onClick={() => componentRef.current.print()}>
                    <PrintIcon />
                  </IconButton>
                )}
                content={() => componentRef.current}
                documentTitle={'Prastaban-Form'}
                copyStyles
                contentStyle={{
                  marginTop: '500px',
                }}
              />
            </div>

            <div className="py-4" ref={componentRef}>
              <div className="page">
                <h2 className="text-center">अनुसुची – १</h2>
                <div className="text-center">निवेदन</div>
                <h1 className="text-center">Request Form</h1>
                <div className="d-flex justify-content-end">मिति : {data?.prastabana_details?.date}</div>
                <br></br>
                <div>श्रीमान प्रमूख प्रशासकिय अधिकृतज्यू,</div>
                <div>उदयपुरगढी गाँउपालीकाको कार्यालय, उदयपुरगढी, उदयपुर ।</div>
                <br></br>
                <div className="text-center">
                  <b>विषय : प्रस्तावनामा आधारीत कृषि कार्यक्रम पेश गरेको सम्बन्धमा ।</b>
                </div>
                <br></br>
                <p>
                  उपरोक्त सम्बन्धमा मिति {data?.prastabana_details?.date} मा यस कार्यालयबाट प्रकाशित सुचना अनुसार हामीले
                  कृषि वस्तु उत्पादन तथा संकलन/कृषि उपजको खरिद विक्री/प्रशोधन एवं वजार व्यवस्थापन/उन्नत कृषि प्रविधी
                  प्रसार कार्यक्रम संचालन गर्ने परियोजना बनाएको छौं । उक्त परियोजना कार्यान्वयनको प्रस्ताव यसै निवेदन
                  साथ संलग्न गरी प्रस्तावनामा आधारीत कृषि कार्यक्रम संचालन कार्यविधि–२०७६ अनुसार आवश्यक कारवाहीको लागि
                  अनुरोध गर्दछौ ।{' '}
                </p>
                <div className="d-flex justify-content-end py-4">निवेदक</div>
                <div>आवश्यक कागज–पत्रहरु</div>
                <div>
                  <div>१. कृषक समूह/सहकारी संस्थाको दर्ता प्रमाण प्रमाण–पत्रको प्रतिलिपी</div>
                  <div>२. सम्बन्धीत वडा कार्यालयको सिफारीस</div>
                  <div>३. सम्बन्धीत कृषि सेवा केन्द्रको सिफारीस</div>
                  <div>
                    ४. उदयपुरगढी गाँउपालीकाकृषि विकास शाखासंग साझेदारीमा कार्यक्रम संचालन गर्न प्रतिबद्ध भई समूह/सहकारी
                    संस्थाले गरेको निर्णय प्रतिलिपी छ
                  </div>
                  <div>
                    ५. परियोजना करार वा भाडामा लिईएको जग्गामा सञ्चालन हुने भए सो को करारनामा/सम्झौताको प्रतिलिपि
                  </div>

                  <div>६. लेखा परिक्षण प्रतिवेदन र संस्था सकृयताको प्रमाणपत्र (सहकारीको हकमा)</div>
                </div>
              </div>
              <div className="page">
                <div className="d-flex justify-content-end"> अनुसुची - २</div>
              </div>
              <div className="text-center">
                <b>प्रस्तावनामा अधारित कृषि कार्यक्रम</b>
              </div>
              <div className="text-center">
                <b>परियोजना प्रस्ताव फर्मेट</b>
              </div>
              <div>
                <br></br>
                १. पृष्ठभुमि : <b>{data?.prastabana_details?.background}</b>
              </div>
              <div>
                क. परियोजनाको नाम :<b>{data?.prastabana_details?.project_name}</b>
              </div>
              <div>
                ख. परियोजना सञ्चालन हुने स्थान :<b>{data?.prastabana_details?.project_location}</b> क्षेत्रफल :
                <b>{data?.prastabana_details?.area}</b>
              </div>
              <div>
                ग. कृषक समुह/कृषि सहकारीको नाम : <b>{data?.prastabana_details?.farmer_group_name}</b>
              </div>
              <div>
                घ. ठेगाना : <b>{data?.prastabana_details?.address}</b>
              </div>
              <div>
                ङ. स्थापना वर्ष : <b>{data?.prastabana_details?.establishment_year}</b>
              </div>
              <div>
                घ. जम्मा सदस्य संख्या :<b>{data?.prastabana_details?.total_members}</b> महिला :{' '}
                <b>{data?.prastabana_details?.female_members}</b>पुरुष : <b>{data?.prastabana_details?.male_members}</b>{' '}
                दलित : <b>{data?.prastabana_details?.dalit_members}</b> जनजाती :{' '}
                <b>{data?.prastabana_details?.janajati_members}</b>अन्य :{' '}
                <b>{data?.prastabana_details?.other_members}</b>
              </div>
              <div>
                २. लाभान्वित कषक संख्या क) प्रत्यक्ष : <b>{data?.prastabana_details?.direct_beneficiaries}</b> ख)
                अप्रत्यक्ष : <b>{data?.prastabana_details?.indirect_beneficiaries}</b>
              </div>
              <div>
                ३. कृषक समूह/कृषि सहकारीको वर्तमान स्थिति : <b>{data?.prastabana_details?.current_status}</b>
              </div>
              <div>
                ४. विद्यमान समस्याहरु : <b>{data?.prastabana_details?.existing_problems}</b>
              </div>
              <div>
                ५. परियोजनाको उद्धेश्य : <b>{data?.prastabana_details?.project_objectives}</b>
              </div>
              <div>
                ६. परियोजानामा संचालन गरिने कृयाकलापहरु : <b>{data?.prastabana_details?.project_activities}</b>
              </div>
              <div>
                ७. परियोजना कार्यान्वयन प्रकृया (कसको भुमिका के र कस्तो हुने) :{' '}
                <b>{data?.prastabana_details?.implementation_process}</b>
              </div>
              <div>
                ८. परियोजनाको अपेक्षित उपलब्धिहरु र उपलब्धि प्राप्ति कसरी हुन्छ :{' '}
                <b>{data?.prastabana_details?.expected_outcomes}</b>{' '}
              </div>
              ९. परियोजनाका लागि आवश्यक वजेट क्र स कृयाकलापहरु ईकाई परिमाण लागत रु. उ.गा.पा.कृ.वि.सा.. ले व्यहोर्ने रकम
              रु. कृषक समुह/ सहकारीले व्यहोर्ने रकम रु. अन्य (...........) श्रोत बाट रु केफियत १ २ ३ ४ ५ ६ ७ ८ ९ १०
              जम्मा १०. परियोजना कार्यान्वयन तालिका क्रस कृयाकलापहरु महिनाहरु जिम्मेवार व्यक्ति सहयोगि निकाय अनुगमन
              गर्ने निकाय कैफियत श्रावण भदौ असोज कार्तिक मंसीर पौष माघ फाल्गुन चैत्र बैशाख जेष्ठ असार १ २ ३ ४ ५ ६ ७ ८ ९
              १० ११. परियोजना संचालन पश्चात यसको दिगोपनाको सुनिश्चतता परियोजना तयार गर्ने : मिति : हस्ताक्षर :
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
};

export default PrastabanaDoc;
