import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Chip, Button } from '@material-ui/core';

import { defaultPhoto } from 'assets/images';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: 300,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const RightDrawer = (person) => {
  person = person.person;

  const classes = useStyles();

  const history = useHistory();

  const submit = () => {
    history.push('/personal-entry');
  };

  return (
    <Container className={classes.root}>
      <Typography variant="h5" component="h5" color="primary">
        संक्षिप्त व्यक्तिगत विवरण
      </Typography>
      <br />
      <img
        src={person.photo ? `https://udaypurgadhikrisibibhag.com/media/${person.photo}` : defaultPhoto}
        style={{ width: '75px', height: '75px', borderRadius: '25px' }}
        alt={person.full_name_english}
      />
      <br />
      <Typography variant="h5">{person.full_name_devnagari}</Typography>
      <br />
      <Typography variant="subtitle1">
        {person.full_name_english} &nbsp;&nbsp;
        <Chip label={person.gender} />
      </Typography>
      <br />
      <Typography variant="subtitle2">{person.phone_no}</Typography>
      <br />
      <Typography variant="subtitle1">
        <strong>ठेगाना:</strong> {person.permanent_palika}-{person.permanent_ward_no}, {person.permanent_district}
      </Typography>
      <br />
      <Typography variant="subtitle1">
        {person.mother_tongue} &nbsp;&nbsp;
        <Chip label={person.marital_status} />
      </Typography>
      <br />
      <Typography variant="subtitle2">
        <strong>नागरिकता नंबर:</strong> {person.citizenship_no} &nbsp;&nbsp;
        <Chip label={person.citizenship_type} />
      </Typography>
      <br />
      <br />
      <Button variant="outlined" color="primary" onClick={submit}>
        {person.full_name_devnagari ? person.full_name_devnagari.split(' ')[0] : 'यो'}को ईन्ट्री समाप्त गर्नुहोस्
      </Button>
    </Container>
  );
};

export default RightDrawer;
