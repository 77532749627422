import React from 'react';

import 'assets/css/home.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import Banner from 'components/Banner';
import Copyright from 'components/Copyright';
import SearchAppBar from 'components/Appbar';
import GoogleMaps from 'components/Maps';

import config from 'config';

import 'assets/css/banner.css';

const ContactUs = () => {

  return (
	<div>
	  <Banner />
	  <SearchAppBar />
	  <div className="banner-office" style={{ margin: '40px auto', color: '#b83a55' }}>
        <h2 className="banner-title">{config.officeName}</h2>
		<br></br>
		<p className="banner-subtitle">{config.provinceName}</p>
        <p className="banner-text">{config.officeLocation}</p>
		<br></br>
		<div style={{ margin: '20px auto', color: '#217C9E' }}>
			<h3>फोन नं</h3>
			+९७७ ०२७ ६९१२७६,
			<br></br>
			‌+९७७ ९७४५१५५३६८
			<br></br>
			<br></br>
			<h3>ईमेल</h3>
			udayapurgadhirm@gmail.com,
			<br></br>
			ito.udayapurgadhimun@gmail.com
		</div>
	  </div>

	  <GoogleMaps />
	  <Copyright website="उदयपुरगढी गाउँपालिका" link="/" />
	</div>
  );
}

export default ContactUs;
