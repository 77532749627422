import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getInputField, getRadio, getRadioBoolean, getSelectField } from './Land';

import 'assets/css/table.css';
import { Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, InputLabel, Input } from '@material-ui/core';
import PersonalService from 'services/personal';
import { convertBase64 } from 'utils/imgToBase64';
// import Notes from 'components/Notes';

import { getProvinces, getDistricts, getPalikas, getAllDistricts } from 'constants/predefinedLists/addresses';
import AlertDialog from 'components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Personal = () => {
  const history = useHistory();
  const classes = useStyles();

  const [myState, setMyState] = useState({
    fullNameDevnagari: null,
    fullNameEnglish: null,
    gender: null,
    age: null,
    casteGroup: null,
    photo: 'null',
    dateOfBirth: null,
    motherTongue: null,
    phoneNo: null,
    citizenshipType: null,
    citizenshipNo: null,
    citizenshipIssuedDate: null,
    citizenshipIssuedDistrict: null,
    maritalStatus: null,
    spouseName: null,
    spouseCitizenshipNo: null,
    fatherName: null,
    fatherCitizenshipNo: null,
    motherName: null,
    motherCitizenshipNo: null,
    permanentState: null,
    permanentDistrict: null,
    permanentPalika: null,
    permanentWardNo: null,
    permanentTole: null,
    permanentIsForeign: false,
    permanentForeignCountry: null,
    temporaryState: null,
    temporaryDistrict: null,
    temporaryPalika: null,
    temporaryWardNo: null,
    temporaryTole: null,
    temporaryIsForeign: false,
    temporaryForeignCountry: null,
    permanentForeignPassportNo: null,
    business: null,
    educationQualification: null,
    isFamilyMembersInForeign: false,
    countOfFamilyMembersInForeign: null,
    foreignFamilyCountryName: null,
    familyMembersInAgriculture: null,
    bankName: null,
    bankAccountNo: null,
    maleLessThanEighteen: null,
    maleEighteenToFiftyNine: null,
    maleSixtyOrAboveEighteen: null,
    maleDifferentlyAbled: null,
    femaleLessThanEighteen: null,
    femaleEighteenToFiftyNine: null,
    femaleSixtyOrAboveEighteen: null,
    femaleDifferentlyAbled: null,
  });

  const [isTemporarySame, setIsTemporarySame] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  const copyAddress = (e) => {
    const tempInfo = {
      temporaryState: myState.permanentState,
      temporaryDistrict: myState.permanentDistrict,
      temporaryPalika: myState.permanentPalika,
      temporaryWardNo: myState.permanentWardNo,
      temporaryTole: myState.permanentTole,
      temporaryForeignCountry: myState.permanentForeignCountry,
    };
    setIsTemporarySame(e.target.checked);
    if (e.target.checked) {
      setMyState({
        ...myState,
        ...tempInfo,
      });
    }
  };

  const addPhoto = async (e) => {
    const imageFile = e.target.files[0];
    let base64 = await convertBase64(imageFile);

    setMyState({
      ...myState,
      photo: base64,
    });
  };

  const onChange = (key, value) => {
    myState[key] = value;
    setMyState({
      ...myState,
    });
  };

  const submit = async () => {
    try {
      setIsDisabled(true);
      const person = await PersonalService.addPersonalData(myState);
      setIsSuccessDialog(true);
      setOpenDialog(true);
      history.push({
        pathname: '/survey',
        search: `?userId=${person.id}`,
      });
    } catch (e) {
      setIsDisabled(false);
      setIsSuccessDialog(false);
      setOpenDialog(true);
    }
  };

  const genderOption = [
    { value: 'पुरुष', label: 'पुरुष' },
    { value: 'महिला', label: 'महिला' },
    { value: 'अन्य', label: 'अन्य' },
  ];

  const citizenshipOptions = [
    { value: 'वंशज', label: 'वंशज' },
    { value: 'जन्मको आधारमा', label: 'जन्मको आधारमा' },
    { value: 'वैवाहिक सम्बन्धको आधारमा', label: 'वैवाहिक सम्बन्धको आधारमा' },
    { value: 'अंगिकृत', label: 'अंगिकृत' },
  ];

  const maritalStatusOption = [
    { value: 'विवाहित', label: 'विवाहित' },
    { value: 'अविवाहित', label: 'अविवाहित' },
    { value: 'एकल', label: 'एकल' },
  ];

  const educationQualificationOption = [
    { value: 'साधारण लेखपढ', label: 'साधारण लेखपढ' },
    { value: 'SLC', label: 'SLC' },
    { value: '+२', label: '+२' },
    { value: 'स्नातक तह', label: 'स्नातक तह' },
    { value: 'स्नातकोत्तर', label: 'स्नातकोत्तर' },
  ];

  const isFamilyMembersInForeignOption = [
    { value: true, label: 'रहेको' },
    { value: false, label: 'नरहेको' },
  ];

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />

      <h3 style={{ marginBottom: '10px' }}>व्यक्तिगत विवरण</h3>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {getInputField(
            'पुरा नाम',
            'पुरा नाम नेपालीमा ',
            'fullNameDevnagari',
            myState.fullNameDevnagari,
            onChange,
            true
          )}
        </Grid>
        <Grid item xs={12}>
          {getInputField(
            'Full Name',
            'पुरा नाम अंग्रेजीमा',
            'fullNameEnglish',
            myState.fullNameEnglish,
            onChange,
            true
          )}
        </Grid>
        <Grid item xs={8}>
          <h3 style={{ marginBottom: '10px' }}>व्यक्तिको फोटो</h3>
          <FormControl style={{ marginBottom: '15px', width: '80%' }}>
            <InputLabel htmlFor="my-input">फोटो</InputLabel>
            <Input type="file" onChange={addPhoto} />
          </FormControl>
        </Grid>
        <Grid item xs={4} style={{ marginBottom: '5px' }}>
          {myState.photo !== 'null' && (
            <img
              src={myState.photo}
              style={{ height: '125px', width: '125px', borderRadius: '62px' }}
              alt={myState.fullNameEnglish}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {getRadio('लिङ्ग', genderOption, 'gender', myState.gender, onChange, true)}
        </Grid>
        <Grid item xs={12} sm={4}>
          {getInputField('उमेर', null, 'age', myState.age, onChange, true, 'number')}
        </Grid>
        <Grid item xs={6} sm={4}>
          {getInputField('जातिय समूह', null, 'casteGroup', myState.casteGroup, onChange)}
        </Grid>
        <Grid item xs={6} sm={2}>
          <h3 style={{ marginBottom: '10px' }}>नागरिकता विवरण</h3>
          {getRadio('नागरिकता किसिम', citizenshipOptions, 'citizenshipType', myState.citizenshipType, onChange, true)}
        </Grid>
        <Grid item xs={6} sm={3}>
          {getInputField('नागरिकता नम्बर', null, 'citizenshipNo', myState.citizenshipNo, onChange, true)}
        </Grid>
        <Grid item xs={6} sm={4}>
          {getInputField(
            'जारी मिती',
            'मितिको ढाँचा: वर्ष-महिना-मिति(२०७७-१२-२७)',
            'citizenshipIssuedDate',
            myState.citizenshipIssuedDate,
            onChange,
            true
          )}
        </Grid>
        <Grid item xs={6} sm={3}>
          {getSelectField(
            'नागरिकता जारी गरेको जिल्ला',
            getAllDistricts(),
            'citizenshipIssuedDistrict',
            myState.citizenshipIssuedDistrict,
            onChange,
            true
          )}
        </Grid>
        <Grid item xs={6}>
          {getInputField(
            'जन्म मिती(बि.स्ं)',
            'मितिको ढाँचा: वर्ष-महिना-मिति(२०७७-१२-२७)',
            'dateOfBirth',
            myState.dateOfBirth,
            onChange,
            true
          )}
        </Grid>
        <Grid item xs={6}>
          {getInputField('मातृभाषा', null, 'motherTongue', myState.motherTongue, onChange, true)}
        </Grid>
        <Grid item xs={12}>
          {getInputField('फोन नम्बर', 'फोन नम्बर: 9841XXXXXX', 'phoneNo', myState.phoneNo, onChange, true)}
        </Grid>
        <Grid item xs={12}>
          <h3 style={{ marginBottom: '10px' }}>परिवारको विवरण</h3>
          <table className="table">
            <tbody>
              <tr>
                <th>परिवार संख्या</th>
                <th>१८ वर्ष भन्दा कम</th>
                <th>१८ देखी ५९ वर्ष सम्म</th>
                <th>६० वर्ष देखी माथी</th>
                <th>कैफियत(असक्त/अपाङ्ग)</th>
              </tr>
              <tr>
                <th>पुरुष</th>
                <td>
                  {getInputField(
                    '',
                    'पुरुष: १८ वर्ष भन्दा कम',
                    'maleLessThanEighteen',
                    myState.maleLessThanEighteen,
                    onChange,
                    false,
                    'number'
                  )}
                </td>
                <td>
                  {getInputField(
                    '',
                    'पुरुष: १८ देखी ५९ वर्ष सम्म',
                    'maleEighteenToFiftyNine',
                    myState.maleEighteenToFiftyNine,
                    onChange,
                    false,
                    'number'
                  )}
                </td>
                <td>
                  {getInputField(
                    '',
                    'पुरुष: ६० वर्ष देखी माथी',
                    'maleSixtyOrAboveEighteen',
                    myState.maleSixtyOrAboveEighteen,
                    onChange,
                    false,
                    'number'
                  )}
                </td>
                <td>
                  {getInputField(
                    '',
                    'पुरुष: असक्त/अपाङ्ग',
                    'maleDifferentlyAbled',
                    myState.maleDifferentlyAbled,
                    onChange,
                    false,
                    'number'
                  )}
                </td>
              </tr>
              <tr>
                <th>महिला</th>
                <td>
                  {getInputField(
                    '',
                    'महिला: १८ वर्ष भन्दा कम',
                    'femaleLessThanEighteen',
                    myState.femaleLessThanEighteen,
                    onChange,
                    false,
                    'number'
                  )}
                </td>
                <td>
                  {getInputField(
                    '',
                    'महिला: १८ देखी ५९ वर्ष सम्म',
                    'femaleEighteenToFiftyNine',
                    myState.femaleEighteenToFiftyNine,
                    onChange,
                    false,
                    'number'
                  )}
                </td>
                <td>
                  {getInputField(
                    '',
                    'महिला: ६० वर्ष देखी माथी',
                    'femaleSixtyOrAboveEighteen',
                    myState.femaleSixtyOrAboveEighteen,
                    onChange,
                    false,
                    'number'
                  )}
                </td>
                <td>
                  {getInputField(
                    '',
                    'महिला: असक्त/अपाङ्ग',
                    'femaleDifferentlyAbled',
                    myState.femaleDifferentlyAbled,
                    onChange,
                    false,
                    'number'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item xs={6} sm={4}>
          <h3 style={{ marginBottom: '10px' }}>वैवाहिक विवरण</h3>
          {getRadio('वैवाहिक स्तिथी', maritalStatusOption, 'maritalStatus', myState.maritalStatus, onChange, true)}
        </Grid>
        <Grid item xs={6} sm={4}>
          {getInputField('पति/पत्नी को नाम', 'पति/पत्नी को नाम', 'spouseName', myState.spouseName, onChange)}
        </Grid>
        <Grid item xs={6} sm={4}>
          {getInputField(
            'नागरिकता नम्बर',
            'पति/पत्नी को नागरिकता नम्बर',
            'spouseCitizenshipNo',
            myState.spouseCitizenshipNo,
            onChange
          )}
        </Grid>
        <Grid item xs={6}>
          <h3 style={{ marginBottom: '15px' }}>बुवा-आमाको विवरण</h3>
          <h4 style={{ marginBottom: '5px' }}>बुवाको विवरण</h4>
          {getInputField('बुवाको नाम', null, 'fatherName', myState.fatherName, onChange, true)}
          {getInputField(
            'नागरिकता नम्बर',
            'बुवाको नागरिकता नम्बर',
            'fatherCitizenshipNo',
            myState.fatherCitizenshipNo,
            onChange
          )}
        </Grid>
        <Grid item xs={6}>
          <h4 style={{ marginBottom: '5px', marginTop: '30px' }}>आमाको विवरण</h4>
          {getInputField('आमाको नाम', null, 'motherName', myState.motherName, onChange, true)}
          {getInputField(
            'नागरिकता नम्बर',
            'आमाको नागरिकता नम्बर',
            'motherCitizenshipNo',
            myState.motherCitizenshipNo,
            onChange
          )}
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={6}>
          <h3 style={{ marginBottom: '10px', marginTop: '5px' }}>स्थायी ठेगाना</h3>
          {getSelectField(
            'प्रदेश',
            getProvinces(),
            'permanentState',
            myState.permanentState,
            (key, value) => {
              myState.permanentDistrict = null;
              myState.getPalikas = null;
              onChange(key, value);
            },
            true
          )}
        </Grid>
        <Grid item xs={4}>
          <br />
          {getSelectField(
            'जिल्ला',
            myState.permanentState ? getDistricts(myState.permanentState) : [],
            'permanentDistrict',
            myState.permanentDistrict,
            (key, value) => {
              myState.getPalikas = null;
              onChange(key, value);
            },
            true
          )}
        </Grid>
        <Grid item xs={4}>
          {getSelectField(
            'पालिका',
            myState.permanentState && myState.permanentDistrict
              ? getPalikas(myState.permanentState, myState.permanentDistrict)
              : [],
            'permanentPalika',
            myState.permanentPalika,
            onChange,
            true
          )}
        </Grid>
        <Grid item xs={4}>
          {getInputField(
            'वार्ड',
            'स्थायी ठेगाना: वार्ड',
            'permanentWardNo',
            myState.permanentWardNo,
            onChange,
            true,
            'number'
          )}
        </Grid>
        <Grid item xs={4}>
          {getInputField('टोल', 'स्थायी ठेगाना: टोल', 'permanentTole', myState.permanentTole, onChange, true)}
        </Grid>
        <Grid item xs={6}>
          <h4 style={{ marginBottom: '10px', marginTop: '5px' }}>विदेशिको हकमा</h4>
          {getInputField(
            'देश',
            'स्थायी ठेगाना: देश',
            'permanentForeignCountry',
            myState.permanentForeignCountry,
            onChange
          )}
        </Grid>
        <Grid item xs={6}>
          <br />
          {getInputField(
            'राहदानी नं',
            'राहदानी नं',
            'permanentForeignPassportNo',
            myState.permanentForeignPassportNo,
            onChange
          )}
        </Grid>
      </Grid>
      <Grid>
        <FormControlLabel
          control={
            <Checkbox
              checked={isTemporarySame}
              onChange={(e) => copyAddress(e)}
              name="isTemporarySame"
              color="primary"
            />
          }
          label="स्थायीठेगाना र अस्थायी ठेगाना समान छ (माथिको जस्तै)"
        />
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <h3 style={{ marginBottom: '10px', marginTop: '5px' }}>अस्थायी ठेगाना</h3>
          {getSelectField('प्रदेश', getProvinces(), 'temporaryState', myState.temporaryState, (key, value) => {
            myState.temporaryDistrict = null;
            myState.temporaryPalika = null;
            onChange(key, value);
          })}
        </Grid>
        <Grid item xs={6}>
          <br />
          {getSelectField(
            'जिल्ला',
            myState.temporaryState ? getDistricts(myState.temporaryState) : [],
            'temporaryDistrict',
            myState.temporaryDistrict,
            (key, value) => {
              myState.temporaryPalika = null;
              onChange(key, value);
            }
          )}
        </Grid>
        <Grid item xs={4}>
          {getSelectField(
            'पालिका',
            myState.temporaryState && myState.temporaryDistrict
              ? getPalikas(myState.temporaryState, myState.temporaryDistrict)
              : [],
            'temporaryPalika',
            myState.temporaryPalika,
            onChange
          )}
        </Grid>
        <Grid item xs={4}>
          {getInputField(
            'वार्ड',
            'अस्थायी ठेगाना: वार्ड',
            'temporaryWardNo',
            myState.temporaryWardNo,
            onChange,
            false,
            'number'
          )}
        </Grid>
        <Grid item xs={4}>
          {getInputField('टोल', 'अस्थायी ठेगाना: टोल', 'temporaryTole', myState.temporaryTole, onChange)}
        </Grid>
        <Grid item xs={6}>
          <h4 style={{ marginBottom: '10px', marginTop: '5px' }}>विदेशिको हकमा</h4>
          {getInputField(
            'देश',
            'स्थायी ठेगाना: पालिका',
            'temporaryForeignCountry',
            myState.temporaryForeignCountry,
            onChange
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <h3 style={{ marginBottom: '10px', marginTop: '5px' }}>बैंकको विवरण</h3>
          {getInputField('नाम', 'बैंकको नाम', 'bankName', myState.bankName, onChange)}
        </Grid>
        <Grid item xs={6}>
          <br />
          {getInputField('खाता नम्बर', 'बैंकको खाता नम्बर', 'bankAccountNo', myState.bankAccountNo, onChange)}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <h3 style={{ marginBottom: '10px', marginTop: '5px' }}>थप विवरण</h3>
          {getInputField('व्यवसाय', 'व्यवसायको विवरण', 'business', myState.business, onChange)}
        </Grid>
        <Grid item xs={6}>
          <br />
          {getRadio(
            'शैक्षिक योग्यता',
            educationQualificationOption,
            'educationQualification',
            myState.educationQualification,
            onChange
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <h3 style={{ marginBottom: '10px', marginTop: '5px' }}>विदेशी रोजगार विवरण</h3>
          {getRadioBoolean(
            'विदेशी रोजगार मा परिवार सदस्य',
            isFamilyMembersInForeignOption,
            'isFamilyMembersInForeign',
            myState.isFamilyMembersInForeign,
            onChange
          )}
        </Grid>
        <Grid item xs={5}>
          {getInputField(
            'देशको नाम',
            'विदेशी देशको नाम',
            'foreignFamilyCountryName',
            myState.foreignFamilyCountryName,
            onChange
          )}
        </Grid>
        <Grid item xs={4}>
          {getInputField(
            'विदेशमा रहेका परिवार संख्या',
            'विदेशमा रहेका परिवार संख्या',
            'countOfFamilyMembersInForeign',
            myState.countOfFamilyMembersInForeign,
            onChange
          )}
        </Grid>
      </Grid>
      <br />
      <ButtonGroup color="primary" aria-label="primary button group">
        <Button onClick={() => submit()} disabled={isDisabled}>
          बुझाउनुहोस् &nbsp;
          {isDisabled ? <CircularProgress size={30} /> : null}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default Personal;
