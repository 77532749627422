import { Grid, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useState } from 'react';
import PrastabanaService from 'services/prastabana';
import RenderInput from './RenderInput';
import { getUserInfo } from 'utils/decodeJWT';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const fields = [
  { id: 'date', label: 'मिति', type: 'date' },
  { id: 'publication', label: 'प्रकाशन', type: 'text' },
  { id: 'number_of_polic', label: 'नम्बर अफ पोलिस', type: 'number' },
  { id: 'ward_no', label: 'वार्ड नं', type: 'number' },
  { id: 'name', label: 'नाम', type: 'text' },
  { id: 'cost_sharing', label: 'िसकाे शेयरिंग ', type: ' text' },
];

const DeclerationForm = ({ applicationDetails, setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  const getValidationSchema = (fields) => {
    const shape = {};
    fields.forEach((field) => {
      shape[field.id] = Yup.string().required(`${field.label} is required`);
    });
    return Yup.object().shape(shape);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Grid item xs={12}>
        <h3>समान प्रकृतिको अनुदान नलिएको स्वघोषणा</h3>
      </Grid>
      <Formik
        initialValues={{
          app: applicationDetails && applicationDetails?.id,
          date: '',
          publication: '',
          number_of_polic: '',
          ward_no: '',
          name: '',
          cost_sharing: '',
          person: getUserInfo().person_id,
        }}
        validationSchema={getValidationSchema(fields)}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            const response = await PrastabanaService.addDecleration(values);
            if (response?.id) {
              setActiveStep(10);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
      >
        {(formik) => <RenderInput fields={fields} formik={formik} isDisabled={isDisabled} />}
      </Formik>
    </div>
  );
};

export default DeclerationForm;
