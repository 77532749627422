import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import * as routes from 'constants/routes';

import Home from 'views/Home';
import SignIn from 'views/SignIn';
import SignUp from 'views/SignUp';
import Detail from 'views/Detail';
import Dashboard from 'views/Dashboard';
import Survey from 'views/Survey';
import SamuhaSahakariFarmEntry from 'views/ExtraForms/SamuhaSahakariFarmEntry';
import DataEntryList from 'views/Detail/DataEntryWrapper';
import ContactUs from 'views/ContactUs';

import 'assets/css/App.css';
import PrivateRoute from 'components/PrivateRoute';
import PersonalEntry from 'views/ExtraForms/PersonalEntry';
import TableWrapper from 'views/Detail/TableWrapper';
import SahakariTable from 'views/Detail/SahakariTable';
import SamuhaTable from 'views/Detail/SamuhaTable';
import FarmTable from 'views/Detail/FarmTable';
import FarmerFullDetails from 'views/FullDetails';
import FarmerCard from 'views/Detail/FarmerCard';
import FertilizerCalculator from 'views/ExtraInfo/FertilizerCalculator';
import BaliPatroList from 'views/baliPatro/baliPatroList';
import baliPatro from 'views/baliPatro';
import SewaPradayakMenu from 'views/sewaPradayak/sewaPradayakMenu';
import Suchana from 'views/SuchanaPrakashan/Suchana';
import Prakashan from 'views/SuchanaPrakashan/Prakashan';
import Sewa from 'views/sewa';
import PrastabanaEntry from 'views/ExtraForms/PrastabanaEntry';
import YouthEntry from 'views/ExtraForms/YouthEntry';
import KrishiEntry from 'views/ExtraForms/KrishiEntry';
import SinchaiEntry from 'views/ExtraForms/SinchaiEntry';
import PrastabanaTable from 'views/Detail/PrastabanaTable';
import YouthTable from 'views/Detail/YouthTable';
import KrishiTable from 'views/Detail/KrishiTable';
// import SinchaiTable from 'views/Detail/SinchaiTable';
import SinglePrastabana from 'views/DetailsView/SinglePrastabana';
import SingleYouth from 'views/DetailsView/SingleYouth';
import PrastabanaDataEntry from 'components/Questions/PrastabanaDataEntry';
import ApplicationDetailTable from 'views/Detail/ApplicationDetailTable';
import SingleApplication from 'views/DetailsView/SingleApplication';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path={routes.SIGNIN} component={SignIn} />
        <Route path={routes.SIGNUP} component={SignUp} />
        <Route path={routes.SUCHANA} component={Suchana} />
        <Route path={routes.PRAKASHAN} component={Prakashan} />

        <PrivateRoute path={routes.DETAILS} component={TableWrapper} />
        <PrivateRoute path={routes.PERSONAL_DETAILS} component={Detail} />
        <PrivateRoute path={routes.SAHAKARI_DETAILS} component={SahakariTable} />
        <PrivateRoute path={routes.SAMUHA_DETAILS} component={SamuhaTable} />
        <PrivateRoute path={routes.YOUTH_DETAILS} component={YouthTable} />
        <PrivateRoute path={routes.KRISHI_DETAILS} component={KrishiTable} />
        {/* <Route path={routes.SINCHAI_DETAILS} component={SinchaiTable} /> */}
        <PrivateRoute path={routes.FARM_DETAILS} component={FarmTable} />
        <PrivateRoute path={routes.PRASTABANA_DETAILS} component={PrastabanaTable} />
        <PrivateRoute path={routes.APPLICATION_DETAILS} component={ApplicationDetailTable} />
        <PrivateRoute path={routes.PRASTABANA_DETAILS_SINGLE} component={SinglePrastabana} />
        <PrivateRoute path={routes.APPLICATION_DETAILS_SINGLE} component={SingleApplication} />
        <PrivateRoute path={routes.YOUTH_DETAILS_SINGLE} component={SingleYouth} />
        <PrivateRoute path={routes.DASHBOARD} component={Dashboard} />
        <PrivateRoute path={routes.PERSONAL} component={PersonalEntry} />
        <PrivateRoute path={routes.PRASTABANA_ENTRY} component={PrastabanaEntry} />
        <PrivateRoute path={routes.PRASTABANA_ENTRY_SECOND} component={PrastabanaDataEntry} />
        <PrivateRoute path={routes.YOUTH_ENTRY} component={YouthEntry} />
        <PrivateRoute path={routes.KRISHI_ENTRY} component={KrishiEntry} />
        {/* <Route path={routes.SINCHAI_ENTRY} component={SinchaiEntry} /> */}

        <PrivateRoute path={routes.SURVEY} component={Survey} />
        <PrivateRoute path={routes.SAMUHA_SAHAKARI_FARM_ENTRY} component={SamuhaSahakariFarmEntry} />
        <PrivateRoute path={routes.DATA_ENTRY_LIST} component={DataEntryList} />
        <PrivateRoute path={routes.FARMER_FULL_DETAILS} component={FarmerFullDetails} />
        <PrivateRoute path={routes.FARMER_CARD} component={FarmerCard} />
        <Route path={routes.CONTACT_US} component={ContactUs} />
        <Route path={routes.FERTILIZER} component={FertilizerCalculator} />
        <Route path={routes.BALI_PATRO_LIST} component={BaliPatroList} />
        <Route path={routes.BALIPATRO} component={baliPatro} />
        <Route path={routes.SEWA_PRADAYAK_MENU} component={SewaPradayakMenu} />
        <Route path={routes.SEWA_PRADAYAK} component={Sewa} />
        <Route path={routes.HOME} component={Home} />
      </Switch>
    </Router>
  );
};

export default Routes;
