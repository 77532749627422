import { mayor, sanu, madhav, durga, indra, pragya, chitra } from 'assets/images';

export const members = {
  janaPratinidhi: [
    {
      name: 'मान बहादुर केप्छाकी मगर',
      imageURL: mayor,
      designation: 'अध्यक्ष',
      email: '',
      phone: '९७४५१५५३६८, ९८४४८६७९७९',
    },
    { name: 'जित बहादुर खड्का', imageURL: sanu, designation: 'उपाध्यक्ष', email: '', phone: '९८४२८४५४३५' },
    // { name: 'रमेश राई', imageURL: madhav, designation: 'प्रमुख प्रशासकिय अधिकृत', email: '', phone: '९८५२८३५२७८' },
    {
      name: 'चित्र प्रसाद गुरागाई',
      imageURL: chitra,
      designation: 'प्रमुख प्रशासकिय अधिकृत',
      email: '',
      phone: '९८५२८३५२७८',
    },
  ],
  padhakari: [
    // { name: 'दुर्गा प्रसाद सुवेदी', imageURL: durga, designation: 'प्अधिकृत छैठौं' },
    // { name: 'इन्द्र नारायण साह', imageURL: indra, designation: 'कृषि अधिकृत' },
    // { name: 'प्रज्ञा पराजुली', imageURL: pragya, designation: 'सूचना तथा संचार प्रविधि अधिकृत' },
  ],
};
