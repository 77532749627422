import { Button, Container } from '@material-ui/core';
import SearchAppBar from 'components/Appbar';
import Banner from 'components/Banner';
import Copyright from 'components/Copyright';
import AdminBudgetForm from 'components/Forms/admin/AdminBudgetForm';
import PrastabanaAdminForm from 'components/Forms/admin/PrastabanaAdmin';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import PrastabanaService from 'services/prastabana';
import PrastabanaDoc from './PrastabanaDoc';
import AdminAgreementPrastabana from 'components/Forms/admin/AdminAgreementPrastabana';

const SinglePrastabana = () => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(1);
  const [adminPrasta, setAdminPrasta] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await PrastabanaService.getAdminPrasta();
      if (res?.count) {
        setActiveStep(2);
        setAdminPrasta(res.results[0]);
      }
      const adminBudget = await PrastabanaService.getPrastabanaAdminBudget();
      if (adminBudget?.count) {
        setActiveStep(3);
      }
      const agreement = await PrastabanaService.getPrastabanaAdminAgreement();
      if (agreement?.count) {
        setActiveStep(4);
      }
      setLoading(false);
    };
    fetchData();
  }, []);
  const openModal = () => {
    setOpen(true);
  };

  if (loading) {
    return <div>Loading</div>;
  }
  return (
    <div>
      <Banner />
      <SearchAppBar title="दर्ता भएको डाटा" />
      <Container maxWidth="lg">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="contained" onClick={openModal}>
            Open details
          </Button>
        </div>
        {activeStep === 1 && <PrastabanaAdminForm id={id} setActiveStep={setActiveStep} />}
        {activeStep === 2 && <AdminBudgetForm id={adminPrasta} setActiveStep={setActiveStep} />}
        {activeStep === 3 && <AdminAgreementPrastabana id={adminPrasta} setActiveStep={setActiveStep} />}
        {activeStep === 4 && <div>Submitted</div>}
        {open && <PrastabanaDoc id={id} open={open} setOpen={setOpen} />}
      </Container>
      <Copyright website="उदयपुरगढी गाउँपालिका" link="#" />
    </div>
  );
};

export default SinglePrastabana;
