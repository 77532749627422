import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core';
import React from 'react';

const RenderInput = ({ formik, fields, isDisabled }) => {
  const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}></Grid>
      {fields.map((field) => {
        switch (field?.type) {
          case 'boolean':
            return (
              <Grid item xs={12} md={6} key={field.id}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.maintenance_fund_available}
                      onChange={formik.handleChange}
                      name={field.id}
                      color="primary"
                    />
                  }
                  label={field.label}
                />
                {formik.touched.maintenance_fund_available && formik.errors.maintenance_fund_available && (
                  <div style={{ color: 'red', marginTop: '8px' }}>{formik.errors.maintenance_fund_available}</div>
                )}
              </Grid>
            );

          case 'select':
            return (
              <Grid item xs={12} md={6} key={field.id}>
                <TextField
                  id={field.id}
                  name={field.id}
                  label={field.label}
                  fullWidth
                  value={formik.values[field.id]}
                  onChange={formik.handleChange}
                  select
                  error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                  helperText={formik.touched[field.id] && formik.errors[field.id]}
                >
                  {field.options?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            );

          case 'date':
            return (
              <Grid item xs={12} md={6} key={field.id}>
                <TextField
                  id={field.id}
                  name={field.id}
                  label={field.label}
                  fullWidth
                  type="date"
                  InputProps={{ inputProps: { max: currentDate } }}
                  value={formik.values[field.id]}
                  onChange={formik.handleChange}
                  error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                  helperText={formik.touched[field.id] && formik.errors[field.id]}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            );

          default:
            return (
              <Grid item xs={12} md={6} key={field.id}>
                <TextField
                  id={field.id}
                  name={field.id}
                  label={field.label}
                  fullWidth
                  type={field.type}
                  value={formik.values[field.id]}
                  onChange={formik.handleChange}
                  error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                  helperText={formik.touched[field.id] && formik.errors[field.id]}
                />
              </Grid>
            );
        }
      })}
      <Grid item xs={12}>
        <ButtonGroup color="primary" aria-label="primary button group">
          <Button onClick={formik.handleSubmit} disabled={isDisabled}>
            बुझाउनुहोस् &nbsp;
            {isDisabled ? <CircularProgress size={30} /> : null}
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default RenderInput;
