import { Button, ButtonGroup, CircularProgress, Grid, MenuItem, TextField, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import KrishiService from 'services/krishiService';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const IrrigationForm = ({ setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [projectList, setProjectList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await PrastabanaService.getData();
      setProjectList(res?.results);
    };
    fetchData();
  }, []);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          address: '',
          agriculture_name: '',
          contact_number: '',
          prastabana: '',
          district: '',
          requester_name: '',
          seal_of_institution: '',
          ward_no: '',
          person: getUserInfo().person_id,
        }}
        onSubmit={async (values) => {
          try {
            const response = await KrishiService.addIrri(values);
            if (response?.id) {
              setActiveStep(2);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <TextField
                id="prastabana"
                name="prastabana"
                label="prastabana"
                fullWidth
                select
                required
                value={formik.values.prastabana}
                onChange={formik.handleChange}
                error={formik.touched.prastabana && Boolean(formik.errors.prastabana)}
                helperText={formik.touched.prastabana && formik.errors.prastabana}
              >
                {projectList &&
                  projectList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.project_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="address"
                name="address"
                label="ठेगाना"
                fullWidth
                required
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="agriculture_name"
                name="agriculture_name"
                label="कृषि नाम"
                fullWidth
                required
                value={formik.values.agriculture_name}
                onChange={formik.handleChange}
                error={formik.touched.agriculture_name && Boolean(formik.errors.agriculture_name)}
                helperText={formik.touched.agriculture_name && formik.errors.agriculture_name}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="contact_number"
                name="contact_number"
                label="सम्पर्क नम्बर"
                fullWidth
                required
                value={formik.values.contact_number}
                onChange={formik.handleChange}
                error={formik.touched.contact_number && Boolean(formik.errors.contact_number)}
                helperText={formik.touched.contact_number && formik.errors.contact_number}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="district"
                name="district"
                label="जिल्ला"
                fullWidth
                required
                value={formik.values.district}
                onChange={formik.handleChange}
                error={formik.touched.district && Boolean(formik.errors.district)}
                helperText={formik.touched.district && formik.errors.district}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="requester_name"
                name="requester_name"
                label="अनुरोधकर्ता नाम"
                fullWidth
                required
                value={formik.values.requester_name}
                onChange={formik.handleChange}
                error={formik.touched.requester_name && Boolean(formik.errors.requester_name)}
                helperText={formik.touched.requester_name && formik.errors.requester_name}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="seal_of_institution"
                name="seal_of_institution"
                label="संस्थाको छाप"
                fullWidth
                required
                value={formik.values.seal_of_institution}
                onChange={formik.handleChange}
                error={formik.touched.seal_of_institution && Boolean(formik.errors.seal_of_institution)}
                helperText={formik.touched.seal_of_institution && formik.errors.seal_of_institution}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="ward_no"
                name="ward_no"
                label="वडा नम्बर"
                fullWidth
                required
                type="number"
                value={formik.values.ward_no}
                onChange={formik.handleChange}
                error={formik.touched.ward_no && Boolean(formik.errors.ward_no)}
                helperText={formik.touched.ward_no && formik.errors.ward_no}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                  बुझाउनुहोस् &nbsp;
                  {isDisabled ? <CircularProgress size={30} /> : null}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default IrrigationForm;
