import axios from 'axios';
import { makeMappings } from '../utils/convertCase';

const baseURI = process.env.REACT_APP_API_BASE_URI+'api/';

class CropService {
  static async createCropsData(data, personId) {
    const url = baseURI + 'crops/';
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);
    mappings.person = parseInt(personId);

    const response = await axios.post(url, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async createCropExtraData(data, personId) {
    const url = baseURI + 'crop-extra-info/';
    const accessToken = localStorage.getItem('access_token');
    // eslint-disable-next-line 
    const person = localStorage.getItem('person');

    const mappings = makeMappings(data);
    
    mappings.person = parseInt(personId);

    const response = await axios.post(url, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}

export default CropService;
