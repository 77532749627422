import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import KrishiService from 'services/krishiService';
import { getUserInfo } from 'utils/decodeJWT';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const fields = [
  { id: 'additional_potential_household_count', label: 'Additional Potential Household Count', type: 'number' },
  { id: 'current_household_count', label: 'Current Household Count', type: 'number' },
  { id: 'distance_km_1', label: 'Distance KM 1', type: 'number' },
  { id: 'distance_km_2', label: 'Distance KM 2', type: 'number' },
  { id: 'district', label: 'District', type: 'text' },
  { id: 'estimated_time_hours_1', label: 'Estimated Time Hours 1', type: 'number' },
  { id: 'estimated_time_hours_2', label: 'Estimated Time Hours 2', type: 'number' },
  { id: 'from_place_1', label: 'From Place 1', type: 'text' },
  { id: 'from_place_2', label: 'From Place 2', type: 'text' },
  { id: 'irrigation_area_name', label: 'Irrigation Area Name', type: 'text' },
  { id: 'municipality', label: 'Municipality', type: 'text' },
  {
    id: 'ownership',
    label: 'Ownership',
    type: 'select',

    options: [
      {
        id: 'private',
        label: 'निजि',
      },
      {
        id: 'public',
        label: 'सार्वजनिक',
      },
      {
        id: 'others',
        label: 'अन्य',
      },
    ],
  },
  { id: 'possible_means_1', label: 'Possible Means 1', type: 'text' },
  { id: 'possible_means_2', label: 'Possible Means 2', type: 'text' },
  { id: 'project_name', label: 'Project Name', type: 'text' },
  {
    id: 'project_type',
    label: 'Project Type',
    type: 'select',
    options: [
      {
        id: 'नयाँ',
        label: 'नयाँ',
      },
      {
        id: 'नविकरण',
        label: 'नविकरण',
      },
      {
        id: 'सुधार',
        label: 'सुधार',
      },
      {
        id: 'अन्य',
        label: 'अन्य',
      },
    ],
  },
  { id: 'source_name', label: 'Source Name', type: 'text' },
  { id: 'source_type', label: 'Source Type', type: 'text' },
  { id: 'to_place_1', label: 'To Place 1', type: 'text' },
  { id: 'to_place_2', label: 'To Place 2', type: 'text' },
  { id: 'ward_no', label: 'Ward No', type: 'text' },
];
const ProjectIrrForm = ({ setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [projectList, setProjectList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await KrishiService.getIrri();
      setProjectList(res?.results);
    };
    fetchData();
  }, []);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          irrigation_application: projectList && projectList[0]?.id,

          additional_potential_household_count: '',
          current_household_count: '',
          distance_km_1: '',
          distance_km_2: '',
          district: '',
          estimated_time_hours_1: '',
          estimated_time_hours_2: '',
          from_place_1: '',
          from_place_2: '',
          irrigation_area_name: '',
          municipality: '',
          ownership: '',
          possible_means_1: '',
          possible_means_2: '',
          project_name: '',
          project_type: '',
          source_name: '',
          source_type: '',
          to_place_1: '',
          to_place_2: '',
          ward_no: '',
          person: getUserInfo().person_id,
        }}
        onSubmit={async (values) => {
          try {
            const response = await KrishiService.addIrrProjects(values);
            if (response?.id) {
              setActiveStep(14);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            {fields.map((field) => {
              if (field?.type === 'boolean') {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.maintenance_fund_available}
                          onChange={formik.handleChange}
                          name={field.id}
                          color="primary"
                        />
                      }
                      label={field.label}
                    />
                    {formik.touched.maintenance_fund_available && formik.errors.maintenance_fund_available && (
                      <div style={{ color: 'red', marginTop: '8px' }}>{formik.errors.maintenance_fund_available}</div>
                    )}
                  </Grid>
                );
              } else if (field.type === 'select') {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <TextField
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      fullWidth
                      value={formik.values[field.id]}
                      onChange={formik.handleChange}
                      select
                      error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                      helperText={formik.touched[field.id] && formik.errors[field.id]}
                    >
                      {field.options?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <TextField
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      fullWidth
                      type={field.type === 'number' ? 'number' : 'text'}
                      value={formik.values[field.id]}
                      onChange={formik.handleChange}
                      error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                      helperText={formik.touched[field.id] && formik.errors[field.id]}
                    />
                  </Grid>
                );
              }
            })}
            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                  बुझाउनुहोस् &nbsp;
                  {isDisabled ? <CircularProgress size={30} /> : null}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default ProjectIrrForm;
