import axios from 'axios';

import { makeMappings } from '../utils/convertCase';

const baseURI = process.env.REACT_APP_API_BASE_URI+'api/';
const url = baseURI + 'samuha-sahakari-farm/';

class SamuhaSahakariFarmService {
  static async createData(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(url, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getData() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}

export default SamuhaSahakariFarmService;
