import axios from 'axios';
import { makeMappings } from '../utils/convertCase';

const baseURI = process.env.REACT_APP_API_BASE_URI+'api/';
class LandService {
  static async createLandData(data, personId) {
    const url = baseURI + 'land-description/';
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);
    mappings.person = parseInt(personId);

    const response = await axios.post(url, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async createLandExtraData(data, personId) {
    const url = baseURI + 'land-extra-info/';
    const accessToken = localStorage.getItem('access_token');
    
    const mappings = makeMappings(data);
    mappings.person = parseInt(personId);

    const response = await axios.post(url, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}

export default LandService;
