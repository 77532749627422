import { udayapurgadhi1, udayapurgadhi2, udayapurgadhi3, udayapurgadhi4, udayapurgadhi5 } from 'assets/images';

export const carouselImagesFromLocal = [
  {
    photo:udayapurgadhi1,
    caption:"Udaypurgadhi"
  },
  {
    photo:udayapurgadhi2,
    caption:"Udaypurgadhi"
  },
  {
    photo:udayapurgadhi3,
    caption:"Udaypurgadhi"
  },
  {
    photo:udayapurgadhi4,
    caption:"Udaypurgadhi"
  }
]