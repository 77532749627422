import React, { useState } from 'react';

import {
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  FormLabel,
  FormControlLabel,
} from '@material-ui/core';

import 'assets/css/table.css';

const Fertilizer = () => {
  const fertilizerValue = {
    धान: {
      प्राङगारिक: 6,
      युरिया: 191.9,
      'डि.ऐ.पि.': 65.224,
      'म्यू.अ.पो': 50,
    },
    गहु: {
      प्राङगारिक: 6,
      युरिया: 174.9,
      'डि.ऐ.पि.': 108.7,
      'म्यू.अ.पो': 41.67,
    },
    मकै: {
      प्राङगारिक: 6,
      युरिया: 104.9,
      'डि.ऐ.पि.': 65.22,
      'म्यू.अ.पो': 50,
    },
    कोदो: {
      प्राङगारिक: 6,
      युरिया: 34.96,
      'डि.ऐ.पि.': 21.74,
      'म्यू.अ.पो': 16.67,
    },
    'उखु मुख्य बाली': {
      प्राङगारिक: 10,
      युरिया: 209.8,
      'डि.ऐ.पि.': 130.4,
      'म्यू.अ.पो': 66.76,
    },
    'उखु खुट्टी बाली': {
      प्राङगारिक: 10,
      युरिया: 275,
      'डि.ऐ.पि.': 130.4,
      'म्यू.अ.पो': 66.76,
    },
    अदुवा: {
      प्राङगारिक: 24,
      युरिया: 39.7,
      'डि.ऐ.पि.': 65.22,
      'म्यू.अ.पो': 100,
    },
    आलु: {
      प्राङगारिक: 30,
      युरिया: 109.6,
      'डि.ऐ.पि.': 108.7,
      'म्यू.अ.पो': 66.67,
    },
    'तरकारी बाली': {
      प्राङगारिक: 32,
      युरिया: 109.6,
      'डि.ऐ.पि.': 108.7,
      'म्यू.अ.पो': 66.67,
    },
    'बोडी, रहर': {
      प्राङगारिक: 46,
      युरिया: 9.45,
      'डि.ऐ.पि.': 86.96,
      'म्यू.अ.पो': 50,
    },
  };
  const [myState, setMyState] = useState({
    area: null,
    selectedBali: null,
    requiredFertilizer: { प्राङगारिक: 0, युरिया: 0, 'डि.ऐ.पि.': 0, 'म्यू.अ.पो': 0 },
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submit = () => {
    setIsSubmitted(true);
    console.log(myState);
  };

  const setState = (key, value) => {
    setIsSubmitted(false);
    if (key == 'selectedBali') {
      myState['requiredFertilizer'] = fertilizerValue[value];
    }
    myState[key] = value;
    setMyState({
      ...myState,
    });
  };

  return (
    <div>
      <h2>मल क्यालकुलेटर</h2>

      <FormControl style={{ width: '100%', margin: '5px 0' }}>
        <InputLabel>
          बालीनाली
          <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>
        </InputLabel>
        <Select value={myState.selectedBali} onChange={(e) => setState('selectedBali', e.target.value)}>
          {Object.keys(fertilizerValue).map((option, index) => {
            return (
              <MenuItem value={option} key={index}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl style={{ marginBottom: '5px', width: '100%' }}>
        <InputLabel htmlFor="my-input">मल प्रयोग क्षेत्रफल( हेक्टरमा)</InputLabel>
        <Input
          type="number"
          aria-describedby="my-helper-text"
          value={myState.area}
          onChange={(e) => setState('area', e.target.value)}
          required={true}
          disabled={false}
        />
        <FormHelperText id="my-helper-text">
          'क्षेत्रफल: हेक्टरमा'
          <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>
        </FormHelperText>
      </FormControl>
      <br />
      <ButtonGroup color="primary" aria-label="primary button group">
        <Button onClick={() => submit()}>हिसाब गर्नुहोस्</Button>
      </ButtonGroup>
      {isSubmitted && (
        <div style={{ marginTop: 15 }}>
          <h3>मलको मात्रा विवरण रिपोर्ट</h3>
          <table className="table">
            <thead>
              <tr>
                <th width="20%">क्षेत्रफल</th>
                <td>{myState.area} हेक्टर</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>बालीनाली</th>
                <td>{myState.selectedBali}</td>
              </tr>
              <tr>
                <th>मलको मात्रा विवरण</th>
                <td>
                  <ul style={{ paddingLeft: 20 }}>
                    {Object.keys(myState.requiredFertilizer).map((fertilizer) => {
                      return (
                        <li>
                          <div style={{ fontWeight: 'bold', display: 'inline' }}>{fertilizer}:</div>{' '}
                          {(myState.requiredFertilizer[fertilizer] * myState.area).toFixed(2)} कि.ग्रा
                        </li>
                      );
                    })}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Fertilizer;
