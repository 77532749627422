import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, ListItemAvatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tr: {
  
  },
  title: {
    fontSize: 14,
    color: '#2e2e2e',
  },
  count: {
    fontSize: 16,
    fontWeight: 'bold',
    // color: '#1FAA59',
    color: '#12B0E8',
    textAlign: 'right',
  },
  small: {
    width: 25,
    height: 25,
    borderRadius: 15,
    marginRight: 20,
  },
}));

const ListIcon = ({ title, imageUrl, count }) => {
  const classes = useStyles();

  return (
    <tr className={classes.tr}>
      { imageUrl &&
        <td width="15%">
          {/* <Avatar alt={title} src={imageUrl} class={classes.small} /> */}
          <div>
            <img src={imageUrl} class={classes.small} />
          </div>
        </td>
      }
      <td width={imageUrl ? "70%" : "85%"}>
        <span className={classes.title}>{title}</span>
      </td>
      <td width="15%" style={{textAlign: 'right'}}>
        <span className={classes.count}>{count}</span>
      </td>
    </tr>
  );
};

export default ListIcon;
