import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const FormSubmit = () => {
  const history = useHistory();

  const submit = () => {
    history.push('/personal-entry');
  };

  return (
    <div
      style={{
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '250px',
        width: '80%',
        margin: '15px auto',
      }}
    >
      <span className="fa fa-user-plus" style={{ fontSize: '28px', margin: '5px 0' }}></span>
      <p>तपाईंको डाटा सफलतापूर्वक अपलोड भएको छ</p>
      <br />
      <Button variant="outlined" color="primary" onClick={submit}>
        अर्को व्यक्तिको डाटा ईन्ट्री
      </Button>
    </div>
  );
};

export default FormSubmit;
