import React from 'react';
import Link from '@material-ui/core/Link';
import { Box, Typography } from '@material-ui/core';

const Copyright = ({ website, link }) => {
  return (
    <Box my={2}>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href={link}>
          {website}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
};

export default Copyright;
