import React from 'react';
import { Pie } from 'react-chartjs-2';

const PieChart = ({ labels, data, title }) => {
  const state = {
    labels: labels,
    datasets: [
      {
        label: title,
        backgroundColor: ['#12B0E8', '#FF6263', '#EDBF69'],
        hoverBackgroundColor: ['#207398', '#DE4839', '#E8BD0D'],
        data: data,
      },
    ],
  };

  return (
    <div style={{ margin: '25px 0' }}>
      <Pie
        data={state}
        options={{
          title: {
            display: true,
            text: title,
            fontSize: 20,
          },
          legend: {
            display: true,
            position: 'right',
          },
        }}
      />
    </div>
  );
};

export default PieChart;
