import { makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useState } from 'react';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';
import * as Yup from 'yup';
import RenderInput from './RenderInput';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const fields = [
  { id: 'background', label: 'पृष्ठभुमि', type: 'text', required: true },
  { id: 'project_name', label: 'परियोजनाको नाम', type: 'text', required: true },
  { id: 'indirect_beneficiaries', label: 'लाभान्वित कषक संख्या - अप्रत्यक्ष', type: 'number', required: true },
  {
    id: 'implementation_process',
    label: 'परियोजना कार्यान्वयन प्रकृया (कसको भुमिका के र कस्तो हुने)',
    type: 'text',
    required: true,
  },
  { id: 'current_status', label: 'कृषक समूह/कृषि सहकारीको वर्तमान स्थिति', type: 'text', required: true },
  { id: 'project_location', label: 'क्षेत्रफल', type: 'text', required: true },
  { id: 'area', label: 'परियोजना सञ्चालन हुने स्थान', type: 'text', required: true },
  { id: 'farmer_group_name', label: 'कृषक समुह/कृषि सहकारीको नाम', type: 'text', required: true },
  { id: 'address', label: 'ठेगाना', type: 'text', required: true },
  { id: 'establishment_year', label: 'स्थापना वर्ष', type: 'number', required: true },
  { id: 'total_members', label: 'जम्मा सदस्य संख्या', type: 'number', required: true },
  { id: 'female_members', label: 'महिला', type: 'number', required: true },
  { id: 'male_members', label: 'पुरुष', type: 'number', required: true },
  { id: 'dalit_members', label: 'दलित', type: 'number', required: true },
  { id: 'janajati_members', label: 'जनजाती', type: 'number', required: true },
  { id: 'other_members', label: 'अन्य', type: 'number', required: true },
  { id: 'direct_beneficiaries', label: 'लाभान्वित कषक संख्या', type: 'number', required: true },
  { id: 'existing_problems', label: 'विद्यमान समस्याहरु', type: 'text', required: true },
  { id: 'objective_of_project', label: 'परियोजनाको उद्धेश्य', type: 'text', required: true },
  { id: 'project_activities', label: 'परियोजानामा संचालन गरिने कृयाकलापहरु', type: 'text', required: true },
  {
    id: 'project_objectives',
    label: 'परियोजना कार्यान्वयन प्रकृया (कसको भुमिका के र कस्तो हुने)',
    type: 'text',
    required: true,
  },
  {
    id: 'expected_outcomes',
    label: 'परियोजनाको अपेक्षित उपलब्धिहरु र उपलब्धि प्राप्ति कसरी हुन्छ ?',
    type: 'text',
    required: true,
  },
];

const getValidationSchema = (fields) => {
  const shape = {};
  fields.forEach((field) => {
    if (field.required) {
      shape[field.id] =
        field.type === 'number'
          ? Yup.number().required(`${field.label} is required`)
          : Yup.string().required(`${field.label} is required`);
    }
  });
  return Yup.object().shape(shape);
};

const PrastabanaForm = ({ setActiveStep, applicationDetails }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          person: getUserInfo().person_id,
          application: applicationDetails && applicationDetails?.id,
          background: '',
          project_location: '',
          area: '',
          implementation_process: '',
          name_of_program_offered: applicationDetails && applicationDetails?.name_of_program_offered,
          farmer_group_name: '',
          address: '',
          status: 'In Progess',
          indirect_beneficiaries: '',
          establishment_year: '',
          female_members: '',
          male_members: '',
          current_status: '',
          dalit_members: '',
          date: new Date().toISOString().split('T')[0],
          janajati_members: '',
          other_members: '',
          direct_beneficiaries: '',
          switchRadio: '',
          total_members: '',
          direct: false,
          indirect: false,
          existing_problems: '',
          objective_of_project: '',
          project_activities: '',
          project_objectives: '',
          expected_outcomes: '',
        }}
        validationSchema={getValidationSchema(fields)}
        onSubmit={async (values) => {
          try {
            const response = await PrastabanaService.addPrastabanaData(values);
            if (response?.id) {
              setActiveStep(3);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
            // history.push({
            //   pathname: '/survey',
            //   search: `?userId=${person.id}`,
            // });
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => <RenderInput fields={fields} formik={formik} isDisabled={isDisabled} />}
      </Formik>
    </div>
  );
};

export default PrastabanaForm;
