export const SIGNIN = '/login';
export const SIGNUP = '/register';

export const HOME = '/';
export const DASHBOARD = '/dashboard';

export const SURVEY = '/survey';
export const PERSONAL = '/personal-entry';
export const PRASTABANA_ENTRY = '/prastabana-entry';
export const PRASTABANA_ENTRY_SECOND = '/prastabana-data-entry';
export const PRASTABANA_DETAILS_SINGLE = '/prastabana-entry/:id';
export const APPLICATION_DETAILS_SINGLE = '/application-entry/:id';

export const YOUTH_ENTRY = '/youth-entry';
export const YOUTH_DETAILS_SINGLE = '/youth-entry/:id';

export const KRISHI_ENTRY = '/krishi-entry';
export const SINCHAI_ENTRY = '/sinchai-entry';
export const SAMUHA_SAHAKARI_FARM_ENTRY = '/samuha-sahakari-farm-entry';

export const DETAILS = '/details';
export const PERSONAL_DETAILS = '/personal-details';
export const SAMUHA_DETAILS = '/samuha-details';
export const SAHAKARI_DETAILS = '/sahakari-details';
export const YOUTH_DETAILS = '/youth-details';
export const KRISHI_DETAILS = '/krishi-details';
// export const SINCHAI_DETAILS = '/sinchai-details';
export const FARM_DETAILS = '/farm-details';
export const PRASTABANA_DETAILS = '/prastabana-details';
export const APPLICATION_DETAILS = '/application-details';

export const FARMER_CARD = '/farmar-card';

export const DATA_ENTRY_LIST = '/data-entry-list';
export const FARMER_FULL_DETAILS = '/farmer-full-details';

export const CONTACT_US = '/contact-us';

export const FERTILIZER = '/fertilizer';

export const BALI_PATRO_LIST = '/bali-patro-list';
export const BALIPATRO = '/bali-patro';

export const SEWA_PRADAYAK_MENU = '/sewa-pradayak-menu';
export const SEWA_PRADAYAK = '/sewa-pradayak';

export const SUCHANA = '/suchana';
export const PRAKASHAN = '/prakashan';
