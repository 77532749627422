import { Grid, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useState } from 'react';
import PrastabanaService from 'services/prastabana';
import RenderInput from './RenderInput';
import { getUserInfo } from 'utils/decodeJWT';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const fields = [
  { id: 'cattle_number', label: 'गाईको संख्या', type: 'number' },
  { id: 'cattle_condition', label: 'गाईको अवस्था', type: 'text' },
  { id: 'building_number', label: 'भवनको संख्या', type: 'number' },
  { id: 'building_condition', label: 'भवनको अवस्था', type: 'text' },
  { id: 'transport_facility_number', label: 'यातायात सुविधाको संख्या', type: 'number' },
  { id: 'transport_facility_condition', label: 'यातायात सुविधाको अवस्था', type: 'text' },
  { id: 'farmer_market_number', label: 'किसान बजारको संख्या', type: 'number' },
  { id: 'farmer_market_condition', label: 'किसान बजारको अवस्था', type: 'text' },
  { id: 'canal_number', label: 'नहरको संख्या', type: 'number' },
  { id: 'canal_condition', label: 'नहरको अवस्था', type: 'text' },
  { id: 'conveyanace_number', label: 'वाहनको संख्या', type: 'number' },
  { id: 'conveyanace_condition', label: 'वाहनको अवस्था', type: 'text' },
  { id: 'agriculture_tool_number', label: 'कृषि उपकरणको संख्या', type: 'number' },
  { id: 'agriculture_tool_condition', label: 'कृषि उपकरणको अवस्था', type: 'text' },
];

const OtherFacilities = ({ applicationDetails, setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  const getValidationSchema = (fields) => {
    const shape = {};
    fields.forEach((field) => {
      shape[field.id] = Yup.string().required(`${field.label} is required`);
    });
    return Yup.object().shape(shape);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Grid item xs={12}>
        <h3>अन्य सुविधा र विकास</h3>
      </Grid>
      <Formik
        initialValues={{
          app: applicationDetails && applicationDetails?.id,
          cattle_number: '',
          cattle_condition: '',
          building_number: '',
          building_condition: '',
          transport_facility_number: '',
          transport_facility_condition: '',
          farmer_market_number: '',
          farmer_market_condition: '',
          canal_number: '',
          canal_condition: '',
          conveyanace_number: '',
          conveyanace_condition: '',
          agriculture_tool_number: '',
          agriculture_tool_condition: '',
          person: getUserInfo().person_id,
        }}
        validationSchema={getValidationSchema(fields)}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            const response = await PrastabanaService.addOtherFacilitiesEntries(values);
            if (response?.id) {
              setActiveStep(6);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
      >
        {(formik) => <RenderInput fields={fields} formik={formik} isDisabled={isDisabled} />}
      </Formik>
    </div>
  );
};

export default OtherFacilities;
