import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { FieldArray, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import PrastabanaService from 'services/prastabana';
import YouthService from 'services/youthService';
import { getUserInfo } from 'utils/decodeJWT';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const YouthAssociationForm = ({ setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await YouthService.getYouth();
      setData(res?.results);
    };
    fetchData();
  }, []);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };
  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          applicant_name: '',
          contact_number: '',
          decision_date: '',
          grant: data && data[0]?.id,
          grant_amount: '',
          guarantee_amount: '',
          purpose: '',
          recommending_officer_name: '',
          recommending_officer_position: '',
          recommending_organization_name: '',
          signature_date: new Date().toISOString().split('T')[0],
        }}
        onSubmit={async (values) => {
          try {
            const response = await YouthService.addYouthAss(values);
            if (response?.id) {
              setActiveStep(4);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="applicant_name"
                name="applicant_name"
                label="आवेदकको नाम"
                fullWidth
                required
                value={formik.values.applicant_name}
                onChange={formik.handleChange}
                error={formik.touched.applicant_name && Boolean(formik.errors.applicant_name)}
                helperText={formik.touched.applicant_name && formik.errors.applicant_name}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="contact_number"
                name="contact_number"
                label="सम्पर्क नम्बर"
                fullWidth
                required
                value={formik.values.contact_number}
                onChange={formik.handleChange}
                error={formik.touched.contact_number && Boolean(formik.errors.contact_number)}
                helperText={formik.touched.contact_number && formik.errors.contact_number}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="decision_date"
                name="decision_date"
                label="निर्णय मिति"
                type="date"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                value={formik.values.decision_date}
                onChange={formik.handleChange}
                error={formik.touched.decision_date && Boolean(formik.errors.decision_date)}
                helperText={formik.touched.decision_date && formik.errors.decision_date}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="grant_amount"
                name="grant_amount"
                label="अनुदान रकम"
                fullWidth
                required
                value={formik.values.grant_amount}
                onChange={formik.handleChange}
                error={formik.touched.grant_amount && Boolean(formik.errors.grant_amount)}
                helperText={formik.touched.grant_amount && formik.errors.grant_amount}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="guarantee_amount"
                name="guarantee_amount"
                label="धितो रकम"
                fullWidth
                required
                value={formik.values.guarantee_amount}
                onChange={formik.handleChange}
                error={formik.touched.guarantee_amount && Boolean(formik.errors.guarantee_amount)}
                helperText={formik.touched.guarantee_amount && formik.errors.guarantee_amount}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="purpose"
                name="purpose"
                label="उद्देश्य"
                fullWidth
                required
                value={formik.values.purpose}
                onChange={formik.handleChange}
                error={formik.touched.purpose && Boolean(formik.errors.purpose)}
                helperText={formik.touched.purpose && formik.errors.purpose}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="recommending_officer_name"
                name="recommending_officer_name"
                label="सिफारिस अधिकारीको नाम"
                fullWidth
                required
                value={formik.values.recommending_officer_name}
                onChange={formik.handleChange}
                error={formik.touched.recommending_officer_name && Boolean(formik.errors.recommending_officer_name)}
                helperText={formik.touched.recommending_officer_name && formik.errors.recommending_officer_name}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="recommending_officer_position"
                name="recommending_officer_position"
                label="सिफारिस अधिकारीको पद"
                fullWidth
                required
                value={formik.values.recommending_officer_position}
                onChange={formik.handleChange}
                error={
                  formik.touched.recommending_officer_position && Boolean(formik.errors.recommending_officer_position)
                }
                helperText={formik.touched.recommending_officer_position && formik.errors.recommending_officer_position}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="recommending_organization_name"
                name="recommending_organization_name"
                label="सिफारिस गर्ने संस्थाको नाम"
                fullWidth
                required
                value={formik.values.recommending_organization_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.recommending_organization_name && Boolean(formik.errors.recommending_organization_name)
                }
                helperText={
                  formik.touched.recommending_organization_name && formik.errors.recommending_organization_name
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="signature_date"
                name="signature_date"
                label="दस्तखत मिति"
                type="date"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                value={formik.values.signature_date}
                onChange={formik.handleChange}
                error={formik.touched.signature_date && Boolean(formik.errors.signature_date)}
                helperText={formik.touched.signature_date && formik.errors.signature_date}
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                  बुझाउनुहोस् &nbsp;
                  {isDisabled ? <CircularProgress size={30} /> : null}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default YouthAssociationForm;
