import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Banner from 'components/Banner';
import Copyright from 'components/Copyright';
import SearchAppBar from 'components/Appbar';

import { FiCard, FiCardActionArea, FiCardContent, FiCardMedia } from 'components/FullImageCard/FullImageCard';

import baliItems from 'constants/baliPatro/item';
import baliPatroDescription from 'constants/baliPatro/description';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '30px auto',
  },
  gridContainer: {},
  gridItem: {
    alignItems: 'center',
    height: 200,
    borderRadius: 20,
    '&:hover': {
      background: '#88A1ff',
    },
  },
  card: {
    maxWidth: 345,
  },
  fiCardContent: {
    height: 150,
    color: '#ffffff',
    backgroundColor: 'rgba(0,0,0,.44)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.14)',
    },
  },
  fiCardTitle: {
    borderRadius: 20,
    margin: 'auto 0',
    // textShadow: "-1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000",
    webkitTextStroke: "1px #F8F8F8",
	  textShadow: "0px 2px 4px #05a",
  },
  fiCardContentTextSecondary: {
    marginTop: 10,
    color: 'rgba(255,255,255,0.78)',
    webkitTextStroke: "1px #F8F8F8",
	  // textShadow: "0px 1px 4px #23430C",
    textShadow: "0px 1px 4px #230C43",
  },
});

const BaliPatroList = () => {
  const classes = useStyles();
  const history = useHistory();

  const baliItemKeys = Object.keys(baliItems);

  return (
    <div>
      <Banner />
      <SearchAppBar />
      <Container className={classes.container}>
        <Typography variant="h4" paragraph align="center">
          बाली पात्रो
        </Typography>
        <Grid container spacing={4} className={classes.gridContainer}>
          {baliItemKeys.map((bali, i) => {
            return (
              <Grid item sm={6} md={3} key={i}>
                <FiCard className={classes.card}>
                  <FiCardActionArea>
                    <FiCardMedia
                      media="picture"
                      alt={baliItems[bali].title}
                      image={baliItems[bali].icon}
                      title={baliItems[bali].title}
                      className={classes.image}
                    />
                    <FiCardContent className={classes.fiCardContent} onClick={() => history.push(`/bali-patro?query=${bali}`)}>
                      <Typography
                        gutterBottom
                        variant="h4"
                        component="h2"
                        paragraph
                        align="center"
                        className={classes.fiCardTitle}
                      > 
                        {baliItems[bali].title}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.fiCardContentTextSecondary}
                        component="p"
                        align="center"
                      >
                        {baliPatroDescription[bali][0]}
                      </Typography>
                    </FiCardContent>
                  </FiCardActionArea>
                </FiCard>
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <Copyright website="उदयपुरगढी गाउँपालिका" link="/" />
    </div>
  );
};

export default BaliPatroList;
