import React from 'react';

import { Container, Typography, Grid, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { defaultPhoto } from 'assets/images';

const useStyles = makeStyles({
	rootContainer: {
		border: '2px dashed #cdc',
		borderRadius: 10,
		marginTop: 25,
		marginBottom: 25,
		padding: 25,
		fontSize: '18px',
	},
	personPhoto: {
		width: 80,
		height: 80,
		borderRadius: 40,
	}
});


const PersonDetails = ({ data }) => {
	const classes = useStyles();
	
	// data = dummyData

	return(
		<Container className={classes.rootContainer}>
			<Typography variant="h5" color="secondary">{data.full_name_devnagari}को व्यक्तिगत जानकारी</Typography>

			<Container>
				<Grid container>
					<Grid item xs={12} md={6}>
						<Typography variant="h6">पुरा नाम: {data.full_name_devnagari}</Typography>
						<Typography variant="subtitle1">
							अङ्ग्रेजीमा: {data.full_name_english} &nbsp;&nbsp;
							<Chip label={data.gender} />
						</Typography>
						{/* <Typography variant="subtitle2">लिङ्ग: {data.gender}</Typography> */}
						<Typography variant="subtitle2">जातीय समुह: {data.caste_group}</Typography>
					</Grid>
					<Grid item xs={12} md={2}>
						<img alt="farmer" src={data.photo ? `https://udaypurgadhikrisibibhag.com/media/${data.photo}` : defaultPhoto} className={classes.personPhoto} />
					</Grid>
					<Grid item xs={12} md={4}></Grid>
				</Grid>
				<br></br>
				<Grid container style={{backgroundColor: '#ddd'}}>
					<Grid item xs={12} md={2}>
						<Typography variant="subtitle1">नागरिकता नं: {data.citizenship_no}</Typography>
					</Grid>
					<Grid item xs={12} md={3}>
						<Typography variant="subtitle1">नागरिकता किसिम: {data.citizenship_type}</Typography>
					</Grid>
					<Grid item xs={12} md={4}>
						<Typography variant="subtitle1">नागरिकता जारी गारिएको जिल्ला: {data.citizenship_issued_district}</Typography>
					</Grid>
					<Grid item xs={12} md={3}>
						<Typography variant="subtitle1">नागरिकता जारी मिती: {data.citizenship_issued_date}</Typography>
					</Grid>
				</Grid>
				<br></br>
				<Grid container>
					<Grid item xs={12} md={3}>
						जन्म मिती: {data.date_of_birth}
					</Grid>
					<Grid item xs={12} md={3}>
						उमेर: {data.age}
					</Grid>
					<Grid item xs={12} md={3}>
						मातृ भाषा: {data.mother_tongue}
					</Grid>
					<Grid item xs={12} md={3}>
						फोन नं: {data.phone_no}
					</Grid>
				</Grid>
				<br></br>
				<Grid container style={{backgroundColor: '#ddd'}}>
					<Grid item xs={12} md={4}>
						वैवाहिक स्तिथी: {data.marital_status}
					</Grid>
					<Grid item xs={12} md={4}>
						दम्पतिको नाम: {data.spouse_name}
					</Grid>
					<Grid item xs={12} md={4}>
						नागरिकता नं: {data.spouse_citizenship_no}
					</Grid>
				</Grid>
				<br></br>
				<Grid container>
					<Grid item xs={12} md={4}>
						बुवाको नाम: {data.father_name}
					</Grid>
					<Grid item xs={12} md={8}>
						बुवाको नागरिकता नं: {data.father_citizenship_no}
					</Grid>
				</Grid>
				<br></br>
				<Grid container style={{backgroundColor: '#ddd'}}>
					<Grid item xs={12} md={4}>
						आमाको नाम: {data.mother_name}
					</Grid>
					<Grid item xs={12} md={8}>
						आमाको नागरिकता नं: {data.mother_citizenship_no}
					</Grid>
				</Grid>
				<br></br>
				<Grid container>
					<Grid item xs={12} md={6}>
						<Typography>
							परिवारमा पुरुषको संख्या: {
								data.male_less_than_eighteen +
								data.male_eighteen_to_fifty_nine +
								data.male_sixty_or_above_eighteen +
								data.male_differently_abled 
							}
						</Typography>
						<Typography>
							परिवारमा महिलाको संख्या: {
								data.female_less_than_eighteen +
								data.female_eighteen_to_fifty_nine +
								data.female_sixty_or_above_eighteen +
								data.female_differently_abled 
							}
						</Typography>
					</Grid>
					<Grid item xs={12} md={6}>
					</Grid>
				</Grid>

				<br></br>

				<Typography variant="h6">स्थायी ठेगाना</Typography>
				<Grid container style={{backgroundColor: '#ddd'}}>
					<Grid item xs={12} md={4}>
						प्रदेश: {data.permanent_state}
					</Grid>
					<Grid item xs={12} md={8}>
						जिल्ला: {data.permanent_district}
					</Grid>
					<Grid item xs={12} md={4}>
						पालिका: {data.permanent_palika}
					</Grid>
					<Grid item xs={12} md={4}>
						वडा: {data.permanent_ward_no}
					</Grid>
					<Grid item xs={12} md={4}>
						टोल: {data.permanent_tole}
					</Grid>
				</Grid>
				<br></br>

				<Typography variant="h6">अस्थायी ठेगाना</Typography>
				<Grid container style={{backgroundColor: '#ddd'}}>
					<Grid item xs={12} md={4}>
						प्रदेश: {data.temporary_state}
					</Grid>
					<Grid item xs={12} md={8}>
						जिल्ला: {data.temporary_district}
					</Grid>
					<Grid item xs={12} md={4}>
						पालिका: {data.temporary_palika}
					</Grid>
					<Grid item xs={12} md={4}>
						वडा: {data.temporary_ward_no}
					</Grid>
					<Grid item xs={12} md={4}>
						टोल: {data.temporary_tole}
					</Grid>
				</Grid>

				<br></br>

				<Typography variant="h6">अन्य जानकारी</Typography>
				<Grid container style={{backgroundColor: '#ddd'}}>
					<Grid item xs={12} md={6}>शैक्षिक योग्यता: {data.education_qualification}</Grid>
					<Grid item xs={12} md={6}>व्यवसाय: {data.business}</Grid>
				</Grid>

				<br></br>

				<Grid container>
					<Grid item xs={12} md={6}>बैंकको नाम: {data.bank_name}</Grid>
					<Grid item xs={12} md={6}>बैंक खाता नं: {data.bank_account_no}</Grid>
				</Grid>

				<br></br>

				<Grid container style={{backgroundColor: '#ddd'}}>
					<Grid item xs={12} md={6}>विदेशमा रहेका परिवार संख्या: {data.count_of_family_members_in_foreign}</Grid>
					<Grid item xs={12} md={6}>कृषिमा संलग्न परिवार संख्या: {data.family_members_in_agriculture}</Grid>
				</Grid>

				{/* {_map(data, (value, key) => { */}
				{/* 	return <Typography variant="subtitle1">{value}</Typography>; */}
				{/* })} */}

			</Container>
		</Container>
	)
}

export default PersonDetails;
