import axios from 'axios';

import { makeMappings } from 'utils/convertCase';

const baseUri = process.env.REACT_APP_API_BASE_URI;
const url = baseUri + 'api/prastabana-entries/';
const budgeUrl = baseUri + 'api/prastabanabudget-entries/';
const applicationUrl = baseUri + 'api/application-entry/';
const trainingUrl = baseUri + 'api/prastabanaimplementationtable-entries/';

const applicationAttachedDocumentsUrl = baseUri + 'api/attacheddocuments-entries/';
const costsharingEntriesUrl = baseUri + 'api/costsharing-entries/';
const ActionPlanEntriesUrl = baseUri + 'api/actionplan-entries/';
const OtherFacilitiesEntriesUrl = baseUri + 'api/otherfacilities-entries/';
const ManpowerEntriesUrl = baseUri + 'api/manpower-entries/';
const ConductedEntriesUrl = baseUri + 'api/conducted-entries/';

//admin
const prastabanaAdmin = baseUri + 'api/prastabana-admin/';
const prastabanaAdminBudget = baseUri + 'api/prastabana-admin-budget/';
const prastabanaAdminAgreement = baseUri + 'api/prastabana-admin-agreement/';

const ApplicationAdminListViewUrl = baseUri + 'api/application-verification/';
const OfficialDetailViewUrl = baseUri + 'api/official-detail/';
const ApplicantEvaluationViewUrl = baseUri + 'api/applicant-evaluation/';
const MachineryEquipmentViewUrl = baseUri + 'api/machinery-equipment/';
const OtherSiteVerificationDetailViewUrl = baseUri + 'api/other-site-verification-detail/';
const ApplicantBusinessEvaluationViewUrl = baseUri + 'api/application-business-evalutaion-detail/';
const ApplicationAgreementViewUrl = baseUri + 'api/application-agreement/';
const PhysicalUrl = baseUri + 'api/physical-precursors/';
const ActivityViewUrl = baseUri + 'api/activity/';
const DecelarationUrl = baseUri + 'api/declaration-entries/';

class PrastabanaService {
  static async getPrastabanaAdminAgreement(data) {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(prastabanaAdminAgreement, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  }

  static async addPrastabanaAdminAgreement(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(prastabanaAdminAgreement, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async addPrastabanaAdminBudget(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(prastabanaAdminBudget, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getPrastabanaAdminBudget() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(prastabanaAdminBudget, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addAdminPrasta(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(prastabanaAdmin, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getAdminPrasta() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(prastabanaAdmin, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  }

  static async addPrastabanaData(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(url, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async addPrastabanaBudgetData(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(budgeUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getBudgetData() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(budgeUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getData() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getSingleData(id) {
    const accessToken = localStorage.getItem('access_token');
    const newUrl = url + id + '/';
    const response = await axios.get(newUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getApplicationData() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(applicationUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async addApplication(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(applicationUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getTrainingData() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(trainingUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async addTraining(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(trainingUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addApplicationAttachedDocuments(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(applicationAttachedDocumentsUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getApplicationAttachedDocuments() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(applicationAttachedDocumentsUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getCostsharingEntries() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(costsharingEntriesUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addCostsharingEntries(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(costsharingEntriesUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getConductedEntries() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(ConductedEntriesUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addConductedEntries(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(ConductedEntriesUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getManpowerEntries() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(ManpowerEntriesUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addManpowerEntries(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(ManpowerEntriesUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getOtherFacilitiesEntries() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(OtherFacilitiesEntriesUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addOtherFacilitiesEntries(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(OtherFacilitiesEntriesUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getActionPlanEntries() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(ActionPlanEntriesUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addActionPlanEntries(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(ActionPlanEntriesUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getActivityView() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(ActivityViewUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addActivityView(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(ActivityViewUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getApplicationAgreementView() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(ApplicationAgreementViewUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addApplicationAgreementView(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(ApplicationAgreementViewUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getApplicantBusinessEvaluationView() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(ApplicantBusinessEvaluationViewUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addApplicantBusinessEvaluationView(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(ApplicantBusinessEvaluationViewUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getOtherSiteVerificationDetailView() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(OtherSiteVerificationDetailViewUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addOtherSiteVerificationDetailView(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(OtherSiteVerificationDetailViewUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getMachineryEquipmentView() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(MachineryEquipmentViewUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addMachineryEquipmentView(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(MachineryEquipmentViewUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getApplicantEvaluationView() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(ApplicantEvaluationViewUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addApplicantEvaluationView(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(ApplicantEvaluationViewUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getOfficialDetailView() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(OfficialDetailViewUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addOfficialDetailView(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(OfficialDetailViewUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getApplicationAdminListView() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(ApplicationAdminListViewUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addApplicationAdminListView(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(ApplicationAdminListViewUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getPhysicalApi() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(PhysicalUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addPhysicalData(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(PhysicalUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getDeclaration() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(DecelarationUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async addDecleration(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(DecelarationUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}

export default PrastabanaService;
