export default {
  'सुदूर-पश्चिम प्रदेश': {
    कैलाली: [
      'धनगढी',
      'टिकापुर',
      'गोदावरी',
      'लम्की चुहा',
      'घोडाघोडी',
      'गौरीगंगा',
      'भजनी',
      'जानकी',
      'कैलारी',
      'जोशीपुर',
      'बर्गगोरिया',
      'मोहन्याल',
      'चुरे',
    ],
    अछाम: [
      'साफेबगर',
      'मंगलसेन',
      'पञ्चदेवल विनायक',
      'कमलबजार',
      'रामारोशन',
      'चौरपाटी',
      'तुर्माखाँद',
      'मेल्लेख',
      'ढँकारी',
      'बान्नीगडीजैगड',
    ],
    डोटी: [
      'दिपायल सिलगढी',
      'शिखर',
      'आदर्श',
      'पूर्वीचौकी',
      'केआईसिंह',
      'जोरायल',
      'सायल',
      'वोगटान–फुड्सिल',
      'बड्डी केदार',
    ],
    बझाङ: [
      'बुंगल',
      'जयपृथ्वी',
      'केदारस्यु',
      'थलारा',
      'बित्थडचिर',
      'छब्बीसपाथिभेरा',
      'छान्ना',
      'मष्टा',
      'दुर्गाथली',
      'तलकोट',
      'सुर्मा',
      'सइपाल',
    ],
    बाजुरा: [
      'बुढीगंगा',
      'बुढीनन्दा',
      'त्रिवेणी',
      'बडीमालिका',
      'खप्तड छेडेदह',
      'स्वामिकार्तिक खापर',
      'जगन्नाथ',
      'हिमाली',
      'गौमुल',
    ],
    कंचनपुर: [
      'भीमदत्त',
      'कृष्णपुर',
      'पुनर्वास',
      'बेलौरी',
      'वेदकोट',
      'शुक्लाफाँटा',
      'माहाकाली',
      'लालझाँडी',
      'बेलडाँडी',
    ],
    डडेलधुरा: [
      'परशुराम',
      'अमरगढी',
      'नवदुर्गा',
      'आलिताल',
      'गन्यापधुरा',
      'भागेश्वर',
      'अजयमेरु',
    ],
    बैतडी: [
      'पुर्चौडी',
      'दशरथचन्द',
      'पाटन',
      'मेलौली',
      'दोगडाकेदार',
      'डिलाशैनी',
      'सिगास',
      'पञ्चेश्वर',
      'सुर्नया',
      'शिवनाथ',
    ],
    दार्चुला: [
      'शैल्यशिखर',
      'महाकाली',
      'नौगाड',
      'मालिकार्जुन',
      'मार्मा',
      'लेकम',
      'दुहु',
      'ब्याँस',
      'अपि हिमाल',
    ],
  },
};
