import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import 'assets/css/table.css';
import { Button, IconButton, ButtonGroup, FormControl, FormHelperText, Grid, Input, InputLabel } from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircle from '@material-ui/icons/CheckCircle';

import OtherBusinessService from 'services/otherBusiness';
import WithoutPerson from './WithoutPerson';

import AlertDialog from 'components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginTop: 20,
  },
}));

export const getInputField = (title, helperText, index, key, state, setState, isRequired = false, isDisabled = false, type = 'text') => {
  return (
    <FormControl style={{ marginBottom: '5px', width: '80%' }}>
      <InputLabel htmlFor="my-input">{title}</InputLabel>
      <Input
        aria-describedby="my-helper-text"
        value={state}
        onChange={(e) => setState(index, key, e.target.value)}
        type={type}
        required={isRequired}
        disabled={isDisabled}
      />
      <FormHelperText id="my-helper-text">
        {helperText}
        {isRequired && <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>}
      </FormHelperText>
    </FormControl>
  );
};

const OtherBusiness = ({ nextPage, isInputDisabled }) => {
  const history = useHistory();

  const stateVariable = {
    businessName: null,
    investment: null,
    yearlyIncome: null,
    remarks: null,
  };

  const [myState, setMyState] = useState([{ ...stateVariable }]);

  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('userId');
  const [savedStatus, setSavedStatus] = useState([{status: "none"}]);

  const [openDialog, setOpenDialog] = useState(false);
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  }


  const onChange = (index, key, value) => {
    let currentEntry = [...myState];
    currentEntry[index][key] = value;
    setMyState([...currentEntry]);
  };

  const removeStateObject = (index) => {
    let currentEntry = [...myState];
    currentEntry.splice(index, 1);
    setMyState([...currentEntry]);

    savedStatus.splice(index, 1);
    setSavedStatus([...savedStatus]);
  };

  const changeSavedStatus = (index, status) => {
    savedStatus[index]['status'] = status;
    setSavedStatus([...savedStatus]);
  }

  const submitItem = async (index) => {
    try{
      await OtherBusinessService.createBusinessData(myState[index], userId);
      changeSavedStatus(index, 'saved');
      setIsSuccessDialog(true);
      setOpenDialog(true);
    }
    catch(e) {
      changeSavedStatus(index, 'error')
      setIsSuccessDialog(false);
      setOpenDialog(true);
    }
  }

  const submit = () => {
    nextPage(4);

    history.push({
      pathname: '/survey',
      search: `?userId=${userId}`,
    });

    setMyState([{ ...stateVariable }]);
  };

  return (
    <>
      {userId ? (
        <div>
          <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose}/>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: '10px' }}>कृषि जन्य तथा अन्य व्यवसायमा लगानी र आम्दानी</h3>

            <table className="table">
              <tr>
                <th>क्रम संख्या </th>
                <th>व्यवसाय</th>
                <th>बार्षिक लगानी</th>
                <th>वार्षिक आम्दानी</th>
                <th>कैफियत</th>
                <th></th>
              </tr>
              {myState.map((state, index) => {
                return (
                  <tr>
                    <td>{index + 1}.</td>
                    <td>
                      {getInputField(
                        '',
                        'व्यवसायको नाम',
                        index,
                        'businessName',
                        myState[index].businessName,
                        onChange,
                        true,
                        isInputDisabled || savedStatus[index].status === 'saved'
                      )}
                    </td>
                    <td>
                      {getInputField('', 'बार्षिक लगानी', index, 'investment', myState[index].investment, onChange, false, isInputDisabled || savedStatus[index].status === 'saved', 'number')}
                    </td>
                    <td>
                      {getInputField(
                        '',
                        'वार्षिक आम्दानी',
                        index,
                        'yearlyIncome',
                        myState[index].yearlyIncome,
                        onChange,
                        false,
                        isInputDisabled || savedStatus[index].status === 'saved',
                        'number'
                      )}
                    </td>
                    <td>{getInputField('', 'कैफियत', index, 'remarks', myState[index].remarks, onChange, false, isInputDisabled || savedStatus[index].status === 'saved')}</td>
                    <td style={{ textAlign: 'center' }}>
                      {/* <span */}
                      {/*   style={{ color: 'red' }} */}
                      {/*   title="एन्ट्री हटाउनुहोस्" */}
                      {/*   className="fa fa-trash" */}
                      {/*   onClick={() => removeStateObject(index)} */}
                      {/* /> */}
                      { savedStatus[index].status === 'saved' ?
                        <IconButton aria-label="saveitem">
                          <CheckCircle color="primary" fontSize="small"/>
                        </IconButton> :
                        <>
                          <IconButton onClick={() => submitItem(index)} aria-label="saveitem">
                            <SaveIcon color="primary" fontSize="small" />
                          </IconButton>
                          <IconButton onClick={() => removeStateObject(index)} aria-label="delete">
                            <DeleteIcon color="secondary" fontSize="small" />
                          </IconButton>
                        </>
                      }
                    </td>
                  </tr>
                );
              })}
            </table>
            <ButtonGroup color="primary" aria-label="primary button group" style={{ margin: '10px 0' }}>
              <Button
                onClick={() => {
                  setMyState([...myState, { ...stateVariable }]);
                  setSavedStatus([...savedStatus, {status: 'none'}]);
                }}
              >
                थप व्यवसायमा लगानी र आम्दानी
              </Button>
              <Button onClick={() => submit()}>बुझाउनुहोस्</Button>
            </ButtonGroup>
          </Grid>
        </div>
      ) : (
        <WithoutPerson nextPage={nextPage} />
      )}
    </>
  );
};

export default OtherBusiness;
