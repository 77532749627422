import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Container, Card, CardHeader, CardContent, Typography, IconButton } from '@material-ui/core';
import Banner from 'components/Banner';
import SearchAppBar from 'components/Appbar';
import { personalEntry } from 'assets/images';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '20px',
  },
  card: {
    cursor: 'pointer',
    backgroundColor: '#eee',
    '&:hover': {
      backgroundColor: '#ddd',
    },
    maxWidth: 300,
    marginRight: 30,
  },
  media: {
    height: 175,
    width: '100%',
  },
}));

const cardData = [
  { title: 'कृषक विवरण', imageUrl: personalEntry, link: '/personal-details' },
  {
    title: 'फार्म विवरण',
    imageUrl: 'https://images.unsplash.com/photo-1548460468-0e6f57732e8e?auto=format&fit=crop&w=400&q=80',
    link: '/farm-details',
  },
  {
    title: 'समूह विवरण',
    imageUrl: 'https://images.unsplash.com/photo-1461354464878-ad92f492a5a0?auto=format&fit=crop&w=400&q=80',
    link: '/samuha-details',
  },
  {
    title: 'प्रस्तावना विवरण',
    imageUrl: 'https://plus.unsplash.com/premium_photo-1661907005604-cec7ffb6a042?auto=format&fit=crop&w=400&q=80',
    link: '/application-details',
  },
  {
    title: 'प्रस्तावना पेश विवरण',
    imageUrl: 'https://images.unsplash.com/photo-1524486361537-8ad15938e1a3?auto=format&fit=crop&w=400&q=80',
    link: '/prastabana-details',
  },
  {
    title: 'युवा विवरण',
    imageUrl: 'https://plus.unsplash.com/premium_photo-1678655233203-dc309ba9e98f?auto=format&fit=crop&w=400&q=80',
    link: '/youth-details',
  },
  {
    title: 'कृषि विवरण',

    imageUrl: 'https://plus.unsplash.com/premium_photo-1681881413914-0d90c6fb42a0?auto=format&fit=crop&w=400&q=80',
    link: '/krishi-details',
  },
];

const TableWrapper = () => {
  const history = useHistory();
  const classes = useStyles();

  const handleCardClick = (link) => {
    history.push(link);
  };

  return (
    <>
      <Banner />
      <SearchAppBar title="दर्ता भएको डाटा" />
      <Container maxWidth="lg">
        <h2 style={{ margin: '15px 0' }}>कृषक तथा संस्थागत विवरण सुची</h2>
        <div className={classes.root}>
          {cardData.map((card, index) => (
            <Card key={index} className={classes.card} onClick={() => handleCardClick(card.link)}>
              <CardHeader
                action={
                  <IconButton onClick={() => history.push(card.link)}>
                    <i className="fa fa-eye" />
                  </IconButton>
                }
                title={card.title}
              />
              <img className={classes.media} src={card.imageUrl} alt={card.title} />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {card.title}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </Container>
    </>
  );
};

export default TableWrapper;
