import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Container, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Banner from 'components/Banner';
import SearchAppBar from 'components/Appbar';
import Copyright from 'components/Copyright';
import PersonalService from 'services/personal';

import PersonDetails from './PersonDetails';
import OtherDetails from './OtherDetails';

const useStyles = makeStyles({
  rootContainer: {
    backgroundColor: '#eee',
    marginTop: 25,
    marginBottom: 25,
    padding: 25,
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  bottomFixed: {
    position: 'fixed',
    bottom: 0,
  },
});

const FarmerFullDetails = () => {
  const classes = useStyles();

  const search = useLocation().search;
  let farmerId = new URLSearchParams(search).get('farmerId');

  const [data, setData] = useState(null);

  useEffect(async () => {
    const data = await PersonalService.getDetailsView(farmerId);
    setData(data);
  }, []);

  return (
    <>
      <div className="no-print">
        <Banner />
        <SearchAppBar />
      </div>
      <Container maxWidth="lg" className={classes.rootContainer}>
        <Typography variant="h3" color="primary">
          कृषकको विवरण
        </Typography>
        {/* <span onClick={() => window.print()} className="no-print print-btn"> */}
        {/*        <i className="fa fa-print"></i> */}
        {/*        &nbsp; &nbsp; प्रिन्ट गर्नुहोस् */}
        {/*    </span> */}
        {!data ? (
          <CircularProgress className={classes.circularProgress} />
        ) : (
          <Container>
            <PersonDetails data={data.person_details} />
            <OtherDetails data={data} />
          </Container>
        )}
      </Container>
      {data ? <Copyright website="उदयपुरगढी गाँउपालिका" /> : null}
    </>
  );
};

export default FarmerFullDetails;
