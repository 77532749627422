import { PersonAdd, Landscape, LocalFlorist, Pets, Business, BusinessCenter, Money, Storefront, AccountTree } from '@material-ui/icons';

const sidebarMenu = [
  // { title: 'व्यक्तिगत विवरण', icon: <PersonAdd color="primary" /> },
  { title: 'जग्गाको विवरण', icon: <Landscape color="primary" /> },
  { title: 'बालीनाली विवरण', icon: <LocalFlorist color="primary" /> },
  { title: 'जनावर र वस्तुभाउ ईन्ट्रि', icon: <Pets color="primary" /> },
  { title: 'व्यवसायमा लगानी र आम्दानी', icon: <Business color="primary" /> },
  { title: 'आगामी योजना', icon: <BusinessCenter color="primary" /> },
  { title: 'निकायबाट पाएका सुविधा', icon: <Money color="primary" /> },
  { title: 'कृषि तथा पशुपालनमा सक्रिय सेवा प्रदायक संस्थामा आवद्ध', icon: <AccountTree color="primary" /> },
  { title: 'मुख्य बजार', icon: <Storefront color="primary" /> },
];

export default sidebarMenu;
