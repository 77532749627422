import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import PersonalService from 'services/personal';

import Banner from 'components/Banner';
import SearchAppBar from 'components/Appbar';

import { emblemOfNepal, flag } from 'assets/images';
import 'assets/css/farmerCard.css';

const FarmerCard = () => {
  const [personalData, setPersonalData] = useState({});

  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('userId');

  useEffect(() => {
    const fetchPersonalData = async () => {
      const personalInfo = await PersonalService.getPersonalData(userId);

      setPersonalData(personalInfo);
    };

    fetchPersonalData();
  }, []);

  const getCardNo = (personId) => {
    let cardNo = "114134";
    const lenghtOfId = String(personId).length;
    const noOfZeros = 5 - lenghtOfId;
    for(let i=0; i < noOfZeros; i++) {
      cardNo += "0";
    }
    cardNo += personId;

    return cardNo;
  }

  return (
    <div>
      <div className="no-print">
        <Banner />
        <SearchAppBar title="दर्ता भएको डाटा" />
      </div>
      <span onClick={() => window.print()} className="no-print print-btn">
        <i className="fa fa-print"></i>
        &nbsp; &nbsp; प्रिन्ट गर्नुहोस्
      </span>
      <div className="farmer-card-wrapper">
        <div className="farmer-card-color-bar red"></div>
        <div className="farmer-card-color-bar navy"></div>
        <div className="farmer-card-header">
          <img src={emblemOfNepal} alt="EmblemOfNepal" />
          <div className="card-title">
            <h1>उदयपुरगढी गाउँपालिका</h1>
            <h1 style={{fontSize: 18}}>उदयपुर, नेपाल</h1>
            <h3>Farmer Identity Card | किसान परिचय पत्र</h3>
          </div>
          <img src={flag} alt="NepalFlag" />
        </div>
        {personalData && (
          <div className="farmer-info">
            <div className="info">
              <span>
                <b>कृषक परिचय नं: </b> {getCardNo(personalData.id)}
              </span>
              <br />
              <span>
                <b>नाम: </b> {personalData.full_name_devnagari}
              </span>
              <span>
                <b>Name: </b> {personalData.full_name_english}
              </span>
              <br />
              <span>
                <b>बुवाको नाम: </b> {personalData.father_name}
              </span>
              <span>
                <b>आमाको नाम: </b> {personalData.mother_name}
              </span>
              <br />
              <span>
                <b>नागरिकता नम्बर: </b> {personalData.citizenship_no}({personalData.citizenship_type})
              </span>
              <span>
                <b>जारी मिति: </b> {personalData.citizenship_issued_date}
              </span>
              <br />
              <span>
                <b>जन्म मिति: </b> {personalData.date_of_birth}
              </span>
              <span>
                <b>फोन नम्बर: </b> {personalData.phone_no}
              </span>
              <span>
                <b>लिङ्ग: </b> {personalData.gender}
              </span>

              <br />
              <span>
                <b>ठेगाना: </b> {personalData.permanent_state}
              </span>
              <span>
                {personalData.permanent_palika}, {personalData.permanent_district}
              </span>
              <span>वार्ड नं: {personalData.permanent_ward_no}</span>
            </div>
            <div className="photo">{personalData.photo && <img src={personalData.photo} alt="Personal Photo" />}</div>
          </div>
        )}
        <div className="farmer-card-color-bar red"></div>
        <div className="farmer-card-color-bar navy"></div>
      </div>
    </div>
  );
};

export default FarmerCard;
