import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ title, labels, data }) => {


  const wardLabel = labels.map((ward) => `वडा नं ${ward}`);

  const options = {
    title: {
      display: true,
      text: title,
      fontSize: 20,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const chartData = {
    labels: wardLabel,
    datasets: [...data],
  };

  return (
    <div style={{ margin: '25px 0' }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChart;
