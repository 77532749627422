const baliPatro = {
  काउली_अगौटे: [
    'पुषा, कात्तिकी, सर्लाही, दिपाली',
    'चैत्र–वैशाख (ज्येष्ठ–आषाढ)',
    'श्रावण–भाद्र',
    'आषाढ–भाद्र',
    'आश्विन–मंसिर',
    '20',
    'वेर्नासार्ने',
    '45',
    '45',
    '३२५-६५०',
  ],
  काउली_मध्यम: [
    'काठमाडौं स्थानिय, मिल्की वे, स्नो मिष्टिक, ज्यापु, कास्मिरे',
    'श्रावण–भाद्र',
    'भाद्र-अश्विन',
    'आश्विन–मंसिर',
    'पौष-माघ',
    '15',
    'वेर्नासार्ने',
    '60',
    '45',
    '६५०-१०००',
  ],
  काउली_पछौटे: [
    'युकन, स्नो डोम, स्नोवल १६, एन एस-९०, अन्ना-९०, किवो जायण्ट, स्नो वाईट, युमिको स्नो बेषट',
    'आश्विन–मंसिर',
    'आश्विन-कार्तिक',
    'पौष-चैत्र',
    'पौष-चैत्र',
    '15',
    'वेर्नासार्ने',
    '60',
    '45',
    '६५०-१०००',
  ],
  बन्दा: [
    'कोपन हेगन मार्केट, गोल्डेन एकर, प्राइड अफ इन्डिया, टि ६२१, ग्रीन स्टोन, सुपर ग्रीन ग्रीनहट, एसिया एक्सप्रेस',
    'जेष्ठ–मंसिर',
    'भाद्र-माघ',
    'बार्है महिना',
    'मंसिर-फाल्गुण',
    '15',
    'वेर्नासार्ने',
    '60',
    '45',
    '६५०-१३००',
  ],
  व्रोकाउली: [
    'ग्रीन स्प्राउटीङ, प्रिमियम कप, पिनाकल, कमेट',
    'भाद्र–मंसिर',
    'भाद्र–कार्तिक',
    'कार्तिक–फाल्गुण',
    'मंसिर–फाल्गुण',
    '44323',
    'वेर्नासारने',
    '60',
    '45',
    '२५०-५००',
  ],
  ग्याँठगोपी: [
    'हवाइट भियना र परपल भियाना',
    'श्रावण–फाल्गुण',
    'आश्विन–पौष',
    'आश्विन–चैत्र',
    'मंसिर–फाल्गुण',
    '25',
    'वेर्नासार्ने',
    '20',
    '20',
    '४५०-६५०',
  ],
  काँक्रो: [
    'भक्तपुर स्थानीय, एन एस–४०४, एन एस–४०८, निन्जा, भक्तपुर छनोट, हिमाल, मालिनी, डायनेष्टि, वर्षा',
    'माघ–भाद्र',
    'माघ–ज्येष्ठ',
    'चैत्र–मंसिर',
    'चैत्र–भाद्र',
    '35',
    'बीउ रोप्ने',
    '150',
    '50',
    '७००-१२००',
  ],
  घिरौला: [
    'ईश्वर, स्थानीय, नारायणी ज्यापु, कान्तिपुरे, पुषा चिललो',
    'फाल्गुण–वैशाख',
    'माघ– ज्येष्ठ',
    'ज्येष्ठ–कार्तिक',
    'वैशाख–आषाढ',
    '३५-४०',
    'बीउ रोप्ने',
    '200',
    '100',
    '५००-७००',
  ],
  तितेकरेला: [
    'कोयम्बाटुर लङ्ग, सेतो करेला, हरियो करेला, स्थानीय, चन्द,्र लक्ष्मी',
    'फाल्गुण–चैत्र',
    'फाल्गुण–ज्येष्ठ',
    'वैशाख–आश्विन',
    'वैशाख–आश्विन',
    '70',
    'बीउ रोप्ने',
    '150',
    '100',
    '५००-८००',
  ],
  फर्सी: [
    'स्थानीय',
    'माघ–चैत्र',
    'माघ–ज्येष्ठ',
    'वैशाख–ज्येष्ठ',
    'चैत्र–श्रावण',
    '70',
    'बीउ रोप्ने',
    '200',
    '200',
    '१५००-२०००',
  ],
  स्क्वास_फर्सी: [
    'ग्रे जुकिनी, रोण्डो, बुलाम हाउस, ब्ल्याक ब्यूटी',
    'फाल्गुण–चैत्र',
    'माघ–ज्येष्ठ',
    'वैशाख–ज्येष्ठ',
    'पौष–चैत्र',
    '70',
    'बीउ रोप्न',
    '100',
    '75',
    '६५०–८००',
  ],
  लौका: [
    'स्थानीय, समर प्रलिफीक लङ्ग, पन्त —१, सौरभ, एन एस ४२१',
    'फाल्गुण–वैशाख',
    'माघ–ज्येष्ठ',
    'वैशाख– कार्तिक',
    'चैत्र–कार्तिक',
    '40',
    'चैत्र–कार्तिक',
    '200',
    '200',
    '१०००-१३५५०',
  ],
  केराउ: [
    'सिक्कीम स्थानीय, आर्केल, आजाद',
    'आश्विन–मंसिर',
    'आश्विन–कार्तिक',
    'कार्तिक–माघ',
    'कार्तिक–माघ',
    '१.५–२ कि.ग्रा.',
    'बीउ रोप्ने',
    '70',
    '60',
    '३२५-६५०',
  ],
  बकुल्ला: [
    'स्थानीय, जापानी',
    'भाद्र–आश्विन',
    'आश्विन–कार्तिक',
    'मंसिर–फाल्गुण',
    'मंसिर–माघ',
    '२ कि.ग्रा.',
    'बीउ रोप्ने',
    '60',
    '30',
    '६५०-१०००',
  ],
  सिमी: [
    'त्रिशुली घ्यू सिमी, चौमासे सिमी, राजमा',
    'माघ–चैत्र',
    'भाद्र–आश्विन',
    'चैत्र–आषाढ',
    'कार्तिक–मंसिर',
    '२–२.५ कि.ग्रा.',
    'वीउ रोप्ने',
    '120',
    '30',
    '४००-६५०',
  ],
  बोडी: [
    'खुमल तने, सर्लाही तने, चन्द्र–०४१, फोला, चाईनिज ३२४, डवल हार्बेट, एडिला',
    'माघ–श्रावण',
    'भाद्र–आश्विन र  माघ–फाल्गुण',
    'चैत्र–आश्विन',
    'फाल्गुण–ज्येष्ठ',
    '७००–१३००',
    'बीउ रोप्ने',
    '120',
    '50',
    '३००-५००',
  ],
  खुर्सानी_पिरो: [
    'ज्वाला, स्थानीय, एन.एस १७०१, एन एस–११०१, कर्मा ७४७, कर्मा ७७७, गोली',
    'माघ–फाल्गुण',
    'भाद्र–कार्तिक',
    'वैशाख–आश्विन',
    'मंसिर–चैत्र',
    '२०-३०',
    'वेर्नासार्ने',
    '60',
    '30',
    '३००-४००',
  ],
  गोलभेंडा_अग्लो: [
    'श्रृजना, मनिषा, मनप्रेकस, लप्सी गेडे, रोमा, बिशेष, एन १६२, सि एल ११३१, बि एल ४१०, पुषा रुबी, इन्डोम, लक्ष्मी',
    'फाल्गुण–भाद्र',
    'भाद्र–कार्तिक',
    'वैशाख–कार्तिक',
    'मंसिर–फाल्गुण',
    '10',
    'वैशाख–कार्तिक	',
    '75',
    '60',
    '६५०-९००',
  ],
  भण्टा: [
    'पर्पल लड्ड, अर्का निधी, देब झुरी, नुर्की, सर्लाही सेतो',
    'फाल्गुण–आषाढ',
    'आश्विन–कार्तिक',
    'वैशाख–आश्विन',
    'मंसिर–वैशाख',
    '20',
    'वेर्नासार्ने',
    '60',
    '60',
    '१३००-२०००',
  ],
  भेडे_खुर्सानी: [
    'क्यालीफोर्निया वण्डर, वण्डरवेल एन एस  ६३२, सागर',
    'फाल्गुण–वैशाख',
    'आश्विन–कार्तिक',
    'वैशाख–आश्विन',
    'मंसिर–माघ',
    '20',
    'वेर्नासार्ने',
    '60',
    '45',
    '३००-६००',
  ],
  सलगम: [
    'पर्पल टप, काठमाडौं स्थानीय, वाइट भिएना',
    'श्रावण–फाल्गुण',
    'आश्विन–मंसिर',
    'आश्विन–वैशाख',
    'मंसिर–माघ',
    '65',
    'वीउ रोप्ने',
    '30',
    '30',
    '६५०-८००',
  ],
  गाजर: [
    'न्यूकोरोडा, वेनी, नान्टिस, च्यान्टेनी',
    'भाद्र–मंसिर',
    'आश्विन–कार्तिक',
    'मंसिर–वैशाख',
    'मंसिर–चैत्र',
    '200',
    'बीउ रोप्ने',
    '30',
    '10',
    '४००-६५०',
  ],
  चम्सुर: [
    'स्थानीय',
    'भाद्र–माघ',
    'असोज–मंसिर',
    'कार्तिक–फाल्गुण',
    'कार्तिक–माघ',
    '350',
    'बीउ छर्ने',
    '20',
    '3',
    '२५०-३५०',
  ],
  पालुंगो: [
    'स्थानीय, अल ग्रीन, पाटने',
    'भाद्र–माघ',
    'आश्विन–कार्तिक',
    'कार्तिक–चैत्र',
    'मंसिर–फाल्गुण',
    '350',
    'बीउ छर्ने',
    '20',
    '3',
    '४००-६५०',
  ],
  प्याज: [
    'नुवाकोटे, रेडक्रियोल, नासिक रेड',
    'आश्विन–मंसिर',
    'आश्विन–कार्तिक',
    'पौष–वैशाख',
    'फाल्गुण–वैशाख',
    '350',
    'फाल्गुण–वैशाख',
    '15',
    '10',
    '1300',
  ],
  लसुन: [
    'स्थानीय, चाइनिज',
    'श्रावण–माघ',
    'आश्विन–कार्तिक',
    'चैत्र–ज्येष्ठ',
    'वैशाख–ज्येष्ठ',
    '१५ कि.ग्रा.',
    'पोटी रोप्ने',
    '15',
    '15',
    '१२५-२५०',
  ],
  मूला: [
    'अल सिजन',
    'बाह्रै महिना',
    'भाद्र–फाल्गुण',
    'बाह्रै महिना',
    'मंसिर–वैशाख',
    '२५०',
    'बीउ रोप्ने',
    '२०',
    '२०',
    '१०००–१३५००',
  ],
  मेथी: [
    'स्थानीय, कसुरी',
    'भाद्र–मंसिर',
    'आश्विन–मंसिर',
    'आश्विन–फाल्गुण',
    'मंसिर–माघ',
    '३५०–६५०',
    'वीउ छर्ने',
    '30',
    '3',
    '३००–६५०',
  ],
  रायो: [
    'मार्फा चौडापात, खुमल चौडापात, खुमल रातोपात, जिनावा∙ रातो, ताङ्गखुवा',
    'भाद्र–मंसिर',
    'आश्विन–मंसिर',
    'आश्विन–फाल्गुण',
    'मंसिर–माघ',
    '7',
    'वेर्नासार्ने',
    '45',
    '30',
    '८००-१०००',
  ],
  स्वीस_चार्ड: [
    'सुसाग',
    'श्रावण–माघ',
    'आश्विन–मंसिर',
    'भाद्र–चैत्र',
    'मंसिर–फाल्गुण',
    '7',
    'वेर्नासार्ने',
    '45',
    '30',
    '६००-८००',
  ],
  भिण्डी: [
    'अर्का अनामिका, पुषा सावनी, पार्वती',
    'फाल्गुण–भाद्र',
    'माघ–जेष्ठ',
    'चैत्र–कार्तिक',
    'चैत्र–कार्तिक',
    '३५०-६००',
    'बीउ रोप्ने',
    '50',
    '30',
    '३२५-६५०',
  ],
};

export default baliPatro;
