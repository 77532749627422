import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import { DataGrid } from '@material-ui/data-grid';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';

import Banner from 'components/Banner';
import SearchAppBar from 'components/Appbar';
import Copyright from 'components/Copyright';
import PersonalService from 'services/personal';

import { defaultPhoto } from 'assets/images';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#879565',
      color: '#ffffff',
      fontWeight: 'bold',
    },
  },
  table: {
    minWidth: 700,
  },
  titleContainer: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconBtn: {
    margin: '5px',
    padding: '5px',
    borderRadius: 10,
    fontSize: 16,
    '&:hover': {
      background: '#88A1ff',
    },
  },
});

const getColumns = (classes) => {
  return [
    {
      field: 'फोटो',
      width: 80,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <img
            src={params.row.photo || defaultPhoto}
            style={{ width: '50px', height: '50px', borderRadius: '25px' }}
            alt={params.row.full_name_english}
          ></img>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'नाम',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px' }}>
            <span style={{ display: 'block' }}>{params.row.full_name_devnagari}</span>
            <span>{params.row.full_name_english}</span>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'नागरिकता को जानकारी',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px' }}>
            <span>{params.row.citizenship_no}</span>
            <span>({params.row.citizenship_type})</span>
            <span style={{ display: 'block' }}>{params.row.citizenship_issued_date}</span>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'ठेगाना',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px' }}>
            <span>{params.row.permanent_state}</span>
            <span style={{ display: 'block' }}>
              {params.row.permanent_palika}, {params.row.permanent_district}
            </span>
            <span style={{ display: 'block' }}>
              वडा नं {params.row.permanent_ward_no} - {params.row.permanent_tole}
            </span>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'व्यक्तिगत विवरण',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px' }}>
            <span style={{ display: 'block' }}>जन्म मिती: {params.row.date_of_birth || '-'}</span>
            <span style={{ display: 'block' }}>फोन नम्बर: {params.row.phone_no || '-'}</span>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'परिवारको जानकारी ',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px' }}>
            <span style={{ display: 'block' }}>बुवा: {params.row.father_name || '-'}</span>
            <span style={{ display: 'block' }}>आमा: {params.row.mother_name || '-'}</span>
            <span style={{ display: 'block' }}>दम्पती: {params.row.spouse_name || '-'}</span>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'विकल्प',
      width: '100%',
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px' }}>
            <Link to={'/farmar-card?userId=' + params.row.id} className={classes.iconBtn}>
              <span className="fa fa-id-card" />
            </Link>
            <Link to={'/farmer-full-details?farmerId=' + params.row.id} className={classes.iconBtn}>
              <span className="fa fa-eye" />
            </Link>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];
};

export default function CustomizedTables() {
  const classes = useStyles();

  const [personalData, setPersonalData] = useState({});
  const [selectedWardNo, setSelectedWardNo] = useState('सबै वार्ड नं');
  const [myWards, setMyWards] = useState([]);

  const groupByWard = (personal) => {
    const result = personal.reduce(function (r, a) {
      r[a.permanent_ward_no] = r[a.permanent_ward_no] || [];
      r[a.permanent_ward_no].push(a);
      return r;
    }, Object.create(null));

    return result;
  };

  useEffect(() => {
    const fetchPersonalData = async () => {
      const personal = await PersonalService.getAllPersonalData();

      const result = groupByWard(personal);

      const personalInfo = {
        'सबै वार्ड नं': personal,
        ...result,
      };

      setPersonalData(personalInfo);
      const availableWards = Object.keys(personalInfo);
      setMyWards(availableWards);
    };

    fetchPersonalData();
    // const availableWards = Object.keys(personalData);
    // setMyWards(availableWards);
  }, []);

  return (
    <>
      <Banner />
      <SearchAppBar title="दर्ता भएको डाटा" />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.titleContainer}>
            <h1>कृषक विवरण</h1>
            <FormControl style={{ width: 250 }}>
              <InputLabel id="land-type">वार्ड नम्बर चयन गर्नुहोस्</InputLabel>
              {myWards && (
                <Select labelId="land-type" value={selectedWardNo} onChange={(e) => setSelectedWardNo(e.target.value)}>
                  {myWards.map((menu, index) => {
                    return (
                      <MenuItem value={menu} key={index}>
                        {menu}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </FormControl>
          </Grid>
          <Hidden only={['lg', 'md', 'sm', 'xs']}>
            <Grid item md={3}>
              <Paper className={classes.paper}>Some content here</Paper>
            </Grid>
          </Hidden>
          <Grid item lg={12} md={9}>
            <div className={classes.root} style={{ height: 675, width: '100%' }}>
              {personalData[selectedWardNo] ? (
                <DataGrid
                  rows={personalData[selectedWardNo]}
                  columns={getColumns(classes)}
                  pageSize={100}
                  rowHeight={100}
                  sortable={false}
                />
              ) : (
                <div style={{ fontWeight: 'bold', fontSize: '18px', color: '#888888' }}>लोड हुँदैछ...</div>
              )}
              {!personalData[selectedWardNo] && (
                <div style={{ height: 40 }}>
                  {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
                  {/*   <CircularProgress color="secondary" /> */}
                  {/* </div> */}
                  <LinearProgress color="primary" />
                </div>
              )}
            </div>{' '}
          </Grid>{' '}
        </Grid>{' '}
      </Container>
      <Copyright website="उदयपुरगढी गाउँपालिका" link="#" />
    </>
  );
}
