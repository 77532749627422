import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { FormControl, Button } from '@material-ui/core';
import {
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Input,
  Select,
  MenuItem,
  InputLabel,
  Container,
  IconButton,
  Checkbox,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircle from '@material-ui/icons/CheckCircle';

import { NepaliDatePicker } from 'datepicker-nepali-reactjs';

import AnimalService from 'services/animal';
import WithoutPerson from './WithoutPerson';

import AlertDialog from 'components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 110,
    width: '12ch',
  },
  deleteButton: {
    marginTop: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const animalsNameList = [
  'गाई',
  'दुध दिने गाई',
  'भैंसी',
  'दुध दिने भैंसी',
  'भेंडा',
  'बाख्रा',
  'बोका/खसी',
  'बंगुर',
  'कुखुरा',
  'फुल पार्ने कुखुरा',
  'हांस',
  'फुल पार्ने हांस',
  'खरायो',
  'माछा',
];

const animalsStateObject = {
  animal_name: null,
  animal_species: null,
  hybrid_count: null,
  local_count: null,
  total_count: null,
  productivity_type: null,
  productive_count: null,
  average_production: null,
};

const animalsExtraInfoObject = {
  is_insured: false,
  insurance_id: null,
  insurance_start_date: null,
  insurance_expiry_date: null,
  insurance_amount: null,
};

export default ({ isInputDisabled, nextPage }) => {
  const classes = useStyles();
  const history = useHistory();

  // isEditable = true;
  // let isInputDisabled = !isEditable;

  const [animals, setAnimals] = useState([{ ...animalsStateObject }]);
  const [animalsExtraInfo, setAnimalsExtraInfo] = useState({ ...animalsExtraInfoObject });
  const [animalsSavedState, setAnimalsSavedState] = useState([{status: "none"}]);

  const [openDialog, setOpenDialog] = useState(false);
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  }

  const changeAnimalsState = (index, key, value, type) => {
    // if(type==="number") value = Number(value);
    let currentAnimals = [...animals];
    currentAnimals[index][key] = value;

    setAnimals([...currentAnimals]);
  };

  const changeAnimalExtraInfoState = (key, value, type) => {
    let currentAnimalExtraInfo = { ...animalsExtraInfo };
    currentAnimalExtraInfo[key] = value;

    setAnimalsExtraInfo({ ...currentAnimalExtraInfo });
  };

  const removeStateObject = (index) => {
    let currentAnimals = [...animals];
    // currentAnimals.pop();
    currentAnimals.splice(index, 1);

    setAnimals([...currentAnimals]);

    animalsSavedState.splice(index, 1);
    setAnimalsSavedState([...animalsSavedState]);
  };

  const changeSavedStatus = (index, status) => {
    animalsSavedState[index]['status'] = status;
    setAnimalsSavedState([...animalsSavedState]);
  }

  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('userId');

  const submitItem = async (index) => {
    try{
      await AnimalService.createAnimalsData(animals[index], userId);
      changeSavedStatus(index, 'saved');
      setIsSuccessDialog(true);
      setOpenDialog(true);
    }
    catch(e) {
      changeSavedStatus(index, 'error')
      setIsSuccessDialog(false);
      setOpenDialog(true);
    }
  }

  const submit = () => {
    (async () => {
      try{
        await AnimalService.createAnimalExtraData(animalsExtraInfo, userId);
        nextPage(3);

        history.push({
          pathname: '/survey',
          search: `?userId=${userId}`,
        });
      }
      catch(e) {
        setIsSuccessDialog(false);
        setOpenDialog(true);
      }
    })();
  };

  return (
    <>
      {userId ? (
        <Container>
          <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose}/>
          <Typography color="primary">पशु चौपायाको विवरण</Typography>
          <Container>
            {animals.map((animalState, i) => (
              <Container key={i}>
                <FormControl className={classes.margin} style={{ width: '2ch' }}>
                  <InputLabel htmlFor="serial_no">{i + 1}.</InputLabel>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="animal_name">जनावरको नाम</InputLabel>
                  <Select
                    value={animalState.animal_name}
                    onChange={(e) => changeAnimalsState(i, 'animal_name', e.target.value, 'string')}
                    id="animal_name"
                    aria-describedby="animal_name_helper"
                    disabled={isInputDisabled || animalsSavedState[i].status === 'saved'}
                  >
                    {animalsNameList.map((animalName) => (
                      <MenuItem value={animalName}>{animalName}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText id="animal_name_helper">
                    जनावरको नाम <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="animal_species">जनावरको प्रजाती</InputLabel>
                  <Input
                    value={animalState.animal_species}
                    onChange={(e) => changeAnimalsState(i, 'animal_species', e.target.value, 'number')}
                    id="animal_species"
                    disabled={isInputDisabled}
                    value={animalState['animal_species']}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="hybrid_count">उन्नत संख्या</InputLabel>
                  <Input
                    value={animalState.hybrid_count}
                    onChange={(e) => changeAnimalsState(i, 'hybrid_count', e.target.value, 'number')}
                    id="hybrid_count"
                    type="number"
                    disabled={isInputDisabled}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="local_count">स्थानिय संख्या</InputLabel>
                  <Input
                    value={animalState.local_count}
                    onChange={(e) => changeAnimalsState(i, 'local_count', e.target.value, 'number')}
                    id="local_count"
                    type="number"
                    disabled={isInputDisabled}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="total_count">कुल संख्या</InputLabel>
                  <Input
                    value={Number(animalState.hybrid_count) + Number(animalState.local_count)}
                    onChange={(e) =>
                      changeAnimalsState(
                        i,
                        'total_count',
                        animalState['hybrid_count'] + animalState['local_count'],
                        'number'
                      )
                    }
                    id="total_count"
                    type="number"
                    disabled={isInputDisabled}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="productivity_type">उत्पादन किसिम</InputLabel>
                  <Select
                    value={animalState.productivity_type}
                    onChange={(e) => changeAnimalsState(i, 'productivity_type', e.target.value, 'string')}
                    id="productivity_type"
                    disabled={isInputDisabled}
                  >
                    <MenuItem value="दुध (लिटर)">दुध (लिटर)</MenuItem>
                    <MenuItem value="मासु (किलो)">मासु (किलो)</MenuItem>
                    <MenuItem value="फुल (हजार)">फुल (हजार)</MenuItem>
                    <MenuItem value="ऊन (किलो)">ऊन (किलो)</MenuItem>
                    <MenuItem value="माछा (किलो)">माछा (किलो)</MenuItem>
                  </Select>
                </FormControl>
                {/* <FormControl className={classes.formControl}>
              <InputLabel htmlFor="productive_count">उत्पादनमुलक संख्या</InputLabel>
              <Input
                value={animalState.productive_count}
                onChange={(e) => changeAnimalsState(i, 'productive_count', e.target.value, 'number')}
                id="productive_count"
                disabled={isInputDisabled}
              />
            </FormControl> */}
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="average_production">औसत उत्पादन</InputLabel>
                  <Input
                    value={animalState.average_production}
                    onChange={(e) => changeAnimalsState(i, 'average_production', e.target.value, 'string')}
                    id="average_production"
                    type="number"
                    disabled={isInputDisabled}
                  />
                </FormControl>
                { animalsSavedState[i].status === 'saved' ?
                  <IconButton aria-label="saveitem" className={classes.deleteButton}>
                    <CheckCircle color="primary" fontSize="small"/>
                  </IconButton> :
                  <>
                    <IconButton onClick={() => submitItem(i)} aria-label="saveitem" className={classes.deleteButton}>
                      <SaveIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => removeStateObject(i)} aria-label="delete" className={classes.deleteButton}>
                      <DeleteIcon color="secondary" fontSize="small" />
                    </IconButton>
                  </>
                }
              </Container>
            ))}

            <Button
              onClick={() => {
                setAnimals([...animals, { ...animalsStateObject }]);
                setAnimalsSavedState([...animalsSavedState, {status: 'none'}]);
              }}
              variant="contained"
              size="small"
              color="primary"
              className={classes.margin}
            >
              थप जनावर
            </Button>
            {/* <IconButton onClick={() => removeStateObject()} aria-label="delete">
          <DeleteIcon color="secondary" fontSize="small" />
        </IconButton> */}
          </Container>

          <br></br>
          <Typography color="primary">पशु चौपाया सम्बन्धी अन्य विवरण</Typography>
          <br></br>

          <Container>
            <FormControl>
              <FormLabel>पशु चौपाया विमा</FormLabel>
              <FormControlLabel
                value="is_insured"
                control={
                  <Checkbox
                    id="is_insured"
                    checked={animalsExtraInfo.is_insured}
                    color="primary"
                    onChange={(e) => setAnimalsExtraInfo({ ...animalsExtraInfo, is_insured: e.target.checked })}
                  />
                }
                label="रहेको"
                labelPlacement="start"
              />
            </FormControl>
            <br></br>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="insurance_id">विमा ID</InputLabel>
              <Input
                value={animalsExtraInfo.insurance_id}
                onChange={(e) => changeAnimalExtraInfoState('insurance_id', e.target.value, 'string')}
                id="insurance_id"
                disabled={isInputDisabled || !animalsExtraInfo.is_insured}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="insurance_amount">विमा रकम</InputLabel>
              <Input
                value={animalsExtraInfo.insurance_amount}
                onChange={(e) => changeAnimalExtraInfoState('insurance_amount', e.target.value, 'string')}
                id="insurance_amount"
                disabled={isInputDisabled || !animalsExtraInfo.is_insured}
              />
            </FormControl>

            <br></br>
            <form style={{ color: 'rgba(0, 0, 0, 0.54)', marginLeft: 8, marginTop: 5 }}>
              <label htmlFor="insurance_start_date">विमा गरेको मिती</label>
              <NepaliDatePicker
                inputClassName="form-control"
                className=""
                id="insurance_start_date"
                value={animalsExtraInfo.insurance_start_date}
                onDateSelect={(value: string) => changeAnimalExtraInfoState('insurance_start_date', value, 'string')}
                options={{ calenderLocale: 'ne', valueLocale: 'en', disabled: true }}
                disabled={isInputDisabled || !animalsExtraInfo.is_insured}
              />
              <br></br>
              <label htmlFor="insurance_expiry_date">विमा सकिने मिती</label>
              <NepaliDatePicker
                inputClassName="form-control"
                className=""
                id="insurance_expiry_date"
                value={animalsExtraInfo.insurance_expiry_date}
                onDateSelect={(value: string) => changeAnimalExtraInfoState('insurance_expiry_date', value, 'string')}
                options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                disabled={isInputDisabled || !animalsExtraInfo.is_insured}
              />
            </form>
          </Container>
          <br></br>
          <Button
            onClick={() => {
              // setAnimalsExtraInfo({ ...animalsExtraInfoObject });
              // setAnimals([{ ...animalsStateObject }]);
              submit();
            }}
            variant="contained"
            color="primary"
            size="medium"
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            बुझाउनुहोस
          </Button>
        </Container>
      ) : (
        <WithoutPerson nextPage={nextPage} />
      )}
    </>
  );
};
