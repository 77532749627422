import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  FormLabel,
  FormControl,
  Button,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Container,
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';

import SamuhaSahakariFarmService from 'services/samuhaSahakariFarm';
import SamuhaSahakariFarmAssociationService from 'services/samuhaSahakariFarmAssociation';
import WithoutPerson from './WithoutPerson';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 50,
    minWidth: 90,
    width: '12ch',
  },
  formControlRadio: {
    margin: theme.spacing(1),
    minWidth: 180,
    width: '12ch',
  },
  deleteButton: {
    marginTop: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const organizationsObject = {
  is_in_entrepreneurship: null,
  entrepreneurship: null,
  is_in_samuha: null,
  samuha: null,
  is_in_sahakari: null,
  sahakari: null,
  is_in_farm: null,
  farm: null,
};

export default ({ isInputDisabled, nextPage }) => {
  const classes = useStyles();
  const history = useHistory();

  const [organizations, setOrganizations] = useState({ ...organizationsObject });
  const [samuhaSahakariFarmData, setSamuhaSahakariFarmData] = useState(null);

  const changeOrganizationsState = (key, value, type) => {
    let currentOrganizations = { ...organizations };
    currentOrganizations[key] = value;

    setOrganizations({ ...currentOrganizations });
  };

  useEffect(() => {
    SamuhaSahakariFarmService.getData()
      .then((data) => data.results)
      .then((res) => setSamuhaSahakariFarmData(res));
  }, []);

  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('userId');

  const submit = () => {
    (async () => SamuhaSahakariFarmAssociationService.createSamuhaSahakariFarmAssociationData(organizations, userId))();

    nextPage(7);

    history.push({
      pathname: '/survey',
      search: `?userId=${userId}`,
    });
  };

  return (
    <Container>
      {userId ? (
        <>
          <Container>
            <br></br>
            <br></br>
            <FormControl>
              <FormLabel>समूहमा आवद्ध</FormLabel>
              <FormControlLabel
                value="is_in_samuha"
                control={
                  <Checkbox
                    id="is_in_samuha"
                    checked={organizations.is_in_samuha}
                    color="primary"
                    onChange={(e) =>
                      setOrganizations({ ...organizations, is_in_samuha: e.target.checked, samuha: null })
                    }
                  />
                }
                label="रहेको"
                labelPlacement="start"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="samuha">समूह</InputLabel>
              <Select
                value={organizations.samuha}
                onChange={(e) => changeOrganizationsState('samuha', e.target.value, 'string')}
                id="samuha"
                aria-describedby="samuha_helper"
                disabled={isInputDisabled || !organizations.is_in_samuha}
              >
                {samuhaSahakariFarmData
                  ? samuhaSahakariFarmData.map((ssf) => {
                      if (ssf.category === 'समूह') {
                        return (
                          <MenuItem value={ssf.id}>
                            {ssf.name}, {ssf.palika}, {ssf.district}
                          </MenuItem>
                        );
                      }

                      return [];
                    })
                  : null}
              </Select>
              <FormHelperText id="samuha_helper">छान्नुहोस</FormHelperText>
            </FormControl>

            <br></br>
            <br></br>

            <FormControl>
              <FormLabel>सहकारीमा आवद्ध</FormLabel>
              <FormControlLabel
                value="is_in_sahakari"
                control={
                  <Checkbox
                    id="is_in_sahakari"
                    checked={organizations.is_in_sahakari}
                    color="primary"
                    onChange={(e) =>
                      setOrganizations({ ...organizations, is_in_sahakari: e.target.checked, sahakari: null })
                    }
                  />
                }
                label="रहेको"
                labelPlacement="start"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="sahakari">सहकारी</InputLabel>
              <Select
                value={organizations.sahakari}
                onChange={(e) => changeOrganizationsState('sahakari', e.target.value, 'string')}
                id="sahakari"
                aria-describedby="sahakari_helper"
                disabled={isInputDisabled || !organizations.is_in_sahakari}
              >
                {samuhaSahakariFarmData
                  ? samuhaSahakariFarmData.map((ssf) => {
                      if (ssf.category === 'सहकारी') {
                        return (
                          <MenuItem value={ssf.id}>
                            {ssf.name}, {ssf.palika}, {ssf.district}
                          </MenuItem>
                        );
                      }

                      return [];
                    })
                  : null}
              </Select>
              <FormHelperText id="sahakari_helper">छान्नुहोस</FormHelperText>
            </FormControl>

            <br></br>
            <br></br>

            <FormControl>
              <FormLabel>फरममा आवद्ध</FormLabel>
              <FormControlLabel
                value="is_in_farm"
                control={
                  <Checkbox
                    id="is_in_farm"
                    checked={organizations.is_in_farm}
                    color="primary"
                    onChange={(e) => setOrganizations({ ...organizations, is_in_farm: e.target.checked, farm: null })}
                  />
                }
                label="रहेको"
                labelPlacement="start"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="farm">फरम</InputLabel>
              <Select
                value={organizations.farm}
                onChange={(e) => changeOrganizationsState('farm', e.target.value, 'string')}
                id="farm"
                aria-describedby="farm_helper"
                disabled={isInputDisabled || !organizations.is_in_farm}
              >
                {samuhaSahakariFarmData
                  ? samuhaSahakariFarmData.map((ssf) => {
                      if (ssf.category === 'फरम') {
                        return (
                          <MenuItem value={ssf.id}>
                            {ssf.name}, {ssf.palika}, {ssf.district}
                          </MenuItem>
                        );
                      }

                      return [];
                    })
                  : null}
              </Select>
              <FormHelperText id="farm_helper">छान्नुहोस</FormHelperText>
            </FormControl>
          </Container>
          <br></br>
          <Button
            onClick={() => {
              submit();
            }}
            variant="contained"
            color="primary"
            size="medium"
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            बुझाउनुहोस
          </Button>
        </>
      ) : (
        <WithoutPerson nextPage={nextPage} />
      )}
    </Container>
  );
};
