import React, { useEffect, useState } from 'react';
import PrastabanaService from 'services/prastabana';

import ApplicationForm from 'components/Forms/ApplicationForm';
import BudgetForm from 'components/Forms/BudgetForm';
import TrainingForm from 'components/Forms/TrainingForm';
import PrastabanaForm from 'components/Forms/PrastabanaForm';
import PrastabanaTable from 'views/Detail/PrastabanaTable';
import { getUserInfo } from 'utils/decodeJWT';

const PrastaData = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [applicationDetails, setApplicationDetails] = useState(null);
  const [prastabanaDetails, setPrastabanaDetails] = useState(null);
  const userRole = getUserInfo().usertype;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const applications = await PrastabanaService.getApplicationData();
      if (applications?.count > 0) {
        setActiveStep(2);
        setApplicationDetails(applications?.results[0]);
      }
      const res = await PrastabanaService.getData();
      if (res?.count > 0) {
        setActiveStep(3);
        setPrastabanaDetails(res?.results[0]);
      }
      const budgetRes = await PrastabanaService.getBudgetData();
      if (budgetRes?.count > 0) {
        setActiveStep(4);
      }
      const implRes = await PrastabanaService.getTrainingData();
      if (implRes?.count > 0) {
        setActiveStep(5);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }
  if (activeStep === 1) {
    return <div>Please fill out the application form</div>;
  }

  return (
    <>
      {userRole !== 'admin' ? (
        <div>
          {/* {activeStep === 1 && (
            <ApplicationForm setActiveStep={setActiveStep} setApplicationDetails={setApplicationDetails} />
          )} */}
          {activeStep === 2 && <PrastabanaForm setActiveStep={setActiveStep} applicationDetails={applicationDetails} />}
          {activeStep === 3 && (
            <BudgetForm
              setActiveStep={setActiveStep}
              applicationDetails={applicationDetails}
              prastabanaDetails={prastabanaDetails}
            />
          )}
          {activeStep === 4 && (
            <TrainingForm
              setActiveStep={setActiveStep}
              applicationDetails={applicationDetails}
              prastabanaDetails={prastabanaDetails}
            />
          )}

          {activeStep === 5 && <div>Submitted</div>}
        </div>
      ) : (
        <PrastabanaTable
          setActiveStep={setActiveStep}
          applicationDetails={applicationDetails}
          prastabanaDetails={prastabanaDetails}
        />
      )}
    </>
  );
};

export default PrastaData;
