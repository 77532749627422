export default {
  'गण्डकी प्रदेश': {
    बागलुङ: [
      'बागलुङ',
      'गल्कोट',
      'जैमिनी',
      'ढोरपाटन',
      'वडिगाड',
      'काठेखोला',
      'निसीखोला',
      'वरेङ',
      'ताराखोला',
      'तमानखोला',
    ],
    गोरखा: [
      'गोरखा',
      'पालुङटार',
      'शहिद लखन',
      'बारपाक सुलीकोट',
      'आरूघाट',
      'सिरानचोक',
      'गण्डकी',
      'भिमसेनथापा',
      'अजिरकोट',
      'धार्चे',
      'चुम नुव्री',
    ],
    कास्की: ['पोखरा', 'अन्नपुर्ण', 'माछापुछ्रे', 'मादी', 'रूपा'],
    लमजुङ: [
      'बेसीशहर',
      'सुन्दरबजार',
      'मध्यनेपाल',
      'रार्इनास',
      'मर्स्याङदी',
      'दोर्दी',
      'दूधपोखरी',
      'क्व्होलासोथार',
    ],
    मनाङ: ['मनाङ डिस्याङ', 'नासोँ', 'चामे', 'नार्पा भूमी'],
    मुस्ताङ: [
      'घरपझोङ',
      'थासाङ',
      'बारागुङ मुक्तिक्षेत्र',
      'लोमन्थाङ',
      'लो-घेकर दामोदरकुण्ड',
    ],
    म्याग्दी: ['बेनी', 'मालिका', 'मंगला', 'रघुगंगा', 'धवलागिरी', 'अन्नपुर्ण'],
    नवलपुर: [
      'कावासोती',
      'गैडाकोट',
      'मध्यविन्दु',
      'देवचुली',
      'हुप्सेकोट',
      'विनयी त्रिवेणी',
      'बुलिङटार',
      'बौदीकाली',
    ],
    पर्वत: ['कुश्मा', 'फलेवास', 'जलजला', 'मोदी', 'पैयूं', 'विहादी', 'महाशिला'],
    स्याङग्जा: [
      'वालिङ',
      'पुतलीबजार',
      'गल्याङ',
      'चापाकोट',
      'भिरकोट',
      'कालीगण्डकी',
      'विरुवा',
      'हरीनास',
      'आँधीखोला',
      'अर्जुन चौपारी',
      'फेदीखोला',
    ],
    तनहुँ: [
      'व्यास',
      'शुक्लागण्डकी',
      'भानु',
      'भिमाद',
      'ऋषिङ्ग',
      'म्याग्दे',
      'आँबुखैरेनी',
      'बन्दिपुर',
      'घिरिङ',
      'देवघाट',
    ],
  },
};
