import { Grid, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { getUserInfo } from 'utils/decodeJWT';
import * as Yup from 'yup';
import RenderInput from '../RenderInput';
import YouthService from 'services/youthService';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const fields = [
  { id: 'first_party_name', label: 'पहिलो पक्षको नाम', type: 'text' },
  { id: 'first_party_position', label: 'पहिलो पक्षको पद', type: 'text' },
  { id: 'first_party_signature', label: 'पहिलो पक्षको दस्तखत', type: 'text' },
  { id: 'first_party_date', label: 'पहिलो पक्षको मिति', type: 'date' },
  { id: 'first_party_office_stamp', label: 'कार्यालयको छाप (पहिलो पक्ष)', type: 'text' },
  { id: 'first_party_contact_number', label: 'पहिलो पक्षको सम्पर्क फोन नं.', type: 'text' },
  { id: 'second_party_name', label: 'दोस्रो पक्षको नाम', type: 'text' },
  { id: 'second_party_position', label: 'दोस्रो पक्षको पद', type: 'text' },
  { id: 'second_party_signature', label: 'दोस्रो पक्षको दस्तखत', type: 'text' },
  { id: 'second_party_date', label: 'दोस्रो पक्षको मिति', type: 'date' },
  { id: 'second_party_organization_stamp', label: 'सस्थाको छाप (दोस्रो पक्ष)', type: 'text' },
  { id: 'second_party_contact_number', label: 'दोस्रो पक्षको सम्पर्क फोन नं.', type: 'text' },
  { id: 'second_party_right_thumb', label: 'दोस्रो पक्षको दायाँ औठा छाप', type: 'text' },
  { id: 'second_party_left_thumb', label: 'दोस्रो पक्षको बायाँ औठा छाप', type: 'text' },
  { id: 'office_stamp', label: 'कार्यालयको छाप', type: 'text' },
  { id: 'office_contact_number', label: 'कार्यालयको सम्पर्क फोन नं.', type: 'text' },
];

const YouthAgreement = ({ id, setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  const getValidationSchema = (fields) => {
    const shape = {};
    fields.forEach((field) => {
      shape[field.id] = Yup.string().required(`${field.label} is required`);
    });
    return Yup.object().shape(shape);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Grid item xs={12}>
        <h3>सम्झौता-पत्र</h3>
      </Grid>
      <Formik
        initialValues={{
          grant: 'Youth Grant',
          first_party_name: '',
          first_party_position: '',
          first_party_signature: '',
          first_party_date: '',
          first_party_office_stamp: '',
          first_party_contact_number: '',
          second_party_name: '',
          second_party_position: '',
          second_party_signature: '',
          second_party_date: '',
          second_party_organization_stamp: '',
          second_party_contact_number: '',
          second_party_right_thumb: '',
          second_party_left_thumb: '',
          office_stamp: '',
          office_contact_number: '',
          person: getUserInfo().person_id,
        }}
        validationSchema={getValidationSchema(fields)}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            const response = await YouthService.addYouthAgreement(values);
            if (response?.id) {
              setActiveStep(2);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
      >
        {(formik) => <RenderInput fields={fields} formik={formik} isDisabled={isDisabled} />}
      </Formik>
    </div>
  );
};

export default YouthAgreement;
