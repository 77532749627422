export default {
  'प्रदेश नं- २': {
    पर्सा: [
      'बीरगञ्ज',
      'बहुदरमाई',
      'पर्सागढी',
      'पोखरिया',
      'सखुवा प्रसौनी',
      'जगरनाथपुर',
      'छिपहरमाई',
      'बिन्दबासिनी',
      'पटेर्वा सुगौली',
      'जिरा भवानी',
      'कालिकामाई',
      'पकाहा मैनपुर',
      'ठोरी',
      'धोबीनी',
    ],
    बारा: [
      'कलैया',
      'जीतपुर-सिमरा',
      'महागढीमाई',
      'सिम्रोनगढ',
      'कोल्हवी',
      'निजगढ',
      'पचरौता',
      'सुवर्ण ',
      'आदर्श कोतवाल',
      'बारागढी',
      'फेटा',
      'करैयामाई',
      'प्रसौनी',
      'विश्रामपुर',
      'देवताल',
      'परवानीपुर',
    ],
    रौतहट: [
      'चन्द्रपुर',
      'गरुडा',
      'गुजरा',
      'बृन्दावन',
      'ईशनाथ',
      'राजपुर',
      'गढीमाई',
      'कटहरीया',
      'परोहा',
      'फतुवाबिजयपुर',
      'बौधीमाई',
      'माधव नारायण',
      'गौर',
      'देवाही गोनाही',
      'राजदेवी',
      'मौलापुर',
      'दुर्गा भगवती',
      'यमुनामाई',
    ],
    सर्लाही: [
      'बरहथवा',
      'ईश्वरपूर',
      'लालबन्दी',
      'गोडैता',
      'मलङ्गवा',
      'बलरा',
      'हरिवन',
      'कविलासी',
      'बागमती',
      'हरिपुर',
      'हरिपुर्वा',
      'चन्द्रनगर',
      'ब्रह्मपुरी',
      'रामनगर',
      'चक्रघट्टा',
      'कौडेना',
      'धनकौल',
      'विष्णु',
      'बसबरिया',
      'पर्सा',
    ],
    धनुषा: [
      'जनकपुर',
      'सवैला',
      'धनुषाधाम',
      'मिथिला',
      'शहिद नगर',
      'क्षिरेश्वरनाथ',
      'हंसपुर',
      'कमला',
      'गणेशमान चारनाथ',
      'नगराईन',
      'मिथिला बिहारी',
      'विदेह',
      'लक्ष्मीनिया',
      'मुखियापट्टी मुसहरमिया',
      'जनकनन्दिनी',
      'औरही',
      'बटेश्वर',
      'धनौजी',
    ],
    सिराहा: [
      'लहान',
      'सिरहा',
      'गोलबजार',
      'मिर्चैया',
      'कल्याणपुर',
      'धनगढीमाई',
      'सुखीपुर',
      'कर्जन्हा',
      'लक्ष्मीपुर पतारी',
      'बरियारपट्टी',
      'औरही',
      'अर्नमा',
      'भगवानपुर',
      'नरहा',
      'नवराजपुर',
      'सखुवानान्कारकट्टी',
      'विष्णुपुर',
    ],
    महोत्तरी: [
      'गौशाला',
      'बर्दिबास',
      'जलेश्वर',
      'मनरा शिसवा',
      'भँगाहा',
      'बलवा',
      'लोहरपट्टी',
      'औरही',
      'मटिहानी',
      'रामगोपालपुर',
      'सोनमा',
      'पिपरा',
      'साम्सी',
      'एकडारा',
      'महोत्तरी',
    ],
    सप्तरी: [
      'राजविराज',
      'हनुमाननगर कंकालिनी',
      'खडक',
      'दक्नेश्वारी',
      'सुरुङ्‍गा',
      'बोदेबरसाईन',
      'शम्भुनाथ',
      'कञ्चनरूप',
      'सप्तकोशी',
      'तिलाठी कोईलाडी',
      'राजगढ',
      'छिन्नमस्ता',
      'महादेवा',
      'अग्निसाइर कृष्णासवरन',
      'रुपनी',
      'बलान-बिहुल',
      'बिष्णुपुर',
      'तिरहुत',
    ],
  },
};
