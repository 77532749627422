import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Container, Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import Banner from 'components/Banner';
import SearchAppBar from 'components/Appbar';
import { getUserInfo } from 'utils/decodeJWT';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '20px',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  card: {
    cursor: 'pointer',
    backgroundColor: '#eee',

    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
}));

const DataEntryWrapper = () => {
  let cardContents = [
    {
      title: 'कृषक डाटा ईन्ट्री',
      imageUrl: 'https://images.unsplash.com/photo-1520052203542-d3095f1b6cf0?auto=format&fit=crop&w=400&q=80',
      link: '/personal-entry',
    },
    {
      title: 'संस्था डाटा ईन्ट्री',
      imageUrl: 'https://images.unsplash.com/photo-1589923188651-268a9765e432?auto=format&fit=crop&w=400&q=80',
      link: '/samuha-sahakari-farm-entry',
    },
    {
      title: 'प्रस्तावना डाटा ईन्ट्री',
      imageUrl:
        'https://images.unsplash.com/photo-1589876568181-a1508b8ef473?q=80&w=2073&auto=format&fit=crop&w=400&q=80',
      link: '/prastabana-entry',
    },
    {
      title: 'प्रस्तावना पेश',
      imageUrl:
        'https://images.unsplash.com/photo-1535379453347-1ffd615e2e08?q=80&w=1974&auto=format&fit=crop&w=400&q=80',
      link: '/prastabana-data-entry',
    },
    {
      title: 'युवा डाटा ईन्ट्री',
      imageUrl: 'https://images.unsplash.com/photo-1506869640319-fe1a24fd76dc?q=80&w=2070&fit=crop&w=400&q=80',
      link: '/youth-entry',
    },
    {
      title: 'कृषि डाटा ईन्ट्री',
      imageUrl:
        'https://images.unsplash.com/photo-1499529112087-3cb3b73cec95?q=80&w=1974&auto=format&fit=crop&w=400&q=80',
      link: '/krishi-entry',
    },
  ];
  const history = useHistory();
  const classes = useStyles();
  const userRole = getUserInfo().usertype;

  const handleCardClick = (link) => {
    history.push(link);
  };
  if (userRole === 'admin') {
    cardContents = cardContents.slice(0, 2);
  }
  if (userRole === 'customer') {
    cardContents = cardContents.slice(2, 6);
  }

  return (
    <>
      <Banner />
      <SearchAppBar title="दर्ता भएको डाटा" />
      <Container maxWidth="lg">
        <h2 style={{ margin: '15px 0' }}>कृषक तथा संस्था डाटा ईन्ट्री</h2>
        <div className={classes.root}>
          {cardContents.map((card, index) => (
            <Card key={index} color="primary" className={classes.card} onClick={() => handleCardClick(card.link)}>
              <CardHeader title={card.title} />
              <img className={classes.media} src={card.imageUrl} alt={card.title} />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {card.title}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </Container>
    </>
  );
};

export default DataEntryWrapper;
