import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import 'assets/css/table.css';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';

import LandService from 'services/land';
import LandExtra from './LandExtra';
import WithoutPerson from './WithoutPerson';
import AlertDialog from 'components/AlertDialog';

export const getInputField = (
  title,
  helperText,
  key,
  state,
  setState,
  isRequired = false,
  type = 'text',
  isDisabled=false
) => {
  return (
    <FormControl style={{ marginBottom: '5px', width: '80%' }}>
      <InputLabel htmlFor="my-input">{title}</InputLabel>
      <Input
        type={type}
        aria-describedby="my-helper-text"
        value={state}
        onChange={(e) => setState(key, e.target.value)}
        required={isRequired}
        disabled={isDisabled}
      />
      <FormHelperText id="my-helper-text">
        {helperText}
        {isRequired && <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>}
      </FormHelperText>
    </FormControl>
  );
};

export const getRadio = (title, options, key, state, setState, isRequired = false) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        {title}
        {isRequired && <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>}
      </FormLabel>
      <RadioGroup aria-label="radio" value={state} onChange={(e) => setState(key, e.target.value)}>
        {options.map((option, index) => {
          return <FormControlLabel value={option.value} control={<Radio />} label={option.label} key={index} />;
        })}
      </RadioGroup>
    </FormControl>
  );
};

export const getRadioBoolean = (title, options, key, state, setState, isRequired = false) => {
  const boolState = state === 'true' ? true : false;
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        {title}
        {isRequired && <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>}
      </FormLabel>
      <RadioGroup aria-label="radio" value={boolState} onChange={(e) => setState(key, e.target.value)}>
        {options.map((option, index) => {
          return <FormControlLabel value={option.value} control={<Radio />} label={option.label} key={index} />;
        })}
      </RadioGroup>
    </FormControl>
  );
};

export const getInputFieldWithIndex = (
  title,
  helperText,
  index,
  key,
  state,
  setState,
  isRequired = false,
  isDisabled = false
) => {
  return (
    <FormControl style={{ marginBottom: '5px' }}>
      <InputLabel htmlFor="my-input">{title}</InputLabel>
      <Input
        aria-describedby="my-helper-text"
        value={state}
        onChange={(e) => setState(index, key, e.target.value)}
        required={isRequired}
        disabled={isDisabled}
      />
      <FormHelperText id="my-helper-text">
        {helperText}
        {isRequired && <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>}
      </FormHelperText>
    </FormControl>
  );
};

export const getSelectField = (title, options, key, state, setState, isRequired = false) => {
  return (
    <FormControl style={{ width: '80%', margin: '5px 0' }}>
      <InputLabel>
        {title}
        {isRequired && <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>}
      </InputLabel>
      <Select value={state} onChange={(e) => setState(key, e.target.value)}>
        {options.map((option, index) => {
          return (
            <MenuItem value={option} key={index}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const handleReset = () => {
  Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
};

const Land = ({ nextPage, isInputDisabled }) => {
  const stateVariable = {
    landType: null,
    bigaha: null,
    kattha: null,
    dhur: null,
    ropani: null,
    aana: null,
    dam: null,
    paisa: null,
    sqMeter: null,
    barrenLand: null,
    pastureLand: null,
    remarks: null,
  };

  const [myState, setMyState] = useState([{ ...stateVariable }]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [savedStatus, setSavedStatus] = useState([{ status: 'none' }]);

  const changeSavedStatus = (index, status) => {
    savedStatus[index]['status'] = status;
    setSavedStatus([...savedStatus]);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };
  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('userId');

  const onChange = (index, key, value) => {
    let currentEntry = [...myState];
    currentEntry[index][key] = value;
    setMyState([...currentEntry]);
  };

  const removeStateObject = (index) => {
    let currentEntry = [...myState];
    currentEntry.splice(index, 1);
    setMyState([...currentEntry]);

    savedStatus.splice(index, 1);
    setSavedStatus([...savedStatus]);
  };
  const saveLandEntry = async (index) => {
    try {
      await LandService.createLandData(myState[index], userId);
      changeSavedStatus(index, 'saved');
      setIsSuccessDialog(true);
      setOpenDialog(true);
    } catch (e) {
      setIsSuccessDialog(false);
      setOpenDialog(true);
    }
  };
  const submit = () => {
    myState.forEach(async (state) => {
      await LandService.createLandData(state, userId);
    });

    handleReset();

    setMyState([{ ...stateVariable }]);
  };

  return (
    <div>
      {userId ? (
        <>
          <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
          <Grid item xs={12}>
            <h3 style={{ marginBottom: '10px' }}>जग्गाको विवरण</h3>

            <table className="table">
              {myState.map((state, index) => {
                return (
                  <>
                    <tr>
                      <td colSpan="5">
                        <FormControl style={{ width: 100 }}>
                          <InputLabel id="land-type">
                            जग्गा प्रकार
                            <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>
                          </InputLabel>
                          <Select
                            labelId="land-type"
                            value={state.landType}
                            onChange={(e) => onChange(index, 'landType', e.target.value)}
                            disabled={isInputDisabled || savedStatus[index].status === 'saved'}
                          >
                            {['नम्बरी', 'ऐलानी', 'सरकारी'].map((menu, index) => {
                              return (
                                <MenuItem value={menu} key={index}>
                                  {menu}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </td>
                    </tr>
                    <tr>
                      <th>तराईमा प्रचलित</th>
                      <td>
                        {getInputFieldWithIndex(
                          'विघाहा',
                          null,
                          index,
                          'bigaha',
                          state.bigaha,
                          onChange,
                          false,
                          isInputDisabled || savedStatus[index].status === 'saved'
                        )}
                      </td>

                      <td>
                        {getInputFieldWithIndex(
                          'कठ्ठा',
                          null,
                          index,
                          'kattha',
                          state.kattha,
                          onChange,
                          false,
                          isInputDisabled || savedStatus[index].status === 'saved'
                        )}
                      </td>
                      <td>
                        {getInputFieldWithIndex(
                          'धुर',
                          null,
                          index,
                          'dhur',
                          state.dhur,
                          onChange,
                          false,
                          isInputDisabled || savedStatus[index].status === 'saved'
                        )}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>पहाडमा प्रचलित</th>
                      <td>
                        {getInputFieldWithIndex(
                          'रोपनी',
                          null,
                          index,
                          'ropani',
                          state.ropani,
                          onChange,
                          false,
                          isInputDisabled || savedStatus[index].status === 'saved'
                        )}
                      </td>
                      <td>
                        {getInputFieldWithIndex(
                          'आना',
                          null,
                          index,
                          'aana',
                          state.aana,
                          onChange,
                          false,
                          isInputDisabled || savedStatus[index].status === 'saved'
                        )}
                      </td>
                      <td>
                        {getInputFieldWithIndex(
                          'दाम',
                          null,
                          index,
                          'dam',
                          state.dam,
                          onChange,
                          false,
                          isInputDisabled || savedStatus[index].status === 'saved'
                        )}
                      </td>
                      <td>
                        {getInputFieldWithIndex(
                          'पैसा',
                          null,
                          index,
                          'paisa',
                          state.paisa,
                          onChange,
                          false,
                          isInputDisabled || savedStatus[index].status === 'saved'
                        )}
                      </td>
                    </tr>
                    <tr>
                      {/* <td>{getInputFieldWithIndex('वर्ग मीटर', null, index, 'sqMeter', state.sqMeter, onChange)}</td> */}
                      <td>
                        {getInputFieldWithIndex(
                          'बाँझो',
                          null,
                          index,
                          'barrenLand',
                          state.barrenLand,
                          onChange,
                          false,
                          isInputDisabled || savedStatus[index].status === 'saved'
                        )}
                      </td>
                      <td>
                        {getInputFieldWithIndex(
                          'चरान खर्क ',
                          null,
                          index,
                          'pastureLand',
                          state.pastureLand,
                          onChange,
                          false,
                          isInputDisabled || savedStatus[index].status === 'saved'
                        )}
                      </td>
                      <td>
                        {getInputFieldWithIndex(
                          'कैफियत ',
                          null,
                          index,
                          'remarks',
                          state.remarks,
                          onChange,
                          false,
                          isInputDisabled || savedStatus[index].status === 'saved'
                        )}
                      </td>
                      <td colSpan="2" style={{ textAlign: 'center' }}>
                        {savedStatus[index].status === 'saved' ? (
                          <span
                            style={{ color: 'green', margin: '0 5px' }}
                            title="एन्ट्री डाटा स्टोर भयो"
                            className="fa fa-check"
                            onClick={() => saveLandEntry(index)}
                          />
                        ) : (
                          <>
                            <span
                              style={{ color: 'green', margin: '0 5px' }}
                              title="एन्ट्री स्टोर डाटा"
                              className="fa fa-save"
                              onClick={() => saveLandEntry(index)}
                            />
                            <span
                              style={{ color: 'red', margin: '0 5px' }}
                              title="एन्ट्री हटाउनुहोस्"
                              className="fa fa-trash"
                              onClick={() => removeStateObject(index)}
                            />
                          </>
                        )}
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
            <ButtonGroup color="primary" aria-label="primary button group" style={{ margin: '10px 0' }}>
              <Button
                onClick={() => {
                  setMyState([...myState, { ...stateVariable }]);
                  setSavedStatus([...savedStatus, { status: 'none' }]);
                }}
              >
                थप जग्गाको विवरण
              </Button>
              {/* <Button onClick={() => submit()}>बुझाउनुहोस्</Button> */}
            </ButtonGroup>
          </Grid>
          <br />
          <br />
          <hr />
          <LandExtra nextPage={nextPage} />
        </>
      ) : (
        <WithoutPerson nextPage={nextPage} />
      )}
    </div>
  );
};

export default Land;
