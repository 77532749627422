import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import colorPallette from './colorPallette';
import BarChart from './BarChart';
import HorizontalBarChart from './HorizontalBarChart';
import PieChart from './PieChart';

const Chart = ({ statistics }) => {
  if (!statistics) {
    return <div style={{height: 40}}>
      <LinearProgress color="secondary" />
    </div>;
  }

  const pieChartData = [
    statistics.total_male_count,
    statistics.total_female_count,
    statistics.total_other_gender_count,
  ];

  const { wards_stats, farmer_categories } = statistics;
  const wardLabel = Object.keys(wards_stats);

  const farmer_categories_label = Object.keys(farmer_categories)
  const farmer_categories_data = []

  farmer_categories_label.forEach((label, index)=>{
    const data = {
      label: label,
      data: [farmer_categories[label]],
      backgroundColor: colorPallette['backgroundColor'][index],
      borderColor: colorPallette['borderColor'][index],
      borderWidth: 1,
    }
    farmer_categories_data[index] = {...data}
  })

  // Gender
  let male = [];
  let female = [];
  let other = [];

  // Age
  let lessThan18 = [];
  let between18to60 = [];
  let above60 = [];

  wardLabel.forEach((ward) => {
    male.push(statistics.wards_stats[ward].male_count);
    female.push(statistics.wards_stats[ward].female_count);
    other.push(statistics.wards_stats[ward].other_gender_count);

    lessThan18.push(statistics.wards_stats[ward].less_than_eighteen);
    between18to60.push(statistics.wards_stats[ward].eighteen_to_sixty);
    above60.push(statistics.wards_stats[ward].sixty_and_above);
  });

  const wardGenderData = [
    {
      label: 'पुरुष',
      data: male,
      backgroundColor: '#12B0E8',
    },
    {
      label: 'महिला',
      data: female,
      backgroundColor: '#FF6263',
    },
    {
      label: 'अन्य',
      data: other,
      backgroundColor: '#EDBF69',
    },
  ];

  const wardAgeData = [
    {
      label: '१८ भन्दा कम',
      data: lessThan18,
      backgroundColor: '#12B0E8',
    },
    {
      label: '१८ देखि ६० सम्म',
      data: between18to60,
      backgroundColor: '#FF6263',
    },
    {
      label: '६० भन्दा माथी',
      data: above60,
      backgroundColor: '#EDBF69',
    },
  ];
  const genderLabel = ['पुरुष', 'महिला', 'अन्य'];

  return (
    statistics && (
      <div>
        <h2>उदयपुरगढीका किसानहरुको वर्गिकरण</h2>
        <div style={{ margin: '25px 0' }}>
          <BarChart title="वडा अनुसार लैङ्गिक  वर्गिकरण" data={[...wardGenderData]} labels={wardLabel} />
          <BarChart title="वडा अनुसार उमेर वर्गिकरण" data={[...wardAgeData]} labels={wardLabel} />

          <PieChart title="उदयपुरगढीको लैङ्गिक वर्गिकरण" data={pieChartData} labels={genderLabel} />
          <HorizontalBarChart title="उदयपुरगढीका कृषकहरुको वर्गिकरण" data={farmer_categories_data} labels={['कृषक वर्गिकरण']} />
        </div>
      </div>
    )
  );
};

export default Chart;
