import React, { useState, useEffect } from 'react';

import { Container, Grid, IconButton } from '@material-ui/core';
import SearchAppBar from 'components/Appbar';
import Banner from 'components/Banner';
import { makeStyles } from '@material-ui/core/styles';
// import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from 'react-router-dom';
import Copyright from 'components/Copyright';
import PrastabanaService from 'services/prastabana';
import { DataGrid } from '@material-ui/data-grid';
import { getUserInfo } from 'utils/decodeJWT';
import { CheckCircleOutline } from '@material-ui/icons';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  titleContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const ApplicationDetailTable = () => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);
  const userRole = getUserInfo().usertype;

  useEffect(() => {
    const fetchData = async () => {
      const res = await PrastabanaService.getApplicationData();
      if (res?.count > 0) {
        setData(res?.results);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      field: 'application_name',
      width: 250,
      headerName: 'Application Name',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <span style={{ fontWeight: 'bold' }}>{params.row.application_name}</span>;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'name_of_program_offered',
      width: 250,
      headerName: 'Grant',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <span style={{ fontWeight: 'bold' }}>{params.row.name_of_program_offered}</span>;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'village',
      width: 250,
      headerName: 'Village',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <span style={{ fontWeight: 'bold' }}>{params.row.village}</span>;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'full_address_of_venue',
      width: 250,
      headerName: 'Village',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <span style={{ fontWeight: 'bold' }}>{params.row.full_address_of_venue}</span>;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'status',
      width: 250,
      headerName: 'Status',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <span style={{ fontWeight: 'bold' }}>
            {params.row.status === 'In Progess' ? (
              <>⚠️ In Progress </>
            ) : params.row.status === 'Rejected' ? (
              <>❌ Rejected</>
            ) : (
              <> ✅ Approved</>
            )}
          </span>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'id',
      width: 150,
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <IconButton onClick={() => handleRowClick}>
          <CheckCircleOutline style={{ color: 'green' }} />
        </IconButton>
      ),
      headerClassName: 'super-app-theme--header',
    },
  ];

  const handleRowClick = (param) => {
    if (userRole === 'admin') {
      history.push(`/application-entry/${param.row.id}`);
    }
  };

  // const handleApprove = async (id) => {
  //   try {
  //     await PrastabanaService.approveData(id);
  //     // Optionally refresh the data after approval
  //     const res = await PrastabanaService.getData();
  //     if (res?.count > 0) {
  //       setData(res?.results);
  //     }
  //   } catch (error) {
  //     console.error('Error approving the data', error);
  //   }
  // };

  return (
    <>
      <Banner />
      <SearchAppBar title="दर्ता भएको डाटा" />
      <Container maxWidth="lg">
        <Grid item xs={12} className={classes.titleContainer}></Grid>
        <Grid item lg={12} md={9}>
          <div style={{ height: 425, width: '100%' }}>
            {data ? (
              <DataGrid rows={data} columns={columns} pageSize={5} onRowClick={handleRowClick} />
            ) : (
              <div style={{ fontWeight: 'bold', fontSize: '18px', color: '#888888' }}>लोड हुँदैछ...</div>
            )}
          </div>
        </Grid>
        <br />
      </Container>
      <Copyright website="उदयपुरगढी गाउँपालिका" link="#" />
    </>
  );
};

export default ApplicationDetailTable;
