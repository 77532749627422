import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import KrishiService from 'services/krishiService';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const ParticipationForm = ({ setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [projectList, setProjectList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await KrishiService.getIrri();
      setProjectList(res?.results);
    };
    fetchData();
  }, []);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          irrigation_application: projectList && projectList[0]?.id,

          percentage_in_labor: '',
          percentage_in_kind: '',
          percentage_in_funding: '',
          person: getUserInfo().person_id,
        }}
        onSubmit={async (values) => {
          try {
            const response = await KrishiService.addContribution(values);
            if (response?.id) {
              setActiveStep(6);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="percentage_in_funding"
                name="percentage_in_funding"
                label="वडा नम्बर"
                fullWidth
                required
                type="number"
                value={formik.values.percentage_in_funding}
                onChange={formik.handleChange}
                error={formik.touched.percentage_in_funding && Boolean(formik.errors.percentage_in_funding)}
                helperText={formik.touched.percentage_in_funding && formik.errors.percentage_in_funding}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="percentage_in_kind"
                name="percentage_in_kind"
                label="वडा नम्बर"
                fullWidth
                required
                type="number"
                value={formik.values.percentage_in_kind}
                onChange={formik.handleChange}
                error={formik.touched.percentage_in_kind && Boolean(formik.errors.percentage_in_kind)}
                helperText={formik.touched.percentage_in_kind && formik.errors.percentage_in_kind}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="percentage_in_labor"
                name="percentage_in_labor"
                label="वडा नम्बर"
                fullWidth
                required
                type="number"
                value={formik.values.percentage_in_labor}
                onChange={formik.handleChange}
                error={formik.touched.percentage_in_labor && Boolean(formik.errors.percentage_in_labor)}
                helperText={formik.touched.percentage_in_labor && formik.errors.percentage_in_labor}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                  बुझाउनुहोस् &nbsp;
                  {isDisabled ? <CircularProgress size={30} /> : null}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default ParticipationForm;
