import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#879565',
      color: '#ffffff',
      fontWeight: 'bold',
      textShadow: '2px 2px 5px #000',
    },
  },
});

const getColumns = () => {
  return [
    {
      field: 'नाम',
      width: 250,
      headerName: 'संगठनको नाम',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <span style={{ fontWeight: 'bold' }}>{params.row.name}</span>;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'सम्पर्क व्यक्ति',
      width: 150,
      headerName: 'सम्पर्क व्यक्ति',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px' }}>
            <span>{params.row.contact_person_name}</span>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'सम्पर्क नं',
      width: 125,
      headerName: 'सम्पर्क नं',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px' }}>
            <span style={{ display: 'block', fontSize: '14px', color: '#888' }}>
              {params.row.contact_person_contact_no}
            </span>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'ठेगाना',
      headerName: 'ठेगाना',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px' }}>
            <span style={{ display: 'block' }}>{params.row.state}</span>
            <span>
              {params.row.palika}-{params.row.ward_no}, {params.row.district}
            </span>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'दर्ता नं',
      headerName: 'दर्ता नं',
      width: 125,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px', fontSize: '13px' }}>
            <span>{params.row.registration_no}</span>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'दर्ता मिती',
      headerName: 'दर्ता मिती',
      width: 125,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px' }}>
            <span style={{ display: 'block', fontSize: '13px', color: '#888' }}>
              {params.row.registration_date}
            </span>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'पान नं',
      headerName: 'पान नं',
      width: 125,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px', fontSize: '13px' }}>
            <span>{params.row.pan_no}</span>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'पान दर्ता मिती',
      headerName: 'पान दर्ता मिती',
      width: 125,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: '15px' }}>
            <span style={{ display: 'block', fontSize: '13px', color: '#888' }}>
              {params.row.pan_registration_date}
            </span>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];
};

const DataTable = ({ rows }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      style={{ height: 400, width: '100%', margin: '20px 0', paddingBottom: '25px', borderBottom: '1px solid #ccc' }}
    >
      <DataGrid rows={rows} columns={getColumns()} pageSize={5} />
    </div>
  );
};

export default DataTable;
