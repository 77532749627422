const snakeToCamel = (str) =>
  str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());

const camelToSnake = (str) =>
  str.replace(/([A-Z]\w)/g, (g) => '_' + g.toLowerCase());

const makeMappings = (data) => {
  const result = {}
  for (const [key, value] of Object.entries(data)) {
    result[camelToSnake(key)] = value;
  }

  return result;
}   

export { snakeToCamel, camelToSnake, makeMappings };