import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Banner from 'components/Banner';
import Appbar from 'components/Appbar';
import Copyright from 'components/Copyright';
import AuthService from 'services/auth';
import jwtDecode from 'jwt-decode';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/400x400/?nepal-farming)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  let history = useHistory();
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const login = async () => {
    const data = new FormData();
    data.append('username', email);
    data.append('password', password);

    try {
      const tokens = await AuthService.login(data);
      localStorage.setItem('access_token', tokens.access);
      localStorage.setItem('refresh_token', tokens.refresh);
      if (tokens) {
        const decoded = jwtDecode(tokens?.access);
        console.log('🚀 ~ login ~ decoded:', decoded);
        if (decoded) {
          localStorage.setItem('user_id', decoded?.user_id);
          localStorage.setItem('user_type', decoded?.usertype);
        }
        history.push('/');
      }
    } catch (e) {
      setError(true);
    }
  };

  return (
    <>
      <Banner />
      <Appbar />
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in ( पुराना प्रयोगकर्ताहरुका लागि )
            </Typography>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="प्रयोगकर्ता नाम"
              autoFocus
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="पासवर्ड"
              type="password"
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={login}
            >
              साईन ईन गर्नुहोस्
            </Button>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)} style={{ right: 0 }}>
              <Alert severity="error" onClose={() => setError(false)}>
                username र password मेल खाएन।
              </Alert>
            </Snackbar>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  पासवर्ड भुल्नु भयो?
                </Link>
              </Grid>
              <Grid item>
                {/* <Link href="/register" variant="body2">
                  प्रयोगकर्ता खाता छैन
                </Link>i */}
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright website="Agro Soft" link="#" />
            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
