import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { Carousel } from 'react-responsive-carousel';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import 'assets/css/home.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { carouselImagesFromLocal } from 'constants/carouselImage';
import MemberPic from 'components/MemberPic';
import Banner from 'components/Banner';
import Copyright from 'components/Copyright';
import SearchAppBar from 'components/Appbar';
import GoogleMaps from 'components/Maps';
import LeftStatistics from 'components/LeftStatistics';
import Chart from 'components/Charts/Chart';

import StatisticService from 'services/statistics';
import SuchanaPrakashanService from 'services/suchanaPrakashan';

const useStyles = makeStyles((theme) => ({
  '@keyframes pulse': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '-6000% -6000%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  media: {
    height: 150,
    width: '100%',
    objectFit: 'cover',
  },
  card: {
    maxWidth: '100%',
    marginBottom: 30,
    cursor: 'pointer',
    // backgroundColor: '#fff',
    backgroundColor: '#e8f8e8',
    borderRadius: '10px',
    borderStyle: 'outset',
    borderColor: '#e8f8e8',
    textShadow: '0 0 10px #ddf',

    backgroundImage: "url('https://i.pinimg.com/originals/c5/3e/f7/c53ef748b9c2911acf295defbba5a240.jpg')",
    animation: '$pulse 3600s infinite',

    '&:hover': {
      color: '#373',
      backgroundColor: '#d8e8d8',
      borderStyle: 'solid',
      borderColor: '#141',
      transition: 'all .6s',
    },
  },
}));

const Home = () => {
  const history = useHistory();
  const classes = useStyles();

  const [statistics, setStatistics] = useState(null);
  const [carouselImages, setCarouselImages] = useState(null);

  useEffect(() => {
    (async () => {
      const statistics = await StatisticService.getAllStatistics();

      setStatistics(statistics);
    })();
  }, []);
  useEffect(() => {
    setCarouselImages(carouselImagesFromLocal);
    // (async () => {
    //   let carouselImagesFromAPI = await SuchanaPrakashanService.getPhotos();
    //   carouselImagesFromAPI = carouselImagesFromAPI.results || [];
    //   if (carouselImagesFromAPI.length !== 0) {
    //     setCarouselImages(carouselImagesFromAPI);
    //   } else {
    //     setCarouselImages(carouselImagesFromLocal);
    //   }
    // })();
  }, []);

  return (
    <div>
      <Banner />
      <SearchAppBar />
      <div style={{ marginTop: '50px' }}>
        <Grid container spacing={0} className="paper">
          <Grid item md={4} className="paper">
            <LeftStatistics statistics={statistics} />
            <a
              class="weatherwidget-io"
              href="https://forecast7.com/en/26d7886d64/gaighat/"
              data-label_1="उदयपुरगढी मौसम"
              data-label_2="उदयपुर, नेपाल"
              data-theme="original"
              style={{ pointerEvents: 'none' }}
            >
              उदयपुरगढी उदयपुर, नेपाल
            </a>
            <div style={{ textAlign: 'center', padding: '1em 0' }}>
              <h4>
                <a
                  style={{ textDecoration: 'none', pointerEvents: 'none' }}
                  href="https://www.zeitverschiebung.net/en/timezone/asia--kathmandu"
                >
                  <span style={{ color: 'gray' }}>उदयपुरगढी, नेपालको हालको समय</span>
                  <br />
                </a>
              </h4>
              <iframe
                title="Image Map"
                src="https://www.zeitverschiebung.net/clock-widget-iframe-v2?language=np&size=small&timezone=Asia%2FKathmandu"
                width="100%"
                height="90"
                frameborder="0"
                seamless
                style={{ pointerEvents: 'none' }}
              ></iframe>
            </div>
          </Grid>
          <Grid item md={6} className="paper">
            <Chart statistics={statistics} />
            <hr />
            {carouselImages && (
              <Carousel autoPlay showThumbs={false}>
                {carouselImages.map((img) => {
                  return (
                    <div>
                      <img alt={img.caption} className="carousel-img" src={img.photo} />
                    </div>
                  );
                })}
              </Carousel>
            )}
            <GoogleMaps />
          </Grid>
          <Grid item md={2}>
            <Card color="primary" className={classes.card} onClick={() => history.push('/sewa-pradayak-menu')}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  सेवा प्रदायक
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  सेवा प्रदायक
                </Typography>
              </CardContent>
            </Card>
            <Card color="primary" className={classes.card} onClick={() => history.push('/bali-patro-list')}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  बाली पात्रो
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  बाली पात्रो
                </Typography>
              </CardContent>
            </Card>
            <Card color="primary" className={classes.card} onClick={() => history.push('/fertilizer')}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  मल क्यालकुलेटर
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  मलको मात्रा विवरण क्यालकुलेटर
                </Typography>
              </CardContent>
            </Card>
            <hr />
            <MemberPic />
          </Grid>
        </Grid>
      </div>

      <Copyright website="उदयपुरगढी गाउँपालिका" link="#" />
    </div>
  );
};

export default Home;
