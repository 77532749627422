import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import KrishiService from 'services/krishiService';
import { getUserInfo } from 'utils/decodeJWT';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const fields = [
  { id: 'after_construction_responsibility', label: 'average_flow_rate_monsoon', type: 'boolean' },
  { id: 'current_status_of_renovation', label: 'average_flow_rate_spring', type: 'boolean' },
  { id: 'organization_exists', label: 'organization_exists', type: 'boolean' },
  { id: 'willing_to_participate_in_construction', label: 'willing_to_participate_in_construction', type: 'boolean' },
  { id: 'organized_consumers_ready', label: 'organized_consumers_ready', type: 'boolean' },
  { id: 'renovation_project', label: 'renovation_project', type: 'boolean' },
  {
    id: 'responsible_for_operation_maintenance',
    label: 'responsible_for_operation_maintenance',
    type: 'select',
    options: [
      {
        label: 'स्वयंद्वारा',
        id: 'स्वयंद्वारा',
      },
      {
        label: 'अन्य कुनै संस्थाद्वारा',
        id: 'अन्य कुनै संस्थाद्वारा',
      },
      {
        label: 'कुनै खास व्यवस्था वा प्रथा नभएको',
        id: 'कुनै खास व्यवस्था वा प्रथा नभएको',
      },
    ],
  },
];
const WaterConsumer = ({ setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [projectList, setProjectList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await KrishiService.getIrri();
      setProjectList(res?.results);
    };
    fetchData();
  }, []);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          irrigation_application: projectList && projectList[0]?.id,
          after_construction_responsibility: false,
          current_status_of_renovation: false,
          organization_exists: false,
          organized_consumers_ready: false,
          registered_with_district_authority: false,
          renovation_project: false,
          responsible_for_operation_maintenance: '',
          willing_to_participate_in_construction: false,
          person: getUserInfo().person_id,
        }}
        onSubmit={async (values) => {
          try {
            const response = await KrishiService.addWater(values);
            if (response?.id) {
              setActiveStep(8);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            {fields.map((field) => {
              if (field?.type === 'boolean') {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.maintenance_fund_available}
                          onChange={formik.handleChange}
                          name={field.id}
                          color="primary"
                        />
                      }
                      label={field.label}
                    />
                    {formik.touched.maintenance_fund_available && formik.errors.maintenance_fund_available && (
                      <div style={{ color: 'red', marginTop: '8px' }}>{formik.errors.maintenance_fund_available}</div>
                    )}
                  </Grid>
                );
              } else if (field.type === 'select') {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <TextField
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      fullWidth
                      value={formik.values[field.id]}
                      onChange={formik.handleChange}
                      select
                      error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                      helperText={formik.touched[field.id] && formik.errors[field.id]}
                    >
                      {field.options?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <TextField
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      fullWidth
                      type={field.type === 'number' ? 'number' : 'text'}
                      value={formik.values[field.id]}
                      onChange={formik.handleChange}
                      error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                      helperText={formik.touched[field.id] && formik.errors[field.id]}
                    />
                  </Grid>
                );
              }
            })}
            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                  बुझाउनुहोस् &nbsp;
                  {isDisabled ? <CircularProgress size={30} /> : null}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default WaterConsumer;
