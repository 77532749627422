import axios from "axios";

const url = process.env.REACT_APP_API_BASE_URI + 'auth/login/';

class AuthService {
  static async login(data) {
    const response = await axios.post(url, data);

    return response.data;
  }
}

export default AuthService;