import React from 'react';

const Notes = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', color: 'red', marginBottom: 24, }}>
      <b>नोट:</b>
      <ul style={{ listStyle: 'none',  }}>
        {/* <li>१. कृपया अङ्क तथा अक्षर समेतको प्रयोग गरि सुरक्षित पासवर्ड श्रृजना गर्नुहोस् र समय-समयमा परिवर्तन गर्नुहोस्। </li> */}
        {/* <li>२. लग-इनका लागि तपाईंको मोबाईल नम्बर र पासवर्ड अनिवार्य हुनाले सुरक्षित साथ राख्‍नुहोस्।  </li> */}
        {/* <li>३. यस प्रणालीमा नेपाली भाषा प्रविष्ट गर्नुपर्ने स्थानमा स्वतः नेपाली टाईप हुनाले भाषा परिवर्तन गरिरहनु पर्दैन। यदि भाषा परिवर्तन गरेमा */}
        {/* टाईप हुँदैन। फारम भर्दा विचमा भाषा परिवर्तन गरेमा भरिएका विवरणहरु हराउन सक्दछन्। नेपाली भाषा traditional layout */}
        {/* अनुसार रहेको छ। </li> */}
        {/* <li>४. नेपाली भाषा आवश्‍यक हुने स्थान बाहेक अन्य स्थानमा अङ्ग्रेजी टाईप हुनेछ। </li> */}
        <li>१. गणितीय संख्या प्रविष्ट गर्दा अङ्ग्रेजी माध्यम प्रयोग गर्नुपर्दछ </li>
        <li>२. मितीको ढाँचा: 2077-12-24 (वर्ष-महिना-गते) (अङ्ग्रेजी अङ्कमा)</li>
        <li>३. जग्गाको क्षेत्रफलको ढाँचा: पहाडमा प्रचलित (1-2-3-4) अथवा तराईमा प्रचलित (1-2-3)</li>
      </ul>
    </div>
  );
};

export default Notes;
