// list of items

const baliItems = {
  काउली_अगौटे: {
    title: 'काउली (अगौटे)',
    icon:
      'https://images.unsplash.com/photo-1568584952634-e9bb8a163e28?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  काउली_मध्यम: {
    title: 'काउली (मध्यम)',
    icon:
      'https://images.unsplash.com/photo-1601171908684-75604cf5da5f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  काउली_पछौटे: {
    title: 'काउली (पछौटे)',
    icon:
      'https://images.unsplash.com/photo-1613549812169-1749ed3566c7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  बन्दा: {
    title: 'बन्दा',
    icon:
      'https://images.unsplash.com/photo-1614690738055-33507a488f91?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  व्रोकाउली: {
    title: 'व्रोकाउली',
    icon:
      'https://images.unsplash.com/photo-1617122823297-5d390e6074b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  ग्याँठगोपी: {
    title: 'ग्याँठगोपी',
    icon: 'https://zaykarecipes.com/wp-content/uploads/2017/11/Ganth-Gobhi.jpg',
  },
  काँक्रो: {
    title: 'काँक्रो',
    icon:
      'https://images.unsplash.com/photo-1568584711271-6c929fb49b60?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  घिरौला: {
    title: 'घिरौला',
    icon: 'https://5.imimg.com/data5/VO/VA/HQ/SELLER-81389294/sponge-gourd-500x500.jpg',
  },
  तितेकरेला: {
    title: 'तितेकरेला',
    icon:
      'https://images.unsplash.com/photo-1563280554-39684b777a3c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  फर्सी: {
    title: 'फर्सी',
    icon:
      'https://images.unsplash.com/photo-1570586437263-ab629fccc818?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  स्क्वास_फर्सी: {
    title: 'स्क्वास (फर्सी)',
    icon: 'https://static.seattletimes.com/wp-content/uploads/2018/05/89c6ac96-549c-11e8-9e73-bbeddc6a4482-780x948.jpg',
  },
  लौका: {
    title: 'लौका',
    icon:
      'https://images.hindustantimes.com/rf/image_size_630x354/HT/p2/2018/06/23/Pictures/vegetable-bottle-gourd_bf2eaf10-76c9-11e8-ada9-0239ece95d2f.jpg',
  },
  केराउ: {
    title: 'केराउ',
    icon:
      'https://images.unsplash.com/photo-1503444786070-ab39340977f2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  बकुल्ला: {
    title: 'बकुल्ला',
    icon: 'https://www.askmepasal.com/wp-content/uploads/2021/02/unnamed-1-1-300x300.jpg',
  },
  सिमी: {
    title: 'सिमी',
    icon: 'https://www.greenmylife.in/wp-content/uploads/2016/01/Improved-Dolichos-Guru-Seeds.jpg',
  },
  बोडी: {
    title: 'बोडी',
    icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThu25efa4651kFbkSLZVyq9huMDGl4TTw38FzpvF09ZdCWXFiyn86UetDLS-AXoLPAFz0&usqp=CAU',
  },
  खुर्सानी_पिरो: {
    title: 'खुर्सानी (पिरो)',
    icon:
      'https://images.unsplash.com/photo-1518006959466-0db0b6b4c1d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  गोलभेंडा_अग्लो: {
    title: 'गोलभेंडा (अग्लो)',
    icon:
      'https://images.unsplash.com/photo-1534940519139-f860fb3c6e38?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  भण्टा: {
    title: 'भण्टा',
    icon:
      'http://cdn.shopify.com/s/files/1/0047/9730/0847/products/nurserylive-seeds-brinjal-f1-purple-long-vegetable-seeds-16969058812044.jpg?v=1601349348',
  },
  भेडे_खुर्सानी: {
    title: 'भेडे खुर्सानी',
    icon:
      'https://images.unsplash.com/photo-1541788707294-a1cb80d42dce?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  सलगम: {
    title: 'सलगम',
    // icon:
    //   'https://images.unsplash.com/photo-1568202785425-f785c43d523e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
    icon:
      'https://kj1bcdn.b-cdn.net/media/25123/turnip.png',
  },
  गाजर: {
    title: 'गाजर',
    icon:
      'https://images.unsplash.com/photo-1598170845058-32b9d6a5da37?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  चम्सुर: {
    title: 'चम्सुर',
    icon: 'https://onlinetarkari.com/wp-content/uploads/2020/06/chamsur-saag.jpg',
  },
  पालुंगो: {
    title: 'पालुंगो',
    icon:
      'https://images.unsplash.com/photo-1574316071802-0d684efa7bf5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  प्याज: {
    title: 'प्याज',
    icon:
      'https://images.unsplash.com/photo-1508747703725-719777637510?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  लसुन: {
    title: 'लसुन',
    icon:
      'https://images.unsplash.com/photo-1540148426945-6cf22a6b2383?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  मूला: {
    title: 'मूला',
    icon:
      'https://assets.rebelmouse.io/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbWFnZSI6Imh0dHBzOi8vYXNzZXRzLnJibC5tcy8yMjAxNTQxOC9vcmlnaW4uanBnIiwiZXhwaXJlc19hdCI6MTYzMDAxOTQyMn0.koBakbEqCJpfdKJ5YOqtOvjbdqoIxalWqTnhbgg_Svk/img.jpg?width=1245&quality=85&coordinates=0%2C0%2C0%2C0&height=700',
  },
  मेथी: {
    title: 'मेथी',
    icon:
      'https://morningchores.com/wp-content/uploads/2019/08/Growing-Fenugreek-Plant-Varieties-How-to-Guide-Problems-and-Harvesting-FI.jpg',
  },
  रायो: {
    title: 'रायो',
    icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaYOkgyckE91jkCBWtSl6R64u4gbCa4mhK9Q&usqp=CAU',
  },
  स्वीस_चार्ड: {
    title: 'स्वीस चार्ड',
    icon:
      'https://www.johnnyseeds.com/on/demandware.static/-/Library-Sites-JSSSharedLibrary/default/dwb2abb6ba/images/culture/141-swiss-chard.jpg',
  },
  भिण्डी: {
    title: 'भिण्डी',
    icon:
      'https://gardenseason.com/wp-content/uploads/2020/06/Lady-Fingers-or-Okra-vegetable-on-plant-in-farm-_-growing-okra-_-ss-_-featured.jpg',
  },
};

export default baliItems;
