import { Button, ButtonGroup, CircularProgress, Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { FieldArray, Formik } from 'formik';
import React, { useState } from 'react';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';
import * as Yup from 'yup';
import RenderInput from './RenderInput';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const validationSchema = Yup.object().shape({
  details: Yup.array()
    .of(
      Yup.object().shape({
        app: Yup.string().required('Application ID is required'),
        detail_of_attached_documents: Yup.string().required('Detail of attached documents is required'),
        number: Yup.string().required('Number is required'),
      })
    )
    .required('Details are required')
    .min(1, 'At least one detail is required'),
});

const ApplicationAttachedDocumentsForm = ({ setActiveStep, applicationDetails }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          details: [
            {
              app: applicationDetails && applicationDetails?.id,
              detail_of_attached_documents: '',
              number: '',
              person: getUserInfo().person_id,
            },
          ],
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            const response = await PrastabanaService.addApplicationAttachedDocuments(values);
            if (response == 'True') {
              setActiveStep(3);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => {
          return (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h3>परियोजनाका लागि आवश्यक वजेट</h3>
              </Grid>
              <Grid item xs={12}>
                <table className="table">
                  <tbody>
                    <tr>
                      <th>क्र स</th>
                      <th>संलग्न कागजातहरुको विवरण</th>
                      <th>संख्या</th>
                      <th>अक्सन</th>
                    </tr>
                  </tbody>
                  <FieldArray name="details">
                    {({ push, remove }) => {
                      return (
                        <>
                          {formik.values.details.map((detailsItem, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <TextField
                                  name={`details[${index}].detail_of_attached_documents`}
                                  label="संलग्न कागजातहरुको विवरण"
                                  value={detailsItem.detail_of_attached_documents}
                                  onChange={formik.handleChange}
                                  fullWidth
                                  helperText={
                                    formik.touched.details?.[index]?.detail_of_attached_documents &&
                                    formik.errors.details?.[index]?.detail_of_attached_documents
                                  }
                                  error={
                                    formik.touched.details?.[index]?.detail_of_attached_documents &&
                                    Boolean(formik.errors.details?.[index]?.detail_of_attached_documents)
                                  }
                                />
                              </td>
                              <td>
                                <TextField
                                  name={`details[${index}].number`}
                                  label="संख्या"
                                  type="number"
                                  value={detailsItem.number}
                                  onChange={formik.handleChange}
                                  fullWidth
                                  helperText={
                                    formik.touched.details?.[index]?.number && formik.errors.details?.[index]?.number
                                  }
                                  error={
                                    formik.touched.details?.[index]?.number &&
                                    Boolean(formik.errors.details?.[index]?.number)
                                  }
                                />
                              </td>

                              <td>
                                <div style={{ display: 'flex', gap: '8px' }}>
                                  {index >= 0 && index === formik.values?.details.length - 1 && index < 9 && (
                                    <IconButton
                                      onClick={() =>
                                        push({
                                          app: applicationDetails && applicationDetails?.id,
                                          detail_of_attached_documents: '',
                                          number: '',
                                          person: getUserInfo().person_id,
                                        })
                                      }
                                    >
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                                          fill="#088720"
                                        />
                                      </svg>
                                    </IconButton>
                                  )}

                                  {index >= 1 && formik.values.details.length > 1 && (
                                    <IconButton onClick={() => remove(index)}>
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6.7 4C6.86568 4 7 3.86569 7 3.7V2.3C7 2.13431 7.13431 2 7.3 2H16.7C16.8657 2 17 2.13431 17 2.3V3.7C17 3.86569 17.1343 4 17.3 4H21.7C21.8657 4 22 4.13431 22 4.3V5.7C22 5.86569 21.8657 6 21.7 6H20.3C20.1343 6 20 6.13431 20 6.3V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V6.3C4 6.13432 3.86569 6 3.7 6H2.3C2.13431 6 2 5.86569 2 5.7V4.3C2 4.13431 2.13431 4 2.3 4H6.7ZM6.3 6C6.13432 6 6 6.13431 6 6.3V19.7C6 19.8657 6.13431 20 6.3 20H17.7C17.8657 20 18 19.8657 18 19.7V6.3C18 6.13431 17.8657 6 17.7 6H6.3ZM9 9.3C9 9.13431 9.13431 9 9.3 9H10.7C10.8657 9 11 9.13431 11 9.3V16.7C11 16.8657 10.8657 17 10.7 17H9.3C9.13431 17 9 16.8657 9 16.7V9.3ZM13 9.3C13 9.13431 13.1343 9 13.3 9H14.7C14.8657 9 15 9.13431 15 9.3V16.7C15 16.8657 14.8657 17 14.7 17H13.3C13.1343 17 13 16.8657 13 16.7V9.3Z"
                                          fill="#B3261E"
                                        />
                                      </svg>
                                    </IconButton>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      );
                    }}
                  </FieldArray>
                </table>
              </Grid>
              <Grid item xs={12}>
                <ButtonGroup color="primary" aria-label="primary button group">
                  <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                    बुझाउनुहोस् &nbsp;
                    {isDisabled ? <CircularProgress size={30} /> : null}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </div>
  );
};

export default ApplicationAttachedDocumentsForm;
