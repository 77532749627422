import { decodeToken } from './decodeJWT';

export const isLoginAndValidToken = () => {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    let decoded = decodeToken(accessToken);
    if (Date.now() / 1000 < decoded.exp && decoded) {
      return true;
    }

    return false;
  }
};
