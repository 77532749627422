import React, { useState, useEffect } from 'react';
import {useLocation} from "react-router-dom";

import baliPatro from 'constants/baliPatro/description';

import 'assets/css/baliPatroTable.css';

const BaliPatroTable = () => {
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);

  const search = useLocation().search;
  
  useEffect(() => {
    const title = new URLSearchParams(search).get('query');

    setTitle(title);
    setData(baliPatro[title]);

  }, [title]);

  return (
    <div>
      {data && (
        <table className="bali-table">
          <thead>
            <tr>
              <th colSpan="3" style={{ fontSize: '22px' }}>
                {title.split('_').join(' ')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="2">बजारमा उपलब्ध मुख्य जातहरु</td>
              <td>{data[0]}</td>
            </tr>
            <tr>
              <td rowSpan="2">बाली/बेर्ना लगाईने समय </td>
              <td>मध्य पहाड (७००-२००० मी) </td>
              <td>{data[1]}</td>
            </tr>
            <tr>
              <td>तराई/बेंसी (७०० मी. भन्दा कम) </td>
              <td>{data[2]}</td>
            </tr>
            <tr>
              <td rowSpan="2">बाली फल्ने समय </td>
              <td>मध्य पहाड (७००-२००० मी) </td>
              <td>{data[3]}</td>
            </tr>
            <tr>
              <td>तराई/बेंसी (७०० मी. भन्दा कम)</td>
              <td>{data[4]}</td>
            </tr>
            <tr>
              <td colSpan="2">बिऊ दर (ग्राम प्रति कठ्ठा)</td>
              <td>{data[5]}</td>
            </tr>
            <tr>
              <td colSpan="2">रोप्ने तरिका </td>
              <td>{data[6]}</td>
            </tr>
            <tr>
              <td rowSpan="2">बाली लगाउने दुरी </td>
              <td>हार-हार (से.मी) </td>
              <td>{data[7]} </td>
            </tr>
            <tr>
              <td>बोट-बोट (से.मी)</td>
              <td>{data[8]}</td>
            </tr>
            <tr>
              <td colSpan="2">उत्पादन(के.जी प्रति कठ्ठा)</td>
              <td>{data[9]}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default BaliPatroTable;
