import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import 'assets/css/table.css';
import {
  Button,
  IconButton,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircle from '@material-ui/icons/CheckCircle';

import MainMarketService from 'services/mainMarket';
import WithoutPerson from './WithoutPerson';

import AlertDialog from 'components/AlertDialog';

export const getInputField = (title, helperText, index, key, state, setState, isRequired = false, isDisabled = false) => {
  return (
    <FormControl style={{ marginBottom: '5px', width: '80%' }}>
      <InputLabel htmlFor="my-input">{title}</InputLabel>
      <Input
        aria-describedby="my-helper-text"
        value={state}
        onChange={(e) => setState(index, key, e.target.value)}
        required={isRequired}
        disabled={isDisabled}
      />
      <FormHelperText id="my-helper-text">
        {helperText}
        {isRequired && <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>}
      </FormHelperText>
    </FormControl>
  );
};

export const getRadio = (title, options, index, key, state, setState, isRequired = false, isDisabled = false) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        {title}
        {isRequired && <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>}
      </FormLabel>
      <RadioGroup aria-label="radio" value={state} onChange={(e) => setState(index, key, e.target.value)} >
        {options.map((option, index) => {
          return <FormControlLabel value={option.value} control={<Radio />} label={option.label} disabled={isDisabled} key={index} />;
        })}
      </RadioGroup>
    </FormControl>
  );
};

const MainMarket = ({ nextPage, isInputDisabled }) => {
  const history = useHistory();

  const stateVariable = {
    mainMarket: null,
    marketingSystem: null,
  };

  const [myState, setMyState] = useState([{ ...stateVariable }]);

  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('userId');

  const [savedStatus, setSavedStatus] = useState([{status: "none"}]);

  const [openDialog, setOpenDialog] = useState(false);
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  }

  const onChange = (index, key, value) => {
    let currentEntry = [...myState];
    currentEntry[index][key] = value;
    setMyState([...currentEntry]);
  };

  const removeStateObject = (index) => {
    let currentEntry = [...myState];
    currentEntry.splice(index, 1);
    setMyState([...currentEntry]);

    savedStatus.splice(index, 1);
    setSavedStatus([...savedStatus]);
  };

  const changeSavedStatus = (index, status) => {
    savedStatus[index]['status'] = status;
    setSavedStatus([...savedStatus]);
  }

  const submitItem = async (index) => {
    try{
      await MainMarketService.createMarketData(myState[index], userId);
      changeSavedStatus(index, 'saved');
      setIsSuccessDialog(true);
      setOpenDialog(true);
    }
    catch(e) {
      changeSavedStatus(index, 'error')
      setIsSuccessDialog(false);
      setOpenDialog(true);
    }
  }


  const submit = () => {
    nextPage(8);

    history.push({
      pathname: '/survey',
      search: `?userId=${userId}`,
    });

    setMyState([{ ...stateVariable }]);
  };

  const marketingSystemOptions = [
    { value: 'स्वयम्', label: 'स्वयम्' },
    { value: 'समुहगत', label: 'समुहगत' },
    { value: 'ठेकेदार', label: 'ठेकेदार' },
  ];

  return (
    <div>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose}/>
      {userId ? (
        <Grid item xs={12}>
          <h3 style={{ marginBottom: '10px' }}>मुख्य बजार तथा बजारीकरण प्रणली</h3>

          <table className="table">
            <tr>
              <th>क्रम संख्या </th>
              <th>मुख्य बजार</th>
              <th>बजारीकरण प्रणली</th>
              <th></th>
            </tr>
            {myState.map((state, index) => {
              return (
                <tr>
                  <td>{index + 1}.</td>
                  <td>{getInputField('', 'मुख्य बजार', index, 'mainMarket', myState[index].mainMarket, onChange, true, isInputDisabled || savedStatus[index].status === 'saved')}</td>
                  <td>
                    {getRadio('', marketingSystemOptions, index, 'marketingSystem', myState.marketingSystem, onChange, false, isInputDisabled || savedStatus[index].status === 'saved')}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {/* <span */}
                    {/*   style={{ color: 'red' }} */}
                    {/*   title="एन्ट्री हटाउनुहोस्" */}
                    {/*   className="fa fa-trash" */}
                    {/*   onClick={() => removeStateObject(index)} */}
                    {/* /> */}
                    { savedStatus[index].status === 'saved' ?
                        <IconButton aria-label="saveitem">
                          <CheckCircle color="primary" fontSize="small"/>
                        </IconButton> :
                        <>
                          <IconButton onClick={() => submitItem(index)} aria-label="saveitem">
                            <SaveIcon color="primary" fontSize="small" />
                          </IconButton>
                          <IconButton onClick={() => removeStateObject(index)} aria-label="delete">
                            <DeleteIcon color="secondary" fontSize="small" />
                          </IconButton>
                        </>
                      }
                  </td>
                </tr>
              );
            })}
          </table>
          <ButtonGroup color="primary" aria-label="primary button group" style={{ margin: '10px 0' }}>
            <Button
              onClick={() => {
                setMyState([...myState, { ...stateVariable }]);
                setSavedStatus([...savedStatus, {status: 'none'}]);
              }}
            >
              थप मुख्य बजार तथा बजारीकरण प्रणली
            </Button>
            <Button onClick={() => submit()}>बुझाउनुहोस्</Button>
          </ButtonGroup>
        </Grid>
      ) : (
        <WithoutPerson nextPage={nextPage} />
      )}
    </div>
  );
};

export default MainMarket;
