import React from 'react';

import { Container } from '@material-ui/core';

import Banner from 'components/Banner';
import Copyright from 'components/Copyright';
import SearchAppBar from 'components/Appbar';

import BaliPatroTable from 'components/BaliPatroTable';

export default () => {
  return (
    <div>
      <div className="no-print">
        <Banner />
        <SearchAppBar title="कृषि तथा पशुपालनमा सक्रिय सेवा प्रदायक (समूह/सहकारी/फरम)को विवरण" />
      </div>
      <span onClick={() => window.print()} className="no-print print-btn">
        <i className="fa fa-print"></i>
        &nbsp; &nbsp; प्रिन्ट गर्नुहोस्
      </span>
      <Container style={{ width: 960, marginTop: 50 }}>
        <BaliPatroTable />
      </Container>
      <Copyright website="उदयपुरगढी गाउँपालिका" />
    </div>
  );
};
