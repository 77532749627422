import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import 'assets/css/table.css';
import { Button, ButtonGroup, Grid } from '@material-ui/core';
import { getInputField, getRadio, getRadioBoolean } from './Land';
import PayekaSubidhaService from 'services/payekaSubidha';
import WithoutPerson from './WithoutPerson';

const PayekaSubidha = ({ nextPage }) => {
  const history = useHistory();

  const stateVariable = {
    gotEconomicHelpForTools: false,
    gotHelpForAgricultureLoan: false,
    gotHelpForMarketManagement: false,
    gotTechnicalHelpFromState: false,
    gotOtherHelp: false,
    helpDescription: null,
    helpingOrganizationName: null,
    helpingOrganizationType: null,
  };

  const [myState, setMyState] = useState({ ...stateVariable });

  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('userId');

  const onChange = (key, value) => {
    myState[key] = value;
    setMyState({
      ...myState,
    });
  };

  const helpingOrganizationTypeOption = [
    { label: 'राष्ट्रिय संस्था', value: 'राष्ट्रिय संस्था' },
    { label: 'अन्तराष्ट्रिय संस्था', value: 'अन्तराष्ट्रिय संस्था' },
    { label: 'प्रदेश स्तर', value: 'प्रदेश स्तर' },
    { label: 'गाऊपालिका स्तर', value: 'गाऊपालिका स्तर' },
  ];

  const trueFalseOptions = [
    { value: true, label: 'छ' },
    { value: false, label: 'छैन' },
  ];

  const submit = async () => {
    await PayekaSubidhaService.createSubidhaData(myState, userId);

    setMyState({ ...stateVariable });

    nextPage(6);

    history.push({
      pathname: '/survey',
      search: `?userId=${userId}`,
    });
  };

  return (
    <div>
      {userId ? (
        <Grid item xs={12}>
          <h3 style={{ marginBottom: '10px' }}>सरकारी तथा गैहसरकारी निकायबाट पाएको सुबिधा</h3>

          <table className="table">
            <tr>
              <th>क्र.सं</th>
              <th>विवरण</th>
              <th>छ/छैन</th>
            </tr>
            <tr>
              <td>१.</td>
              <td>
                कृषि जन्य उपकरण खरिदमा आर्थिक सहयोग{' '}
                <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>
              </td>
              <td>
                {getRadioBoolean(
                  '',
                  trueFalseOptions,
                  'gotEconomicHelpForTools',
                  myState.gotEconomicHelpForTools,
                  onChange
                )}
              </td>
            </tr>
            <tr>
              <td>२.</td>
              <td>
                कृषि कर्जा प्रप्तिमा सहयोग <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>
              </td>
              <td>
                {getRadioBoolean(
                  '',
                  trueFalseOptions,
                  'gotHelpForAgricultureLoan',
                  myState.gotHelpForAgricultureLoan,
                  onChange
                )}
              </td>
            </tr>
            <tr>
              <td>३.</td>
              <td>
                बजार व्यवस्थापन तथा बजारीकरणमा सहयोग{' '}
                <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>
              </td>
              <td>
                {getRadioBoolean(
                  '',
                  trueFalseOptions,
                  'gotHelpForMarketManagement',
                  myState.gotHelpForMarketManagement,
                  onChange
                )}
              </td>
            </tr>
            <tr>
              <td>४.</td>
              <td>
                व्यवसायकि कृषिका लागि पालिका स्तरीय प्रविधिक सहयोग{' '}
                <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>
              </td>
              <td>
                {getRadioBoolean(
                  '',
                  trueFalseOptions,
                  'gotTechnicalHelpFromState',
                  myState.gotTechnicalHelpFromState,
                  onChange
                )}
              </td>
            </tr>
            <tr>
              <td>५.</td>
              <td>
                अन्य सहयोग <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>
              </td>
              <td>{getRadioBoolean('', trueFalseOptions, 'gotOtherHelp', myState.gotOtherHelp, onChange)}</td>
            </tr>
            <tr>
              <td></td>
              <td colspan="2">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  अनुदान (विवरण उल्लेख गर्नुहोस)
                  {getInputField('', 'विवरण उल्लेख गर्नुहोस', 'helpDescription', myState.helpDescription, onChange)}
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td colspan="2">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  सहयोगी संस्थाको नाम-
                  {getInputField(
                    '',
                    'सहयोगी संस्थाको नाम',
                    'helpingOrganizationName',
                    myState.helpingOrganizationName,
                    onChange
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td colspan="2">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  सहयोगी संस्थाको प्रकार- &nbsp;&nbsp;
                  {getRadio(
                    'संस्थाको प्रकार',
                    helpingOrganizationTypeOption,
                    'helpingOrganizationType',
                    myState.helpingOrganizationType,
                    onChange
                  )}
                </div>
              </td>
            </tr>
          </table>
          <ButtonGroup color="primary" aria-label="primary button group" style={{ margin: '10px 0' }}>
            <Button onClick={() => submit()}>बुझाउनुहोस्</Button>
          </ButtonGroup>
        </Grid>
      ) : (
        <WithoutPerson nextPage={nextPage} />
      )}
    </div>
  );
};

export default PayekaSubidha;
