import { Grid, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useState } from 'react';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';
import * as Yup from 'yup';
import RenderInput from '../RenderInput';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const fields = [
  { id: 'district', label: 'जिल्ला', type: 'text' },
  { id: 'applicant_name', label: 'आवेदकको नाम', type: 'text' },
  { id: 'permanent_address', label: 'स्थायी ठेगाना', type: 'text' },
  { id: 'proposed_office_name', label: 'प्रस्तावित कार्यलयको नाम', type: 'text' },
  { id: 'program_location_address', label: 'कार्यक्रम संचालन स्थलको ठेगाना', type: 'text' },
  { id: 'on_site_verification_date', label: 'स्थलगत प्रमाणीकरण मिति', type: 'date' },
];

const ApplicationAdmin = ({ id, setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  const getValidationSchema = (fields) => {
    const shape = {};
    fields.forEach((field) => {
      shape[field.id] = Yup.string().required(`${field.label} is required`);
    });
    return Yup.object().shape(shape);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Grid item xs={12}>
        <h3>स्थलगत प्रमारितकरण मुल्याक्तन फारम</h3>
      </Grid>
      <Formik
        initialValues={{
          app: id,
          district: '',
          applicant_name: '',
          permanent_address: '',
          proposed_office_name: '',
          program_location_address: '',
          on_site_verification_date: '',
          person: getUserInfo().person_id,
        }}
        validationSchema={getValidationSchema(fields)}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            const response = await PrastabanaService.addApplicationAdminListView(values);
            if (response?.id) {
              setActiveStep(3);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
      >
        {(formik) => <RenderInput fields={fields} formik={formik} isDisabled={isDisabled} />}
      </Formik>
    </div>
  );
};

export default ApplicationAdmin;
