import React from 'react';

import 'assets/css/maps.css';

const GoogleMaps = () => {
  return (
    <div>
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            width="100%"
            height="400"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=%E0%A4%89%E0%A4%A6%E0%A4%AF%E0%A4%AA%E0%A5%81%E0%A4%B0%E0%A4%97%E0%A4%A2%E0%A5%80%20%E0%A4%97%E0%A4%BE%E0%A4%89%E0%A4%AA%E0%A4%BE%E0%A4%B2%E0%A4%BF%E0%A4%95%E0%A4%BE,%20%E0%A4%97%E0%A4%BE%E0%A4%89%20%E0%A4%95%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%AF%E0%A4%AA%E0%A4%BE%E0%A4%B2%E0%A4%BF%E0%A4%95%E0%A4%BE%20%E0%A4%95%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B2%E0%A4%AF&t=k&z=17&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            title="Udaypurgadhi Nepal"
          ></iframe>
	  {/*<a href="https://www.embedgooglemap.net ">Nepal</a>
          <br />
          <a href="https://www.embedgooglemap.net">Embed Google Maps</a>*/}
        </div>
      </div>
    </div>
  );
};

export default GoogleMaps;
