import YouthActivityDetailsForm from 'components/Forms/YouthActivityDetailsForm';
import YouthAssociationForm from 'components/Forms/YouthAssociationForm';
import YouthGrantForm from 'components/Forms/YouthGrantForm';
import React, { useEffect, useState } from 'react';
import YouthService from 'services/youthService';

const YouthQuestions = () => {
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      const applications = await YouthService.getYouth();
      if (applications?.count > 0) {
        setActiveStep(2);
      }
      const res = await YouthService.getYouthActi();
      if (res?.count > 0) {
        setActiveStep(3);
      }
      const reposne = await YouthService.getYouthAss();
      if (reposne?.count > 0) {
        setActiveStep(4);
      }
      // const budgetRes = await PrastabanaService.getBudgetData();
      // if (budgetRes?.count > 0) {
      //   setActiveStep(4);
      // }
      // const implRes = await PrastabanaService.getTrainingData();
      // if (implRes?.count > 0) {
      //   setActiveStep(5);
      // }
    };

    fetchData();
  }, []);

  return (
    <>
      {activeStep === 1 && <YouthGrantForm setActiveStep={setActiveStep} />}

      {activeStep === 2 && <YouthActivityDetailsForm setActiveStep={setActiveStep} />}
      {activeStep === 3 && <YouthAssociationForm setActiveStep={setActiveStep} />}
      {activeStep === 4 && <div>Submitted</div>}
    </>
  );
};

export default YouthQuestions;
