import React from 'react';
import { Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	gridContainer: {
		padding: '0px 5px',
		borderRadius: '5px',
	},
	grayBg: {
		backgroundColor: '#ddd',
	},
	dataKey: {
		color: '#111'
	},
	dataValue: {
		color: '#444'
	},
});

const CustomGridRow = ({dataKeys, dataValues, isGray}) => {
	const classes = useStyles();
	const grayGridContainer = classes.gridContainer + ' ' + classes.grayBg;

	if(dataKeys.length !== dataValues.length) {
		return <Grid container>...</Grid>
	}

	return(
		<Grid container className={isGray ? grayGridContainer : classes.gridContainer}>
			{
				dataKeys.map((datakey, i) => {
					return <Grid item xs={12} md={ Math.ceil(12 / dataKeys.length) }>
						<Typography>
							{datakey && <span className={classes.dataKey}>{datakey}: </span>}
							<span className={classes.dataValue}>{dataValues[i]}</span>
						</Typography>
					</Grid>
				})
			}
		</Grid>
	)
}

export default CustomGridRow;
