import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import ListIcon from './ListIcon';
import leftPanelConstants from 'constants/leftPanelConstants';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#3e3e3e',
  },
  table: {
    marginBottom: 15,
    width: '100%',
  },
  headerRight: {
    textAlign: 'right',
    float: 'right',
    color: '#3e3e3e',
  }
}));

const LeftStatistics = ({ statistics }) => {
  const classes = useStyles();

  return (
    <div>
      <h2>उदयपुरगढ़ी गाउँपालिकाको संछिप्त परिचय</h2>
      <p class="intro-para">सगरमाथा अंचल उदयपुर जिल्ला २६°४९'५३" उत्तरी अक्षांशदेखि २७°०२'०२" उत्तरी अक्षांशसम्म र ८६°२६'५०" पुर्बी देशान्तरदेखि ८६°३६'५५" पुर्बी देशान्तरसम्म अवस्थित साबिकको तावाश्री, बर्रे, डुम्रे, पंचावती तथा भलायड़ाडा गा.वि.स. हरु मिली उदयपुरगढी गाउँपालिका बनेको हो। जिल्लाको पुरानो सदरमुकाम तथा ऐतिहासिक महत्व बोकेको उदयपुरगढीको नामको आधारमा गाउँपालिकाको नाम तथा केन्द्र उदयपुरगढी नै रहने गरि यस छेत्रलाई नयाँ उदयपुरगढी गाउँपालिकाको रुपमा प्रस्ताव गरिएको हो।</p>
      <h2>विविध तथ्याङ्कहरु</h2>
      {
        !statistics && <div style={{height: 40}}>
          <LinearProgress color="secondary" />
        </div>
      }
      {statistics && (
        <div>
          <table className={classes.table}>
            <tr>
              <td className={classes.title}>
                किसान तथ्याङ्क
              </td>
              <th className={classes.headerRight}>संख्या</th>
            </tr>
            <ListIcon title="कुल किसान संख्या" count={statistics.total_farmers_count} imageUrl="" />
            <ListIcon title="पुरुष किसान संख्या" count={statistics.total_male_count} imageUrl="" />
            <ListIcon title="महिला किसान संख्या" count={statistics.total_female_count} imageUrl="" />
            <ListIcon title="अन्य किसान संख्या" count={statistics.total_other_gender_count} imageUrl="" />
          </table>

          <table className={classes.table}>
            <tr>
              <td className={classes.title}>
                कृषकको किसिम
              </td>
              <th className={classes.headerRight}>संख्या</th>
            </tr>
            {statistics.farmer_categories &&
              Object.keys(statistics.farmer_categories).map((key, index) => {
                return <ListIcon title={key} count={statistics.farmer_categories[key]} imageUrl="" key={index} />;
              })}
          </table>

          <table className={classes.table}>
            <tr>
              <td colspan="2" className={classes.title}>
                जनावर तथ्याङ्क
              </td>
              <th className={classes.headerRight}>संख्या</th>
            </tr>
            {statistics.animals_stats &&
              statistics.animals_stats.map((item, index) => {
                return <ListIcon title={item.animal_name} count={item.total_count} imageUrl={leftPanelConstants.animalsIcons[item.animal_name]} key={index} />;
              })}
          </table>

          <table className={classes.table}>
            <tr>
              <td colspan="2" className={classes.title}>
                बाली तथ्याङ्क
              </td>
              <th className={classes.headerRight}>उत्पादन (टन)</th>
            </tr>
            {statistics.crops_stats &&
              statistics.crops_stats.map((item, index) => {
                return item.crop_name !== 'अन्य' ? <ListIcon title={item.crop_name} count={(item.total_production / 1000.0).toFixed(2)} imageUrl={leftPanelConstants.cropsIcons[item.crop_name]} key={index} /> : null;
              })}
          </table>
        </div>
      )}
    </div>
  );
};

export default LeftStatistics;
