import jwt_decode from 'jwt-decode';

export const decodeToken = (token) => {
  const decoded = jwt_decode(token);
  return decoded;
};

export const getUserInfo = () => {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    let decoded = decodeToken(accessToken);
    return decoded;
  }
};
