import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';

const HorizontalBarChart = ({ title, data, labels }) => {

  const options = {
    indexAxis: 'x',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    title: {
      display: true,
      text: title,
      fontSize: 20,
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: title,
        fontSize: 20,
      },
    },
  };

  const chartData = {
    labels: [...labels],
    datasets: [...data],
  };

  return (
    <div style={{ margin: '25px 0' }}>
      <HorizontalBar data={chartData} options={options} />
    </div>
  );
};

export default HorizontalBarChart;
