import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Switch, { Case, Default } from 'react-switch-case';

import sidebarMenu from 'constants/surveySidebar';
import Banner from 'components/Banner';
import Notes from 'components/Notes';
import RightDrawer from 'components/RightDrawer';

import PersonalService from 'services/personal';

import Land from 'components/Questions/Land';
import AnimalsEntry from 'components/Questions/AnimalsEntry';
import CropsEntry from 'components/Questions/CropsEntry';
import OtherBusiness from 'components/Questions/OtherBusiness';
import UpcomingPlan from 'components/Questions/UpcomingPlan';
import PayekaSubidha from 'components/Questions/payekaSubidha';
import MainMarket from 'components/Questions/MainMarket';
import SamuhaSahakariFarmAssociation from 'components/Questions/SamuhaSahakariFarmAssociation';
import FormSubmit from 'components/Questions/FormSuccess';

const drawerWidth = 300;

const MyAppBar = withStyles({
  positionFixed: {
    top: 'unset',
    position: 'absolute',
  },
})(AppBar);
const MyDrawer = withStyles({
  paper: {
    top: 'unset',
    position: 'absolute',
  },
})(Drawer);
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  rightButton: {
    backgroundColor: theme.palette.secondary.main,
    position: "fixed",
    right: 0,
    top: "50%",
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.secondary.light
    }
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [detailsData, setDetailsData] = useState(null);

  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('userId');

  useEffect(()=>{
    (async ()=>{
      const res = await PersonalService.getDetailsView(userId);
      setDetailsData(res);
    })();
  },[])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleRightDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setRightDrawerOpen(open);
  };

  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);

  return (
    <>
      <Banner />
      <div className={classes.root}>
        <CssBaseline />
        <MyAppBar
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {sidebarMenu[selectedMenuIndex] ? sidebarMenu[selectedMenuIndex].title : 'सफलतापूर्वक अपलोड'}
            </Typography>
          </Toolbar>
        </MyAppBar>
        <MyDrawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon color="#fffff" /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {sidebarMenu.map((menu, index) => (
              <ListItem button key={index} onClick={() => setSelectedMenuIndex(index)}>
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <ListItemText primary={menu.title} />
              </ListItem>
            ))}
          </List>
        </MyDrawer>
        <SwipeableDrawer
          anchor="right"
          open={rightDrawerOpen}
          onClose={toggleRightDrawer(false)}
          onOpen={toggleRightDrawer(true)}
        >
          { !detailsData ? '...' : <RightDrawer person={detailsData.person_details}/> }
        </SwipeableDrawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <IconButton onClick={toggleRightDrawer(true)} className={classes.rightButton}>
            <ChevronRightIcon />
          </IconButton>
          <Notes />
          <Switch condition={selectedMenuIndex}>
            <Case value={0}>
              <Land nextPage={setSelectedMenuIndex} />
            </Case>
            <Case value={1}>
              <CropsEntry nextPage={setSelectedMenuIndex} />
            </Case>
            <Case value={2}>
              <AnimalsEntry nextPage={setSelectedMenuIndex} />
            </Case>
            <Case value={3}>
              <OtherBusiness nextPage={setSelectedMenuIndex} />
            </Case>
            <Case value={4}>
              <UpcomingPlan nextPage={setSelectedMenuIndex} />
            </Case>
            <Case value={5}>
              <PayekaSubidha nextPage={setSelectedMenuIndex} />
            </Case>
            <Case value={6}>
              <SamuhaSahakariFarmAssociation nextPage={setSelectedMenuIndex} />
            </Case>
            <Case value={7}>
              <MainMarket nextPage={setSelectedMenuIndex} />
            </Case>
            <Case value={8}>
              <FormSubmit />
            </Case>
            <Default>
              <span>गलत विकल्प !!</span>
            </Default>
          </Switch>
        </main>
      </div>
    </>
  );
}
