import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, ButtonGroup } from '@material-ui/core';
import LandService from 'services/land';
import { getInputField, getRadio, getRadioBoolean } from './Land';

import 'assets/css/table.css';

const LandExtra = ({ nextPage }) => {
  const history = useHistory();

  const stateVariable = {
    agriculturableArea: null,
    agriculturedArea: null,
    totalArea: null,
    isIrrigationAvailable: false,
    irrigatedArea: null,
    nonIrrigatedArea: null,
    irrigationType: null,
    isRoadConnected: false,
  };

  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('userId');

  const [myState, setMyState] = useState({
    ...stateVariable,
  });

  const onChange = (key, value) => {
    myState[key] = value;
    setMyState({
      ...myState,
    });
  };

  const submit = async () => {
    await LandService.createLandExtraData(myState, userId);
    setMyState({
      ...stateVariable,
    });

    nextPage(1);

    history.push({
      pathname: '/survey',
      search: `?userId=${userId}`,
    });
  };

  const trueFalseOptions = [
    { value: true, label: 'छ' },
    { value: false, label: 'छैन' },
  ];

  const irrigationTypeOptions = [
    { value: 'सतह', label: 'सतह' },
    { value: 'उप-सतह', label: 'उप-सतह' },
    { value: 'ड्रिप (थोपा सिचाई)', label: ' ड्रिप (थोपा सिचाई)' },
    { value: 'फोहरा सिचाई', label: 'फोहरा सिचाई' },
  ];

  return (
    <div style={{ marginTop: '15px' }}>
      <h2>थप विवरण</h2>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{getInputField('खेती योग्य जग्गा', null, 'agriculturableArea', myState.agriculturableArea, onChange)}</div>
        <div>{getInputField('खेती गरिएको जग्गा', null, 'agriculturedArea', myState.agriculturedArea, onChange)}</div>
        <div>{getInputField('कुल क्षेत्रफल', null, 'totalArea', myState.totalArea, onChange)}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '25px' }}>
        <div>
          {getRadioBoolean(
            'सिचाई सुविधा ?',
            trueFalseOptions,
            'isIrrigationAvailable',
            myState.isIrrigationAvailable,
            onChange
          )}
        </div>
        <div>
          {getRadio('सिचाईको प्रकार ', irrigationTypeOptions, 'irrigationType', myState.irrigationType, onChange)}
        </div>
        <div></div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h3>सिन्चित/अ-सिन्चित क्षेत्रफल</h3>
        {getInputField('सिन्चित  क्षेत्रफल', 'क्षेत्रफलको ढाँचा: पहाडमा प्रचलित (1-2-3-4) अथवा तराईमा प्रचलित (1-2-3)', 'irrigatedArea', myState.irrigatedArea, onChange)}
        <br />
        {getInputField('अ-सिन्चित क्षेत्रफल', 'क्षेत्रफलको ढाँचा: पहाडमा प्रचलित (1-2-3-4) अथवा तराईमा प्रचलित (1-2-3)', 'nonIrrigatedArea', myState.nonIrrigatedArea, onChange)}
      </div>
      <div style={{ marginTop: '25px' }}>
        {getRadioBoolean('सडक पुगेको ?', trueFalseOptions, 'isRoadConnected', myState.isRoadConnected, onChange)}
      </div>
      <br />
      <ButtonGroup color="primary" aria-label="primary button group">
        <Button onClick={() => submit()}>बुझाउनुहोस्</Button>
      </ButtonGroup>
    </div>
  );
};

export default LandExtra;
