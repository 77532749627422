export default {
  'प्रदेश नं- १': {
    धनकुटा: [
      'धनकुटा',
      'पाख्रीबास',
      'महालक्ष्मी',
      'सागुरीगढी',
      'चौविसे',
      'खाल्सा छिन्ताङ सहीदभूमि',
      'छथर जोरपाटी',
    ],
    इलाम: [
      'इलाम',
      'देउमाई',
      'माई',
      'सूर्योदय',
      'फाकफोकथुम',
      'माईजोगमाई',
      'चुलाचुली',
      'रोङ',
      'माङसेबुङ',
      'सन्दकपुर',
    ],
    झापा: [
      'मेचीनगर',
      'भद्रपुर',
      'बिर्तामोड',
      'अर्जुनधारा',
      'कन्काई',
      'शिवासताक्षी',
      'गौरादह',
      'दमक',
      'बुद्धशान्ति',
      'हल्दिबरी',
      'कचंकवल',
      'बर्हदशी',
      'झापा',
      'गौरीगन्ज',
      'कमल',
    ],
    खोटाँग: [
      'दिक्तेल',
      'रुपाकोट',
      'मझुवागढी',
      'हलेसी',
      'तुवाचुङ',
      'रावाबे‌सी',
      'केपिलासगढी',
      'ऐसेलुखर्क',
      'साकेला',
      'दिप्रुङ',
      'बराहापोखरी',
      'खोटेहाङ',
      'जन्तेढुंगा',
    ],
    मोरंग: [
      'विराटनगर',
      'सुन्दरहरैंचा',
      'बेलवारी',
      'पथरी शनिश्चरे',
      'रतुवामाई',
      'उर्लावारी',
      'रंगेली',
      'सुनवर्षी',
      'लेटाङ',
      'जहदा',
      'बुढीगंगा',
      'कटहरी',
      'धनपालथान',
      'कानेपोखरी',
      'ग्रामथान',
      'केरावारी',
      'मिक्लाजुङ',
    ],
    ओखलढुंगा: [
      'सिद्दिचरण',
      'मानेभञ्ज्याङ',
      'चम्पादेवी',
      'सुनकोशी',
      'मोलुङ',
      'चिसंखुगढी',
      'खिजिदेम्बा',
      'लिखु',
    ],
    पांचथर: [
      'फिदिम',
      'मिक्लाजुङ',
      'फाल्गुनन्द',
      'हिलिहाङ',
      'फालेलुङ',
      'याङवरक',
      'कुम्मायक',
      'तुम्बेवा',
    ],
    संखुवासभा: [
      'खादँवारी',
      'चैनपुर',
      'धर्मदेवी',
      'पाँचखपन',
      'मादी',
      'मकालु',
      'सिलीचोङ',
      'सभापोखरी',
      'चिचिला',
      'भोटखोला',
    ],
    सोलुखुम्बू: [
      'सोलुदुधकुण्ड',
      'दुधकौशिका',
      'नेचासल्यान',
      'दुधकोशी',
      'महाकुलुङ',
      'सोताङ',
      'खुम्बु पासाङल्हमु',
      'लिखुपिके',
    ],
    सुनसरी: [
      'इटहरी',
      'धरान',
      'बराहक्षेत्र',
      'ईनरुवा',
      'दुहवी',
      'रामधुनी',
      'कोशी',
      'हरिनगरा',
      'भोक्राहा',
      'देवानगन्ज',
      'गढी',
      'बर्जु',
    ],
    ताप्लेजुंग: [
      'फुङलिङ',
      'सिरीजङ्घा',
      'आठराई त्रिवेणी',
      'पाथीभरा याङवरक',
      'मेरिङदेन',
      'सिदिङ्वा',
      'फक्ताङलुङ',
      'मैवाखोला',
      'मिक्वाखोला',
    ],
    तेह्रथुम: ['म्याङलुङ', 'लालिगुराँस', 'आठराई', 'फेदाप', 'छथर', 'मेन्छयायेम'],
    उदयपुर: [
      'त्रियुगा',
      'कटारी',
      'चौदण्डीगढी',
      'वेलका',
      'उदयपुरगढी',
      'रौतामाई',
      'ताप्ली',
      'लिम्चुङबुङ',
    ],
    भोजपुर: [
      'भोजपुर',
      'षडानन्द',
      'हतुवागढी',
      'रामप्रसाद राई',
      'आमचोक',
      'ट्याम्केमैयुम',
      'अरुण',
      'पौवादुङमा',
      'साल्पासिलिछो',
    ],
  },
};
