import axios from 'axios';

import { makeMappings } from 'utils/convertCase';

const baseUri = process.env.REACT_APP_API_BASE_URI;
const url = baseUri + 'api/youth-grants/';
const youthGrantUrl = baseUri + 'api/youth-associations/';
const youthActiviUrl = baseUri + 'api/youth-activity-details/';

const youthAgreement = baseUri + 'api/youth-agreement/';
class YouthService {
  static async addYouth(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(url, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getYouth() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async addYouthAss(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(youthGrantUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getYouthAss() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(youthGrantUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async addYouthAgreement(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(youthAgreement, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getYouthAgreement() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(youthAgreement, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async addYouthActi(data) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);

    const response = await axios.post(youthActiviUrl, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getYouthActi() {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(youthActiviUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
  static async getYouthDetails(id) {
    const accessToken = localStorage.getItem('access_token');

    const response = await axios.get(url + id, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}

export default YouthService;
