import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { FormControl, Button } from '@material-ui/core';
import {
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  Container,
  IconButton,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircle from '@material-ui/icons/CheckCircle';

import CropService from 'services/crop';
import WithoutPerson from './WithoutPerson';

import AlertDialog from 'components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 90,
    width: '12ch',
  },
  formControlRadio: {
    margin: theme.spacing(1),
    minWidth: 180,
    width: '12ch',
  },
  deleteButton: {
    marginTop: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const cropTypes = ['खाद्यान्न', 'नगदे', 'दलहन', 'अन्य'];

const foodCropsNameList = ['धान', 'मकै', 'गहुँ', 'कोदो', 'जौँ', 'फापर'];

const cashCropsNameList = ['तेलहन', 'आलु', 'रवर', 'ऊखु', 'जुट', 'कपास'];

const beanCropsNameList = ['मुसुरो', 'चना', 'रहर', 'मास', 'खेसरी', 'गहत', 'भटमास', 'अन्य'];

const otherCropsNameList = ['फलफुल', 'तरकारी', 'चिया', 'कफी', 'खुर्सानी', 'अलैची', 'अदुवा', 'लसुन', 'बेसार'];

const cropsNameList = {
  खाद्यान्न: [...foodCropsNameList],
  नगदे: [...cashCropsNameList],
  दलहन: [...beanCropsNameList],
  अन्य: [...otherCropsNameList],
};

const cropsStateObject = {
  crop_type: 'खाद्यान्न',
  crop_name: null,
  own_area: null,
  rented_area: null,
  adhiya_area: null,
  mohi_area: null,
  total_area: null,
  land_type: null,
  crop_cultivated_season: null,
  total_production: null,
};

const cropsExtraInfoObject = {
  seeds_source: null,
  seeds_distributer: null,
};

export default ({ isInputDisabled, nextPage }) => {
  const classes = useStyles();
  const history = useHistory();

  // isEditable = true;
  // let isInputDisabled = !isEditable;

  const [crops, setCrops] = useState([{ ...cropsStateObject }]);
  const [cropsExtraInfo, setCropsExtraInfo] = useState({ ...cropsExtraInfoObject });
  const [savedStatus, setSavedStatus] = useState([{status: "none"}]);

  const [openDialog, setOpenDialog] = useState(false);
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  }

  const changeCropsState = (index, key, value, type) => {
    // if(type==="number") value = Number(value);
    let currentCrops = [...crops];
    currentCrops[index][key] = value;

    setCrops([...currentCrops]);
  };

  const changeCropExtraInfoState = (key, value, type) => {
    let currentCropExtraInfo = { ...cropsExtraInfo };
    currentCropExtraInfo[key] = value;

    setCropsExtraInfo({ ...currentCropExtraInfo });
  };

  const removeStateObject = (index) => {
    let currentCrops = [...crops];
    // currentCrops.pop();
    currentCrops.splice(index, 1);

    setCrops([...currentCrops]);

    savedStatus.splice(index, 1);
    setSavedStatus([...savedStatus]);
  };

  const changeSavedStatus = (index, status) => {
    savedStatus[index]['status'] = status;
    setSavedStatus([...savedStatus]);
  }

  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('userId');

  const submitItem = async (index) => {
    try{
      await CropService.createCropsData(crops[index], userId);
      changeSavedStatus(index, 'saved');
      setIsSuccessDialog(true);
      setOpenDialog(true);
    }
    catch(e) {
      changeSavedStatus(index, 'error')
      setIsSuccessDialog(false);
      setOpenDialog(true);
    }
  }

  const submit = () => {
    (async () => {
      try{
        await CropService.createCropExtraData(cropsExtraInfo, userId);

        nextPage(2);

        history.push({
          pathname: '/survey',
          search: `?userId=${userId}`,
        });
      }
      catch(e){
        setIsSuccessDialog(false);
        setOpenDialog(true);
      }
    })();
  };

  return (
    <>
      {userId ? (
        <Container>
          <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose}/>
          <Typography color="primary">कृषिजन्य वालीहरुको उत्पादन स्थिति (वार्षिक)</Typography>
          <Container>
            {crops.map((cropState, i) => (
              <Container key={i}>
                <FormControl className={classes.margin} style={{ width: '2ch' }}>
                  <InputLabel htmlFor="serial_no">{i + 1}.</InputLabel>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="crop_type">वालिको प्रकार</InputLabel>
                  <Select
                    value={cropState.crop_type}
                    onChange={(e) => {
                      changeCropsState(i, 'crop_name', null, 'string');
                      changeCropsState(i, 'crop_type', e.target.value, 'string');
                    }}
                    id="crop_type"
                    aria-describedby="crop_type_helper"
                    disabled={isInputDisabled || savedStatus[i].status === 'saved'}
                  >
                    {cropTypes.map((cropType) => (
                      <MenuItem value={cropType}>{cropType}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText id="crop_type_helper">
                    <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="crop_name">वालिको नाम</InputLabel>
                  <Select
                    value={cropState.crop_name}
                    onChange={(e) => changeCropsState(i, 'crop_name', e.target.value, 'string')}
                    id="crop_name"
                    aria-describedby="crop_name_helper"
                    disabled={isInputDisabled || savedStatus[i].status === 'saved'}
                  >
                    {cropsNameList[cropState['crop_type']].map((cropName) => (
                      <MenuItem value={cropName}>{cropName}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText id="crop_name_helper">
                    छान्नुहोस <span style={{ fontWeight: 'bold', color: 'red', margin: '0 3px' }}>*</span>
                  </FormHelperText>
                </FormControl>

                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="own_area">आफ्नै</InputLabel>
                  <Input
                    value={cropState.own_area}
                    onChange={(e) => changeCropsState(i, 'own_area', e.target.value, 'number')}
                    id="own_area"
                    disabled={isInputDisabled || savedStatus[i].status === 'saved'}
                    value={cropState['own_area']}
                    aria-describedby="own_area_helper"
                  />
                  <FormHelperText id="own_area_helper">क्षेत्रफल</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="rented_area">भाडामा</InputLabel>
                  <Input
                    value={cropState.rented_area}
                    onChange={(e) => changeCropsState(i, 'rented_area', e.target.value, 'number')}
                    id="rented_area"
                    disabled={isInputDisabled || savedStatus[i].status === 'saved'}
                    value={cropState['rented_area']}
                    aria-describedby="rented_area_helper"
                  />
                  <FormHelperText id="rented_area_helper">क्षेत्रफल</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="adhiya_area">अधिया</InputLabel>
                  <Input
                    value={cropState.adhiya_area}
                    onChange={(e) => changeCropsState(i, 'adhiya_area', e.target.value, 'number')}
                    id="adhiya_area"
                    disabled={isInputDisabled || savedStatus[i].status === 'saved'}
                    value={cropState['adhiya_area']}
                    aria-describedby="adhiya_area_helper"
                  />
                  <FormHelperText id="adhiya_area_helper">क्षेत्रफल</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="mohi_area">मोहि</InputLabel>
                  <Input
                    value={cropState.mohi_area}
                    onChange={(e) => changeCropsState(i, 'mohi_area', e.target.value, 'number')}
                    id="mohi_area"
                    disabled={isInputDisabled || savedStatus[i].status === 'saved'}
                    value={cropState['mohi_area']}
                    aria-describedby="mohi_area_helper"
                  />
                  <FormHelperText id="mohi_area_helper">क्षेत्रफल</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="total_area">कुल क्षेत्रफल</InputLabel>
                  <Input
                    value={cropState.total_area}
                    onChange={(e) => changeCropsState(i, 'total_area', e.target.value, 'number')}
                    id="total_area"
                    disabled={isInputDisabled || savedStatus[i].status === 'saved'}
                    value={cropState['total_area']}
                    aria-describedby="total_area_helper"
                  />
                  <FormHelperText id="total_area_helper">क्षेत्रफल</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="total_production">उत्पादन</InputLabel>
                  <Input
                    value={cropState.total_production}
                    onChange={(e) => changeCropsState(i, 'total_production', e.target.value, 'number')}
                    id="total_production"
                    disabled={isInputDisabled || savedStatus[i].status === 'saved'}
                    value={cropState['total_production']}
                    type="number"
                    aria-describedby="total_production_helper"
                  />
                  <FormHelperText id="total_production_helper">किलो</FormHelperText>
                </FormControl>

                { savedStatus[i].status === 'saved' ?
                  <IconButton aria-label="saveitem" className={classes.deleteButton}>
                    <CheckCircle color="primary" fontSize="small"/>
                  </IconButton> :
                  <>
                    <IconButton onClick={() => submitItem(i)} aria-label="saveitem" className={classes.deleteButton}>
                      <SaveIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => removeStateObject(i)} aria-label="delete" className={classes.deleteButton}>
                      <DeleteIcon color="secondary" fontSize="small" />
                    </IconButton>
                  </>
                }
              </Container>
            ))}

            <Button
              onClick={() => {
                setCrops([...crops, { ...cropsStateObject }]);
                setSavedStatus([...savedStatus, {status: 'none'}]);
              }}
              variant="contained"
              size="small"
              color="primary"
              className={classes.margin}
            >
              थप वालि
            </Button>
          </Container>

          <br></br>
          <Typography color="primary">वालीनाली सम्बन्धी अन्य विवरण</Typography>
          <br></br>

          <Container>
            <FormControl component="fieldset" className={classes.formControlRadio}>
              <FormLabel component="legend">विउबीजनको श्रोत</FormLabel>
              <RadioGroup
                aria-label="seeds_source"
                name="seeds_source"
                value={cropsExtraInfo.seeds_source}
                onChange={(e) => changeCropExtraInfoState('seeds_source', e.target.value)}
              >
                <FormControlLabel value="स्थानिय" control={<Radio />} label="स्थानिय" disabled={isInputDisabled} />
                <FormControlLabel value="उन्नत" control={<Radio />} label="उन्नत" disabled={isInputDisabled} />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" className={classes.formControlRadio}>
              <FormLabel component="legend">विउबीजनको प्रदायक</FormLabel>
              <RadioGroup
                aria-label="seeds_distributer"
                name="seeds_distributer"
                value={cropsExtraInfo.seeds_distributer}
                onChange={(e) => changeCropExtraInfoState('seeds_distributer', e.target.value)}
              >
                <FormControlLabel
                  value="सरकारी निकाय"
                  control={<Radio />}
                  label="सरकारी निकाय"
                  disabled={isInputDisabled}
                />
                <FormControlLabel
                  value="प्राईभेट कृषि फर्म"
                  control={<Radio />}
                  label="प्राईभेट कृषि फर्म"
                  disabled={isInputDisabled}
                />
                <FormControlLabel
                  value="अन्य संघ/संस्था"
                  control={<Radio />}
                  label="अन्य संघ/संस्था (NGO/INGO)"
                  disabled={isInputDisabled}
                />
              </RadioGroup>
            </FormControl>
          </Container>
          <br></br>
          <Button
            onClick={() => {
              submit();
            }}
            variant="contained"
            color="primary"
            size="medium"
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            बुझाउनुहोस
          </Button>
        </Container>
      ) : (
        <WithoutPerson nextPage={nextPage} />
      )}
    </>
  );
};
