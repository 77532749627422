import React, { useEffect, useState } from 'react';
import IrrigationForm from 'components/Forms/IrrigationForm';
import PumpForm from 'components/Forms/PumpForm';
import WorkCost from 'components/Forms/WorkCost';
import WorkForm from 'components/Forms/WorkForm';
import KrishiService from 'services/krishiService';
import ParticipationForm from 'components/Forms/ParticipationForm';
import RenovationForm from 'components/Forms/RenovationForm';
import WaterSource from 'components/Forms/WaterSource';
import WaterConsumer from 'components/Forms/WaterConsumer';
import NearProjectForm from 'components/Forms/NearProjectForm';
import WaterExtraForm from 'components/Forms/WaterExtraForm';
import PrevIrrForm from 'components/Forms/PrevIrrForm';
import BenifiForm from 'components/Forms/BenifiForm';
import ProjectIrrForm from 'components/Forms/ProjectIrrForm';
import ConsumerForm from 'components/Forms/ConsumerForm';

const KrishiQuestions = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const applications = await KrishiService.getIrri();
        if (applications?.count > 0) {
          setActiveStep(2);
        }
        const workDetail = await KrishiService.getWork();
        if (workDetail?.count > 0) {
          setActiveStep(3);
        }
        const workCost = await KrishiService.getWorkCost();
        if (workCost?.count > 0) {
          setActiveStep(4);
        }
        const pumpDetail = await KrishiService.getPump();
        if (pumpDetail?.count > 0) {
          setActiveStep(5);
        }
        const participationDetail = await KrishiService.getContribution();
        if (participationDetail?.count > 0) {
          setActiveStep(6);
        }
        const renoDetail = await KrishiService.getRenovation();
        if (renoDetail?.count > 0) {
          setActiveStep(7);
        }
        const waterConsumer = await KrishiService.getWater();
        if (waterConsumer?.count > 0) {
          setActiveStep(8);
        }
        const nearProject = await KrishiService.getNear();
        if (nearProject?.count > 0) {
          setActiveStep(9);
        }
        const waterExtra = await KrishiService.getWaterExtra();
        if (waterExtra?.count > 0) {
          setActiveStep(10);
        }
        const waterSource = await KrishiService.getWaterSource();
        if (waterSource?.count > 0) {
          setActiveStep(11);
        }
        const prevIrr = await KrishiService.getPrevIrr();
        if (prevIrr?.count > 0) {
          setActiveStep(12);
        }
        const beni = await KrishiService.getBeni();
        if (beni?.count > 0) {
          setActiveStep(13);
        }
        const irrp = await KrishiService.getIrrProjects();
        if (irrp?.count > 0) {
          setActiveStep(14);
        }
        const consumer = await KrishiService.getConsumer();
        if (consumer?.count > 0) {
          setActiveStep(15);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {activeStep === 1 && <IrrigationForm setActiveStep={setActiveStep} />}
      {activeStep === 2 && <WorkForm setActiveStep={setActiveStep} />}
      {activeStep === 3 && <WorkCost setActiveStep={setActiveStep} />}
      {activeStep === 4 && <PumpForm setActiveStep={setActiveStep} />}
      {activeStep === 5 && <ParticipationForm setActiveStep={setActiveStep} />}
      {activeStep === 6 && <RenovationForm setActiveStep={setActiveStep} />}
      {activeStep === 7 && <WaterConsumer setActiveStep={setActiveStep} />}
      {activeStep === 8 && <NearProjectForm setActiveStep={setActiveStep} />}
      {activeStep === 9 && <WaterExtraForm setActiveStep={setActiveStep} />}
      {activeStep === 10 && <WaterSource setActiveStep={setActiveStep} />}
      {activeStep === 11 && <PrevIrrForm setActiveStep={setActiveStep} />}
      {activeStep === 12 && <BenifiForm setActiveStep={setActiveStep} />}
      {activeStep === 13 && <ProjectIrrForm setActiveStep={setActiveStep} />}
      {activeStep === 14 && <ConsumerForm setActiveStep={setActiveStep} />}
      {activeStep === 15 && <div>Form Submitted successfully</div>}
    </div>
  );
};

export default KrishiQuestions;
