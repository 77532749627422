import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import NepaliDate from 'nepali-date-converter';

import Banner from 'components/Banner';
import SearchAppBar from 'components/Appbar';
import { DataGrid } from '@material-ui/data-grid';
import Copyright from 'components/Copyright';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import SuchanaPrakashanService from 'services/suchanaPrakashan';

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#879565',
      color: '#ffffff',
      fontWeight: 'bold',
    },
  },
  table: {
    minWidth: 700,
  },
  titleContainer: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconBtn: {
    margin: '5px',
    padding: '5px',
    borderRadius: 10,
    fontSize: 16,
    '&:hover': {
      background: '#88A1ff',
    },
  },
});

const getColumns = (classes) => {
  return [
    {
      field: 'क्र.सं',
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <span>{params.row.id}</span>;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'विवरण',
      width: 725,
      sortable: false,
      filterable: false,
      renderCell: (_) => {
        return <span>{_.row.title}</span>;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'प्रकाशन मिति',
      width: 300,
      sortable: false,
      filterable: false,
      renderCell: (_, index) => {
        return <span>{new NepaliDate(new Date(_.row.created_at).getTime()).format('ddd, MMMM DD, YYYY', 'np')}</span>;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'विकल्प',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div>
            <a href={params.row.file_upload} target="_blank">
              <span className="fa fa-eye"></span>
            </a>
            &nbsp;&nbsp;
            <a href={params.row.file_upload} target="_blank" download>
              <span className="fa fa-download"></span>
            </a>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];
};

const Suchana = () => {
  const classes = useStyles();

  const [suchanaData, setSuchanaData] = useState([]);
  useEffect(() => {
    const fetchSuchanaData = async () => {
      const suchanas = await SuchanaPrakashanService.getAllSuchana();

      setSuchanaData(suchanas.results);
    };

    fetchSuchanaData();
  }, []);

  return (
    <>
      <Banner />
      <SearchAppBar title="दर्ता भएको डाटा" />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.titleContainer}>
            <h1>सुचना तथा समाचार</h1>
          </Grid>
        </Grid>
        <Grid item lg={12} md={9}>
          <div className={classes.root} style={{ height: 675, width: '100%', marginTop: 15 }}>
            {suchanaData ? (
              <DataGrid
                rows={suchanaData}
                columns={getColumns(classes)}
                pageSize={10}
                rowHeight={100}
                sortable={false}
              />
            ) : (
              <div style={{ fontWeight: 'bold', fontSize: '18px', color: '#888888' }}>लोड हुँदैछ...</div>
            )}
          </div>
        </Grid>
      </Container>
      <Copyright website="उदयपुरगढी गाउँपालिका" link="#" />
    </>
  );
};

export default Suchana;
