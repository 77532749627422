import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { FieldArray, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import PrastabanaService from 'services/prastabana';
import YouthService from 'services/youthService';
import { getUserInfo } from 'utils/decodeJWT';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const YouthGrantForm = ({ setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [projectList, setProjectList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await PrastabanaService.getData();
      setProjectList(res?.results);
    };
    fetchData();
  }, []);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };
  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          person: getUserInfo().person_id,
          progName: '',
          progArea: '',
          contact_number: '',
          progPlace: '',
          wanted_operate: '',
          place_of_business: '',
          invesment_of_grant: '',
          groupName: '',
          address: '',
          negative_impact_on_environment: false,
          ward_no: '',
          years_of_business: '',
          estbYear: '',
          related_business: '',
          months_of_business: '',
          your_family_labor: '',
          gavisa: '',
          female: '',
          marketable_quantity: '',
          your_investment: '',
          male: '',
          prastabana: '',
          dalit: '',
          janajati: '',
          amount_of_agricultural: '',
          others: '',
          labor_management: '',
          name_of_firm: '',
          products_produced_by_business: '',
        }}
        onSubmit={async (values) => {
          try {
            const response = await YouthService.addYouth(values);
            if (response?.id) {
              setActiveStep(2);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <TextField
                id="prastabana"
                name="prastabana"
                label="prastabana"
                fullWidth
                select
                required
                value={formik.values.prastabana}
                onChange={formik.handleChange}
                error={formik.touched.prastabana && Boolean(formik.errors.prastabana)}
                helperText={formik.touched.prastabana && formik.errors.prastabana}
              >
                {projectList &&
                  projectList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.project_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="address"
                name="address"
                label="ठेगाना"
                fullWidth
                required
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="wanted_operate"
                name="wanted_operate"
                label="संचालन गर्न खोजेको"
                fullWidth
                required
                value={formik.values.wanted_operate}
                onChange={formik.handleChange}
                error={formik.touched.wanted_operate && Boolean(formik.errors.wanted_operate)}
                helperText={formik.touched.wanted_operate && formik.errors.wanted_operate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="place_of_business"
                name="place_of_business"
                label="व्यवसाय र स्थान"
                fullWidth
                required
                value={formik.values.place_of_business}
                onChange={formik.handleChange}
                error={formik.touched.place_of_business && Boolean(formik.errors.place_of_business)}
                helperText={formik.touched.place_of_business && formik.errors.place_of_business}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="invesment_of_grant"
                name="invesment_of_grant"
                label="सहयोग स्वरुप माग गरेको अनुदान रकम/लगानी"
                fullWidth
                required
                type="number"
                value={formik.values.invesment_of_grant}
                onChange={formik.handleChange}
                error={formik.touched.invesment_of_grant && Boolean(formik.errors.invesment_of_grant)}
                helperText={formik.touched.invesment_of_grant && formik.errors.invesment_of_grant}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="your_investment"
                name="your_investment"
                label="आफ्नो लगानि"
                fullWidth
                required
                type="number"
                value={formik.values.your_investment}
                onChange={formik.handleChange}
                error={formik.touched.your_investment && Boolean(formik.errors.your_investment)}
                helperText={formik.touched.your_investment && formik.errors.your_investment}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="your_family_labor"
                name="your_family_labor"
                label="आफ्नो पारिवारिक श्रम"
                fullWidth
                required
                value={formik.values.your_family_labor}
                onChange={formik.handleChange}
                error={formik.touched.your_family_labor && Boolean(formik.errors.your_family_labor)}
                helperText={formik.touched.your_family_labor && formik.errors.your_family_labor}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="products_produced_by_business"
                name="products_produced_by_business"
                label="व्यवसायवाट उत्पादन हुने वस्तु"
                fullWidth
                required
                value={formik.values.products_produced_by_business}
                onChange={formik.handleChange}
                error={
                  formik.touched.products_produced_by_business && Boolean(formik.errors.products_produced_by_business)
                }
                helperText={formik.touched.products_produced_by_business && formik.errors.products_produced_by_business}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="amount_of_agricultural"
                name="amount_of_agricultural"
                label="उत्पादित कृषि बस्तुको परिमाण"
                fullWidth
                type="number"
                required
                value={formik.values.amount_of_agricultural}
                onChange={formik.handleChange}
                error={formik.touched.amount_of_agricultural && Boolean(formik.errors.amount_of_agricultural)}
                helperText={formik.touched.amount_of_agricultural && formik.errors.amount_of_agricultural}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="marketable_quantity"
                name="marketable_quantity"
                label="बजारयोग्य परिमाण"
                fullWidth
                type="number"
                required
                value={formik.values.marketable_quantity}
                onChange={formik.handleChange}
                error={formik.touched.marketable_quantity && Boolean(formik.errors.marketable_quantity)}
                helperText={formik.touched.marketable_quantity && formik.errors.marketable_quantity}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="related_business"
                name="related_business"
                label="बजारयोग्य परिमाण"
                fullWidth
                type="number"
                required
                value={formik.values.related_business}
                onChange={formik.handleChange}
                error={formik.touched.related_business && Boolean(formik.errors.related_business)}
                helperText={formik.touched.related_business && formik.errors.related_business}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.negative_impact_on_environment}
                    onChange={formik.handleChange}
                    name="negative_impact_on_environment"
                    color="primary"
                  />
                }
                label="व्यवसायले वातावरणमा नकारात्मक प्रभाव पार्छ / पार्दैन ?"
              />
              {formik.touched.negative_impact_on_environment && formik.errors.negative_impact_on_environment && (
                <div style={{ color: 'red', marginTop: '8px' }}>{formik.errors.negative_impact_on_environment}</div>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="name_of_firm"
                name="name_of_firm"
                label="फर्म,समुह, समिति वा सहकारीको नाम"
                fullWidth
                required
                value={formik.values.name_of_firm}
                onChange={formik.handleChange}
                error={formik.touched.name_of_firm && Boolean(formik.errors.name_of_firm)}
                helperText={formik.touched.name_of_firm && formik.errors.name_of_firm}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="contact_number"
                name="contact_number"
                label="सम्पर्क नम्वर"
                fullWidth
                required
                value={formik.values.contact_number}
                onChange={formik.handleChange}
                error={formik.touched.contact_number && Boolean(formik.errors.contact_number)}
                helperText={formik.touched.contact_number && formik.errors.contact_number}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="village"
                name="village"
                label="गाउँ/टोल"
                fullWidth
                required
                value={formik.values.village}
                onChange={formik.handleChange}
                error={formik.touched.village && Boolean(formik.errors.village)}
                helperText={formik.touched.village && formik.errors.village}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="ward_no"
                name="ward_no"
                label="वार्ड नं."
                fullWidth
                required
                type="number"
                value={formik.values.ward_no}
                onChange={formik.handleChange}
                error={formik.touched.ward_no && Boolean(formik.errors.ward_no)}
                helperText={formik.touched.ward_no && formik.errors.ward_no}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="gavisa"
                name="gavisa"
                label="गा.वि.स."
                fullWidth
                required
                value={formik.values.gavisa}
                onChange={formik.handleChange}
                error={formik.touched.gavisa && Boolean(formik.errors.gavisa)}
                helperText={formik.touched.gavisa && formik.errors.gavisa}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="years_of_business"
                name="years_of_business"
                label="व्यवसाय गरेको विगत कति वर्ष देखि"
                fullWidth
                required
                type="number"
                value={formik.values.years_of_business}
                onChange={formik.handleChange}
                error={formik.touched.years_of_business && Boolean(formik.errors.years_of_business)}
                helperText={formik.touched.years_of_business && formik.errors.years_of_business}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="months_of_business"
                name="months_of_business"
                label="व्यवसाय गरेको विगत कति वर्ष देखि"
                fullWidth
                required
                type="number"
                value={formik.values.months_of_business}
                onChange={formik.handleChange}
                error={formik.touched.months_of_business && Boolean(formik.errors.months_of_business)}
                helperText={formik.touched.months_of_business && formik.errors.months_of_business}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="district"
                name="district"
                label="जिल्ला व्यवसाय संचालन गरिने स्थान"
                fullWidth
                required
                value={formik.values.district}
                onChange={formik.handleChange}
                error={formik.touched.district && Boolean(formik.errors.district)}
                helperText={formik.touched.district && formik.errors.district}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="labor_management"
                name="labor_management"
                label="labor_management"
                fullWidth
                select
                required
                value={formik.values.labor_management}
                onChange={formik.handleChange}
                error={formik.touched.labor_management && Boolean(formik.errors.labor_management)}
                helperText={formik.touched.labor_management && formik.errors.labor_management}
              >
                {[
                  { label: 'पारिवारीक', id: 'पारिवारीक' },
                  { label: 'वाहिरबाट', id: 'वाहिरबाट' },
                  { label: 'दुवै', id: 'दुवै' },
                ].map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.id}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                  बुझाउनुहोस् &nbsp;
                  {isDisabled ? <CircularProgress size={30} /> : null}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default YouthGrantForm;
