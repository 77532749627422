export default {
  'बागमती प्रदेश': {
    सिन्धुली: [
      'दुधौली',
      'कमलामाई',
      'तिनपाटन',
      'मरिण',
      'हरिहरपुरगढी',
      'सुनकोशी',
      'गोलन्जोर',
      'फिक्कल',
      'घ्याङलेख',
    ],
    रामेछाप: [
      'मन्थली',
      'रामेछाप',
      'खाँडादेवी',
      'लिखु तामाकोशी',
      'दोरम्बा',
      'गोकुलगङ्गा',
      'सुनापती',
      'उमाकुण्ड',
    ],
    दोलखा: [
      'भीमेश्वर',
      'जिरी',
      'कालिन्चोक',
      'मेलुङ',
      'शैलुङ',
      'वैतेश्वर',
      'तामाकोशी',
      'विगु',
      'गौरिशंकर',
    ],
    भक्तपुर: ['मध्यपुर थिमी', 'भक्तपुर', 'सूर्यविनायक', 'चाँगुनारायण'],
    धादिङ: [
      'निलकण्ठ',
      'धुनीबेंशी',
      'थाक्रे',
      'बेनीघाट रोराङ्ग',
      'गल्छी',
      'गजुरी',
      'ज्वालामूखी',
      'सिद्धलेक',
      'त्रिपुरासुन्दरी',
      'गङ्गाजमुना',
      'नेत्रावती डबजोङ',
      'खनियाबास',
      'रुवी भ्याली',
    ],
    काठमाडौँ: [
      'काठमाडौँ',
      'बुढानिलकण्ठ',
      'गोकर्णेश्वर',
      'टोखा',
      'चन्द्रागिरी',
      'तारकेश्वर',
      'किर्तिपुर',
      'नागार्जुन',
      'कागेश्वरी मनोहरा',
      'शङ्खरापुर',
      'दक्षिणकाली',
    ],
    काभ्रेपलान्चोक: [
      'बनेपा',
      'पनौती',
      'पाँचखाल',
      'मण्डनदेउपुर',
      'धुलिखेल',
      'नमोबुद्ध',
      'रोशी',
      'तेमाल',
      'चौंरी देउराली',
      'भुम्लु',
      'महाभारत',
      'बेथानचोक',
      'खानीखोला',
    ],
    ललितपुर: [
      'ललितपुर',
      'गोदावरी',
      'महालक्ष्मी',
      'बाग्मती',
      'कोन्ज्योसोम',
      'महाङ्काल',
    ],
    नुवाकोट: [
      'विदुर',
      'बेलकोटगढी',
      'ककनी',
      'दुप्चेश्वर',
      'शिवपुरी',
      'तादी',
      'लिखु',
      'सुर्यगढी',
      'पञ्चकन्या',
      'तारकेश्वर',
      'किस्पाङ',
      'म्यागङ',
    ],
    रसुवा: ['नौकुण्ड', 'कालिका', 'उत्तरगया', 'गोसाईकुण्ड', 'आमाछोदिङमो'],
    सिन्धुपाल्चोक: [
      'चौतारा साँगाचोकगढी',
      'मेलम्ची',
      'बाह्रविसे',
      'ईन्द्रावती',
      'पाँचपोखरी थाङपाल',
      'जुगल',
      'बलेफी',
      'हेलम्बु',
      'भोटेकोशी',
      'सुनकोशी',
      'लिसंखु पाखर',
      'त्रिपुरासुन्दरी',
    ],
    चितवन: [
      'भरतपुर',
      'रत्ननगर',
      'राप्ती',
      'खैरहनी',
      'कालिका',
      'माडी',
      'इच्छाकामना',
    ],
    मकवानपुर: [
      'हेटौडा',
      'थाहा',
      'बकैया',
      'मनहरी',
      'बाग्मती',
      'राक्सिराङ्ग',
      'मकवानपुरगढी',
      'कैलाश',
      'भीमफेदी',
      'ईन्द्र सरोवर',
    ],
  },
};
