import React from 'react';

import TableWrapper from 'components/BaliPatroTable/TableWrapper';

const baliPatro = () => {
  return (
    <div>
      <TableWrapper />
    </div>
  );
};

export default baliPatro;
