import axios from 'axios';

import { makeMappings } from '../utils/convertCase';

const baseURI = process.env.REACT_APP_API_BASE_URI+'api/';
const url = baseURI + 'payeka-subidha/';

class PayekaSubidhaService {
  static async createSubidhaData(data, personId) {
    const accessToken = localStorage.getItem('access_token');

    const mappings = makeMappings(data);
    mappings.person = parseInt(personId);

    const response = await axios.post(url, mappings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}

export default PayekaSubidhaService;
