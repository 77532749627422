import { Grid, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useState } from 'react';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';
import RenderInput from './RenderInput';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const fields = [
  { id: 'application_name', label: 'आवेदन नाम', type: 'text' },
  { id: 'village', label: 'गाँउ नाम', type: 'text' },
  {
    id: 'name_of_program_offered',
    label: 'प्रस्तावित कार्यक्रमको नाम',
    type: 'select',
    options: [
      { label: 'Youth Grant', id: 'Youth Grant' },
      { label: 'Small Agriculture Grant', id: 'Small Agriculture Grant' },
    ],
  },
  { id: 'full_address_of_venue', label: 'कार्यक्रम संचालन हुने स्थानको पुरा ठेगाना', type: 'text' },
];

const getValidationSchema = (fields) => {
  const shape = {};
  fields.forEach((field) => {
    shape[field.id] = Yup.string().required(`${field.label} is required`);
  });
  return Yup.object().shape(shape);
};

const ApplicationForm = ({ setActiveStep, setApplicationDetails }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Grid item xs={12}>
        <h3>आवेदन</h3>
      </Grid>
      <Formik
        initialValues={{
          application_name: '',
          village: '',
          name_of_program_offered: null,
          status: 'In Progess',
          full_address_of_venue: '',
          person: getUserInfo().person_id,
        }}
        validationSchema={getValidationSchema(fields)}
        onSubmit={async (values) => {
          try {
            const response = await PrastabanaService.addApplication(values);
            if (response?.id) {
              setApplicationDetails(response);
              setActiveStep(2);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
      >
        {(formik) => <RenderInput fields={fields} formik={formik} isDisabled={isDisabled} />}
      </Formik>
    </div>
  );
};

export default ApplicationForm;
