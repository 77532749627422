import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const WithoutPerson = ({ nextPage }) => {
  const history = useHistory();

  const submit = () => {
    nextPage(0);

    history.push('/survey');
  };

  return (
    <div
      style={{
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '250px',
        width: '80%',
        margin: '15px auto',
      }}
    >
      <span className="fa fa-user-plus" style={{ fontSize: '28px', margin: '5px 0' }}></span>
      <p>व्यक्तिगत विवरण ईन्ट्री फारम बिना यो फारम भर्न असमर्थ</p>
      <br />
      <Button variant="outlined" color="primary" onClick={submit}>
        व्यक्तिगत ईन्ट्री फारम भर्नुहोस्
      </Button>
    </div>
  );
};

export default WithoutPerson;
