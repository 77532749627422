import React from 'react';

import { members } from 'constants/members';

const memberInfo = (name, image, designation, email, phone) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '25px',
      }}
      key={Math.random()}
    >
      <img
        src={image}
        alt={name}
        style={{
          height: '100px',
          objectFit: 'content',
          marginBottom: '10px',
          borderRadius: '50px',
          boxShadow: '3px 2px #cccccc',
        }}
        title={name}
      />
      <b style={{ color: '#11a', fontSize: '16px' }}>{name}</b>
      <span style={{ color: '#b83a55', fontWeight: 'light', fontSize: '15px' }}>{designation}</span>
      {email && <span style={{ color: '#217C9E', fontWeight: 'light', fontSize: '14px', margin: '2px 0px'}}>{email} <span className="fa fa-envelope ico" /></span>}
      {phone && <span style={{ color: '#217C9E', fontWeight: 'light', fontSize: '14px'}}>{phone} <span className="fa fa-phone ico" /></span>}
    </div>
  );
};

const MemberPic = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column'  }}>
      {members.janaPratinidhi.map(({ name, imageURL, designation, email, phone }) => {
        return memberInfo(name, imageURL, designation, email, phone);
      })}
    </div>
  );
};

export default MemberPic;
