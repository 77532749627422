import { Grid, TextField, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useState } from 'react';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const SinchaiQuestions = () => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };
  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          progName: '',
          progArea: '',
          progPlace: '',
          groupName: '',
          address: '',
          estbYear: '',
          female: '',
          male: '',
          dalit: '',
          janajati: '',
          others: '',
          budget: [
            {
              activities: '',
              akai: '',
              parimand: '',
              lagat: '',
              funded: '',
              otherBudget: '',
              kefiyat: '',
            },
          ],
          training: [
            {
              person: '',
              activity: '',
              shrawn: '',
              bhadra: '',
              ashoj: '',
              kartik: '',
              mangsir: '',
              poush: '',
              magh: '',
              falgun: '',
              chaitra: '',
              baisakh: '',
              jestha: '',
              ashar: '',
              remarks: '',
              monitoringAgency: '',
              supportingAgency: '',
              responsiblePerson: '',
            },
          ],
        }}
        onSubmit={(values) => {
          console.log('🚀 ~ values:', values);
        }}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h3>सिंचाई आयोजना सम्बन्धी आधारभूत जानकारी</h3>
            </Grid>
            <Grid item xs={12}>
              <h4>आयोजना सम्बन्धी विवरण </h4>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="name"
                name="name"
                label="प्रस्तावित आयोजनाको नाम"
                fullWidth
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="name"
                name="name"
                label="प्रस्तावित आयोजनाको किसिम नयाँ, नविकरण सुधार वा अन्य खुलाउने"
                fullWidth
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="name"
                name="name"
                label="प्रस्तावित आयोजनाको ठेगाना जिल्ला"
                fullWidth
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="name"
                name="name"
                label="नगर/गाउँपालिका"
                fullWidth
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="name"
                name="name"
                label="वडा नं."
                fullWidth
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="name"
                name="name"
                label="वर्तमान जल उपभोक्ताहरुको परिवार वा घरधुरी संख्या"
                fullWidth
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="name"
                name="name"
                label="थप सम्भाव्य परिवार वा घरधुरी संख्या"
                fullWidth
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <h4>समय र स्थान</h4>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="name"
                name="name"
                label="थप सम्भाव्य परिवार वा घरधुरी संख्या"
                fullWidth
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default SinchaiQuestions;
