import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import KrishiService from 'services/krishiService';
import { getUserInfo } from 'utils/decodeJWT';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const fields = [
  { id: 'additional_monsoon_cultivation_area', label: 'Additional Monsoon Cultivation Area', type: 'number' },
  { id: 'additional_seasonal_cultivation_area', label: 'Additional Seasonal Cultivation Area', type: 'number' },
  { id: 'additional_winter_cultivation_area', label: 'Additional Winter Cultivation Area', type: 'number' },
  { id: 'branch_canal_length', label: 'Branch Canal Length', type: 'number' },
  { id: 'construction_of_new_branch_canals', label: 'Construction of New Branch Canals' },
  {
    id: 'construction_of_structures_in_canal_and_other_places',
    label: 'Construction of Structures in Canal and Other Places',
  },
  { id: 'current_canal_length', label: 'Current Canal Length', type: 'number' },
  { id: 'dam_or_intake_construction', label: 'Dam or Intake Construction' },
  { id: 'increasing_capacity_or_lengthening_of_main_canal', label: 'Increasing Capacity or Lengthening of Main Canal' },
  {
    id: 'lack_of_activity_even_after_organization',
    label: 'Lack of Activity Even After Organization',
    type: 'boolean',
  },
  { id: 'lack_of_management_for_irrigation', label: 'Lack of Management for Irrigation', type: 'boolean' },
  { id: 'lack_of_technical_skills_and_knowledge', label: 'Lack of Technical Skills and Knowledge', type: 'boolean' },
  { id: 'large_amount_required', label: 'Large Amount Required', type: 'boolean' },
  { id: 'leakage_issues', label: 'Leakage Issues' },
  { id: 'lining_of_some_portions_of_main_canal', label: 'Lining of Some Portions of Main Canal' },
  { id: 'monsoon_cultivation_area', label: 'Monsoon Cultivation Area', type: 'number' },
  { id: 'necessary_structures_for_water_distribution', label: 'Necessary Structures for Water Distribution' },
  { id: 'number_of_structures', label: 'Number of Structures', type: 'integer' },
  { id: 'organizational_structure_absence', label: 'Organizational Structure Absence', type: 'boolean' },
  { id: 'other_problems', label: 'Other Problems' },
  { id: 'permanent_structure_issues', label: 'Permanent Structure Issues', type: 'boolean' },
  { id: 'problems_at_intake', label: 'Problems at Intake' },
  { id: 'proposed_branch_canal_length', label: 'Proposed Branch Canal Length', type: 'number' },
  { id: 'proposed_irrigated_area', label: 'Proposed Irrigated Area', type: 'number' },
  { id: 'proposed_main_canal_length', label: 'Proposed Main Canal Length', type: 'number' },
  { id: 'proposed_number_of_structures', label: 'Proposed Number of Structures', type: 'integer' },
  { id: 'river_control_at_intake', label: 'River Control at Intake' },
  { id: 'river_cutting_concerns', label: 'River Cutting Concerns', type: 'boolean' },
  { id: 'seasonal_cultivation_area', label: 'Seasonal Cultivation Area', type: 'number' },
  { id: 'total_irrigated_area', label: 'Total Irrigated Area', type: 'number' },
  {
    id: 'unsuccessful_attempts_despite_multiple_efforts',
    label: 'Unsuccessful Attempts Despite Multiple Efforts',
    type: 'boolean',
  },
  {
    id: 'unsuccessful_attempts_due_to_multiple_times',
    label: 'Unsuccessful Attempts Due to Multiple Times',
    type: 'boolean',
  },
  { id: 'water_shortage_issues', label: 'Water Shortage Issues' },
  { id: 'winter_cultivation_area', label: 'Winter Cultivation Area', type: 'number' },
];
const RenovationForm = ({ setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [projectList, setProjectList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await KrishiService.getIrri();
      setProjectList(res?.results);
    };
    fetchData();
  }, []);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          irrigation_application: projectList && projectList[0]?.id,

          additional_monsoon_cultivation_area: '',
          additional_seasonal_cultivation_area: '',
          additional_winter_cultivation_area: '',
          branch_canal_length: '',
          construction_of_new_branch_canals: '',
          construction_of_structures_in_canal_and_other_places: '',
          current_canal_length: '',
          dam_or_intake_construction: '',
          increasing_capacity_or_lengthening_of_main_canal: '',
          lack_of_activity_even_after_organization: false,
          lack_of_management_for_irrigation: false,
          lack_of_technical_skills_and_knowledge: false,
          large_amount_required: false,
          leakage_issues: '',
          lining_of_some_portions_of_main_canal: '',
          monsoon_cultivation_area: '',
          necessary_structures_for_water_distribution: '',
          number_of_structures: '',
          organizational_structure_absence: false,
          other_problems: '',
          permanent_structure_issues: false,
          problems_at_intake: '',
          proposed_branch_canal_length: '',
          proposed_irrigated_area: '',
          proposed_main_canal_length: '',
          proposed_number_of_structures: '',
          river_control_at_intake: '',
          river_cutting_concerns: false,
          seasonal_cultivation_area: '',
          total_irrigated_area: '',
          unsuccessful_attempts_despite_multiple_efforts: false,
          unsuccessful_attempts_due_to_multiple_times: false,
          water_shortage_issues: '',
          winter_cultivation_area: '',
          person: getUserInfo().person_id,
        }}
        onSubmit={async (values) => {
          try {
            const response = await KrishiService.addRenovation(values);
            if (response?.id) {
              setActiveStep(7);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            {fields.map((field) => {
              if (field?.type === 'boolean') {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.maintenance_fund_available}
                          onChange={formik.handleChange}
                          name="maintenance_fund_available"
                          color="primary"
                        />
                      }
                      label="रखरखाव कोष उपलब्ध छ"
                    />
                    {formik.touched.maintenance_fund_available && formik.errors.maintenance_fund_available && (
                      <div style={{ color: 'red', marginTop: '8px' }}>{formik.errors.maintenance_fund_available}</div>
                    )}
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <TextField
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      fullWidth
                      type={field.type === 'number' ? 'number' : 'text'}
                      value={formik.values[field.id]}
                      onChange={formik.handleChange}
                      error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                      helperText={formik.touched[field.id] && formik.errors[field.id]}
                    />
                  </Grid>
                );
              }
            })}
            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                  बुझाउनुहोस् &nbsp;
                  {isDisabled ? <CircularProgress size={30} /> : null}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default RenovationForm;
