import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import KrishiService from 'services/krishiService';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const fields = [
  { id: 'agreement_date', label: 'सम्झौताको मिति', type: 'date' },
  { id: 'bank_account_number', label: 'बैंक खाता नम्बर', type: 'text' },
  { id: 'completion_date', label: 'सम्पन्न हुने मिति', type: 'date' },
  { id: 'farmer_group_name', label: 'किसान समूहको नाम', type: 'text' },
  { id: 'first_party_address', label: 'पहिलो पार्टीको ठेगाना', type: 'text' },
  { id: 'first_party_name', label: 'पहिलो पार्टीको नाम', type: 'text' },
  { id: 'first_party_position', label: 'पहिलो पार्टीको पद', type: 'text' },
  { id: 'first_party_signature', label: 'पहिलो पार्टीको हस्ताक्षर', type: 'text' },
  { id: 'gaunpalika_name', label: 'गाउँपालिका नाम', type: 'text' },
  { id: 'second_party_address', label: 'दोश्रो पार्टीको ठेगाना', type: 'text' },
  { id: 'second_party_contribution', label: 'दोश्रो पार्टीको योगदान', type: 'number' },
  { id: 'second_party_name', label: 'दोश्रो पार्टीको नाम', type: 'text' },
  { id: 'second_party_position', label: 'दोश्रो पार्टीको पद', type: 'text' },
  { id: 'second_party_signature', label: 'दोश्रो पार्टीको हस्ताक्षर', type: 'text' },
  { id: 'total_expense', label: 'कुल खर्च', type: 'number' },
  { id: 'village_name', label: 'गाउँको नाम', type: 'text' },
  { id: 'ward_number', label: 'वडा नम्बर', type: 'number' },
];

const AdminAgreementPrastabana = ({ id, setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const userName = getUserInfo().username;

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          approved_by: userName,
          agreement_date: '',
          bank_account_number: '',
          completion_date: '',
          farmer_group_name: '',
          first_party_address: '',
          first_party_name: '',
          first_party_position: '',
          first_party_signature: '',
          gaunpalika_name: '',
          second_party_address: '',
          second_party_contribution: '',
          second_party_name: '',
          prastabana: id?.id,

          second_party_position: '',
          second_party_signature: '',
          total_expense: '',
          village_name: '',
          ward_number: '',
          person: getUserInfo().person_id,
        }}
        onSubmit={async (values) => {
          try {
            const response = await PrastabanaService.addPrastabanaAdminAgreement(values);
            if (response?.id) {
              setActiveStep(4);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            {fields.map((field) => {
              if (field?.type === 'boolean') {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.maintenance_fund_available}
                          onChange={formik.handleChange}
                          name={field.id}
                          color="primary"
                        />
                      }
                      label={field.label}
                    />
                    {formik.touched.maintenance_fund_available && formik.errors.maintenance_fund_available && (
                      <div style={{ color: 'red', marginTop: '8px' }}>{formik.errors.maintenance_fund_available}</div>
                    )}
                  </Grid>
                );
              } else if (field.type === 'select') {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <TextField
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      fullWidth
                      value={formik.values[field.id]}
                      onChange={formik.handleChange}
                      select
                      error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                      helperText={formik.touched[field.id] && formik.errors[field.id]}
                    >
                      {field.options?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} md={4} key={field.id}>
                    <TextField
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      fullWidth
                      type={field.type}
                      value={formik.values[field.id]}
                      onChange={formik.handleChange}
                      error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                      helperText={formik.touched[field.id] && formik.errors[field.id]}
                    />
                  </Grid>
                );
              }
            })}
            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                  बुझाउनुहोस् &nbsp;
                  {isDisabled ? <CircularProgress size={30} /> : null}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default AdminAgreementPrastabana;
