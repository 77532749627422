import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import KrishiService from 'services/krishiService';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const PumpForm = ({ setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [projectList, setProjectList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await KrishiService.getIrri();
      setProjectList(res?.results);
    };
    fetchData();
  }, []);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Formik
        initialValues={{
          irrigation_application: projectList && projectList[0]?.id,
          irrigated_area: '',
          land_approval_copy: false,
          maintenance_fund_available: false,
          pump_irrigation_issue: false,
          shed_house_required: false,
          water_distribution_distance: '',
          person: getUserInfo().person_id,
        }}
        onSubmit={async (values) => {
          try {
            const response = await KrishiService.addPump(values);
            if (response?.id) {
              setActiveStep(5);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="irrigated_area"
                name="irrigated_area"
                label="सिंचित क्षेत्र"
                fullWidth
                required
                type="number"
                value={formik.values.irrigated_area}
                onChange={formik.handleChange}
                error={formik.touched.irrigated_area && Boolean(formik.errors.irrigated_area)}
                helperText={formik.touched.irrigated_area && formik.errors.irrigated_area}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.land_approval_copy}
                    onChange={formik.handleChange}
                    name="land_approval_copy"
                    color="primary"
                  />
                }
                label="भूमि स्वीकृति प्रतिलिपि"
              />
              {formik.touched.land_approval_copy && formik.errors.land_approval_copy && (
                <div style={{ color: 'red', marginTop: '8px' }}>{formik.errors.land_approval_copy}</div>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.maintenance_fund_available}
                    onChange={formik.handleChange}
                    name="maintenance_fund_available"
                    color="primary"
                  />
                }
                label="रखरखाव कोष उपलब्ध छ"
              />
              {formik.touched.maintenance_fund_available && formik.errors.maintenance_fund_available && (
                <div style={{ color: 'red', marginTop: '8px' }}>{formik.errors.maintenance_fund_available}</div>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.pump_irrigation_issue}
                    onChange={formik.handleChange}
                    name="pump_irrigation_issue"
                    color="primary"
                  />
                }
                label="रखरखाव कोष उपलब्ध छ"
              />
              {formik.touched.pump_irrigation_issue && formik.errors.pump_irrigation_issue && (
                <div style={{ color: 'red', marginTop: '8px' }}>{formik.errors.pump_irrigation_issue}</div>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.shed_house_required}
                    onChange={formik.handleChange}
                    name="shed_house_required"
                    color="primary"
                  />
                }
                label="रखरखाव कोष उपलब्ध छ"
              />
              {formik.touched.shed_house_required && formik.errors.shed_house_required && (
                <div style={{ color: 'red', marginTop: '8px' }}>{formik.errors.shed_house_required}</div>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="water_distribution_distance"
                name="water_distribution_distance"
                label="पानी वितरण दूरी"
                fullWidth
                required
                type="number"
                value={formik.values.water_distribution_distance}
                onChange={formik.handleChange}
                error={formik.touched.water_distribution_distance && Boolean(formik.errors.water_distribution_distance)}
                helperText={formik.touched.water_distribution_distance && formik.errors.water_distribution_distance}
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonGroup color="primary" aria-label="primary button group">
                <Button onClick={formik.handleSubmit} disabled={isDisabled}>
                  बुझाउनुहोस् &nbsp;
                  {isDisabled ? <CircularProgress size={30} /> : null}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default PumpForm;
