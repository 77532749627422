import React, { useEffect, useState } from 'react';
import PrastabanaService from 'services/prastabana';

import ApplicationForm from 'components/Forms/ApplicationForm';

import ApplicationAttachedDocumentsForm from 'components/Forms/ApplicationAttachedDocumentsForm';
import CostSharingForm from 'components/Forms/CostSharingForm';
import ActionPlan from 'components/Forms/ActionPlan';
import OtherFacilities from 'components/Forms/OtherFacilities';
import ManPowerForm from 'components/Forms/ManPowerForm';
import ConductedEntryForm from 'components/Forms/ConducterEntryForm';
import ApplicationPhysicalForm from 'components/Forms/ApplicationPhysicalForm';
import DeclerationForm from 'components/Forms/DeclerationForms';

const PrastabanaQuestions = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [applicationDetails, setApplicationDetails] = useState(null);
  console.log('🚀 ~ PrastabanaQuestions ~ applicationDetails:', applicationDetails);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const applications = await PrastabanaService.getApplicationData();
      if (applications?.count > 0) {
        setActiveStep(2);
        setApplicationDetails(applications?.results[0]);
      }

      const res = await PrastabanaService.getApplicationAttachedDocuments();
      if (res?.count > 0) {
        setActiveStep(3);
      }
      const costRes = await PrastabanaService.getCostsharingEntries();
      if (costRes?.count > 0) {
        setActiveStep(4);
      }
      const actionRes = await PrastabanaService.getActionPlanEntries();
      if (actionRes?.count > 0) {
        setActiveStep(5);
      }
      const otherRes = await PrastabanaService.getOtherFacilitiesEntries();
      if (otherRes?.count > 0) {
        setActiveStep(6);
      }
      const manRes = await PrastabanaService.getManpowerEntries();
      if (manRes?.count > 0) {
        setActiveStep(7);
      }
      const conductedRes = await PrastabanaService.getConductedEntries();
      if (conductedRes?.count > 0) {
        setActiveStep(8);
      }
      const physical = await PrastabanaService.getPhysicalApi();
      if (physical?.count > 0) {
        setActiveStep(9);
      }
      const decelartion = await PrastabanaService.getDeclaration();
      if (decelartion?.count > 0) {
        setActiveStep(10);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <>
      <div>
        {activeStep === 1 && (
          <ApplicationForm setActiveStep={setActiveStep} setApplicationDetails={setApplicationDetails} />
        )}
        {activeStep === 2 && (
          <ApplicationAttachedDocumentsForm setActiveStep={setActiveStep} applicationDetails={applicationDetails} />
        )}
        {activeStep === 3 && <CostSharingForm setActiveStep={setActiveStep} applicationDetails={applicationDetails} />}
        {activeStep === 4 && <ActionPlan setActiveStep={setActiveStep} applicationDetails={applicationDetails} />}
        {activeStep === 5 && <OtherFacilities setActiveStep={setActiveStep} applicationDetails={applicationDetails} />}
        {activeStep === 6 && <ManPowerForm setActiveStep={setActiveStep} applicationDetails={applicationDetails} />}
        {activeStep === 7 && (
          <ConductedEntryForm setActiveStep={setActiveStep} applicationDetails={applicationDetails} />
        )}
        {activeStep === 8 && (
          <ApplicationPhysicalForm setActiveStep={setActiveStep} applicationDetails={applicationDetails} />
        )}
        {activeStep === 9 && <DeclerationForm setActiveStep={setActiveStep} applicationDetails={applicationDetails} />}
        {activeStep === 10 && <div>Submitted</div>}
      </div>
    </>
  );
};

export default PrastabanaQuestions;
