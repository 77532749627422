import React, { useState, useEffect } from 'react';

import { Container, Grid } from '@material-ui/core';
import SearchAppBar from 'components/Appbar';
import Banner from 'components/Banner';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import SamuhaSahakariFarmService from 'services/samuhaSahakariFarm';
import DataTable from './RawTable';
import Copyright from 'components/Copyright';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  titleContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const FarmTable = () => {
  const classes = useStyles();
  const [organizationEntry, setOrganizationEntry] = useState([]);
  const [selectedWardNo, setSelectedWardNo] = useState('सबै वार्ड नं');
  const [myWards, setMyWards] = useState([]);

  const groupByWard = (organization) => {
    const result = organization.reduce(function (r, a) {
      r[a.ward_no] = r[a.ward_no] || [];
      r[a.ward_no].push(a);
      return r;
    }, Object.create(null));

    return result;
  };

  useEffect(() => {
    const fetchData = async () => {
      const organization = await SamuhaSahakariFarmService.getData();
      const myResults = organization.results.filter((org) => org.category === 'फरम');
      const result = groupByWard(myResults);

      const organizationInfo = {
        'सबै वार्ड नं': myResults,
        ...result,
      };
      setOrganizationEntry(organizationInfo);
    };

    fetchData();
    const availableWards = Object.keys(organizationEntry);
    setMyWards(availableWards);
  }, []);

  return (
    <>
      <Banner />
      <SearchAppBar title="दर्ता भएको डाटा" />
      <Container maxWidth="lg">
        <Grid item xs={12} className={classes.titleContainer}>
          <h3>फार्म विवरण</h3>
          <FormControl style={{ width: 250 }}>
            <InputLabel id="land-type">वार्ड नम्बर चयन गर्नुहोस्</InputLabel>
            {myWards && (
              <Select labelId="land-type" value={selectedWardNo} onChange={(e) => setSelectedWardNo(e.target.value)}>
                {myWards.map((menu, index) => {
                  return (
                    <MenuItem value={menu} key={index}>
                      {menu}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </FormControl>
        </Grid>
        <Grid item lg={12} md={9}>
          <div style={{ height: 425, width: '100%' }}>
            {organizationEntry[selectedWardNo] ? (
              <DataTable rows={organizationEntry[selectedWardNo]} />
            ) : (
              <div style={{ fontWeight: 'bold', fontSize: '18px', color: '#888888' }}>लोड हुँदैछ...</div>
            )}
          </div>
        </Grid>
        <br />
      </Container>
      <Copyright website="उदयपुरगढी गाउँपालिका" link="#" />
    </>
  );
};

export default FarmTable;
