import axios from 'axios';

const baseURI = process.env.REACT_APP_API_BASE_URI+'api/';
const url = baseURI + 'suchana-prakashan';

class SuchanaPrakashanService {
  static async getAllSuchana() {
    const response = await axios.get(`${url}/suchana/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getAllPrakashan() {
    const response = await axios.get(`${url}/prakashan/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getPrakashanList() {
    const response = await axios.get(`${url}/prakashan-category/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getPhotos() {
    const response = await axios.get(`${url}/carousel-photos/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    return response.data;
  }
}

export default SuchanaPrakashanService;
