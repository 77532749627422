import { Grid, makeStyles } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { Formik } from 'formik';
import React, { useState } from 'react';
import PrastabanaService from 'services/prastabana';
import { getUserInfo } from 'utils/decodeJWT';
import * as Yup from 'yup';
import RenderInput from '../RenderInput';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const fields = [
  { id: 'physical_capacity', label: 'भौतिक क्षमता', type: 'number', min: 0, max: 30 },
  {
    id: 'program_location_ownership',
    label: 'कार्यक्रम कार्यान्वयन स्थलको जग्गा वा भवन आवेदकको आफ्नै स्वमित्वको छ/ छैन',
    type: 'boolean',
  },
  {
    id: 'adequate_space_for_program',
    label: 'प्रस्तावित कार्यक्रम संचालन गर्न प्रयाप्त जग्गा छ/ छैन',
    type: 'boolean',
  },
  {
    id: 'potential_expansion_space',
    label: 'भविष्यमा विस्तारका लागि जग्गा उपलब्ध हुनसक्ने सम्भावना छ/ छैन',
    type: 'boolean',
  },
  { id: 'flood_resilience', label: 'बाढी पहिरोले क्षति नपुग्ने अवस्था छ/ छैन', type: 'boolean' },
  {
    id: 'utilities_availability',
    label: 'कार्यक्रम कार्यान्वयन स्थलमा बाटो, पानी,बिजुलीको सुबिधा छ / छैन',
    type: 'boolean',
  },
  {
    id: 'suitability_by_geography',
    label: 'भौगोलिक हिसावले उक्त स्थानमा प्रस्तावित कार्यक्रम संचालन गर्न उपयुक्त छ /छैन',
    type: 'boolean',
  },
  { id: 'technical_capacity', label: 'प्राबिधिक क्षमता', type: 'number', min: 0, max: 15 },
  {
    id: 'alignment_with_program',
    label: 'प्रस्तावित कार्यक्रम संग मेल खाने क्रियाकलापमा हाल संग्लनता छ /छैन ',
    type: 'boolean',
  },
  {
    id: 'availability_of_necessary_services',
    label: 'आवश्यक प्राबिधिक सेवा टेवा प्राप्त हुन सक्ने अवस्था छ/ छैन',
    type: 'boolean',
  },
  {
    id: 'applicant_experience_and_training',
    label: 'आवेदक सम्बन्धित बिषयको तालिम अनुभब प्राप्त छ/ छैन',
    type: 'boolean',
  },
  { id: 'financial_capacity', label: 'वित्तीय क्षमता', type: 'number', min: 0, max: 15 },
  {
    id: 'ability_to_contribute_cost_share',
    label: 'आवेदक लागत साझेदारी गर्न सक्ने आर्थिक अवस्थामा छ/ छैन',
    type: 'boolean',
  },
  {
    id: 'availability_of_financial_institution_loan',
    label: 'साझेदारीका लागि बैंक मौज्दात वा संचालित कारोबारको बचत छ / छैन',
    type: 'boolean',
  },
  {
    id: 'possibility_to_obtain_loan_from_financial_institution',
    label: 'आवश्यक रकम बित्तिय संस्थाबाट ऋण प्राप्त गर्न सक्ने अवस्था छ छैन ',
    type: 'boolean',
  },
  { id: 'suitability_for_environment', label: 'वाताबरनीय उपयुक्तता', type: 'number', min: 0, max: 10 },
  {
    id: 'absence_of_local_opposition',
    label: 'प्रस्तावित कार्यक्रम कर्यन्युअन गर्दा स्थानीय बासिन्दाबाट विरोद नहुने अवस्था छ छैन',
    type: 'boolean',
  },
  {
    id: 'absence_of_negative_environmental_impact',
    label: 'प्रस्तावित कार्यक्रमले वाताबरणमा नकारात्मक असर पुग्ने अवस्था छ छैन',
    type: 'boolean',
  },
  { id: 'other_aspects', label: 'अन्य पक्षहरु', type: 'number', min: 0, max: 20 },
  { id: 'professional_business_conduct', label: 'संस्था / व्यक्तिको व्यवसायिक क्रियासिलता छ छैन ', type: 'boolean' },
  { id: 'registration_and_compliance', label: 'दर्ता , नवीकरण , अडिट र करचुक्कता अध्यवादिक छ छैन', type: 'boolean' },
  {
    id: 'availability_of_raw_materials',
    label: 'आवश्यक कच्चा पदार्थ प्राप्त हुन सक्ने अवस्था छ / छैन',
    type: 'boolean',
  },
  { id: 'ease_of_marketing_of_produced_goods', label: 'उत्पादित बस्तुको सहज बजारीकरण हुन', type: 'boolean' },
  {
    id: 'consistency_between_proposal_and_site_condition',
    label: 'प्रस्तावमा उल्लेखित कुरा र स्थलगत आस्था बिच समानता छ / छैन',
    type: 'boolean',
  },
  { id: 'appropriateness_of_cost_sharing_with_applicant', label: 'आवेदक संग लागत साझेदारी गर्न उपयु', type: 'boolean' },
  { id: 'total_marks', label: 'जम्मा', type: 'number', min: 0, max: 100 },
];

const ApplicationEvaluation = ({ id, setActiveStep }) => {
  const classes = useStyles();
  const [isSuccessDialog, setIsSuccessDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSuccessDialog(false);
  };

  const getValidationSchema = (fields) => {
    const shape = {};

    fields.forEach((field) => {
      if (field.type === 'number' && typeof field.min === 'number' && typeof field.max === 'number') {
        shape[field.id] = Yup.number()
          .required(`${field.label} is required`)
          .min(field.min, `${field.label} must be at least ${field.min}`)
          .max(field.max, `${field.label} must be at most ${field.max}`);
      } else {
        shape[field.id] = Yup.string().required(`${field.label} is required`);
      }
    });

    return Yup.object().shape(shape);
  };

  return (
    <div className={classes.root}>
      <AlertDialog isSuccess={isSuccessDialog} open={openDialog} handleClose={handleDialogClose} />
      <Grid item xs={12}>
        <h3>व्यवसाय योजना मूल्यांकनका आधार</h3>
      </Grid>
      <Formik
        initialValues={{
          application: id,
          physical_capacity: '',
          program_location_ownership: false,
          adequate_space_for_program: false,
          potential_expansion_space: false,
          flood_resilience: false,
          utilities_availability: false,
          suitability_by_geography: false,
          technical_capacity: '',
          alignment_with_program: false,
          availability_of_necessary_services: false,
          applicant_experience_and_training: false,
          financial_capacity: '',
          ability_to_contribute_cost_share: false,
          availability_of_financial_institution_loan: false,
          possibility_to_obtain_loan_from_financial_institution: false,
          suitability_for_environment: '',
          absence_of_local_opposition: false,
          absence_of_negative_environmental_impact: false,
          other_aspects: '',
          professional_business_conduct: false,
          registration_and_compliance: false,
          availability_of_raw_materials: false,
          ease_of_marketing_of_produced_goods: false,
          consistency_between_proposal_and_site_condition: false,
          appropriateness_of_cost_sharing_with_applicant: false,
          total_marks: '',
          person: getUserInfo().person_id,
        }}
        validationSchema={getValidationSchema(fields)}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            const response = await PrastabanaService.addApplicantEvaluationView(values);
            if (response?.id) {
              setActiveStep(5);
            }
            setIsSuccessDialog(true);
            setOpenDialog(true);
          } catch (e) {
            setIsDisabled(false);
            setIsSuccessDialog(false);
            setOpenDialog(true);
          }
        }}
      >
        {(formik) => <RenderInput fields={fields} formik={formik} isDisabled={isDisabled} />}
      </Formik>
    </div>
  );
};

export default ApplicationEvaluation;
