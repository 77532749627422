import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoginAndValidToken } from 'utils/login';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to home page
    <Route {...rest} render={(props) => (isLoginAndValidToken() ? <Component {...props} /> : <Redirect to="/" />)} />
  );
};

export default PrivateRoute;
