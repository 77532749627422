// list of sewa pradayak

const sewaPradayakItems = {
  dairy: {
    title: 'डेरी',
    possible_values: ['dairy', 'Dairy', 'डेरी'],
    icon: 'https://images.unsplash.com/photo-1516518691269-6d1e18187234?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  agrovet: {
    title: 'एग्रोभेट',
    possible_values: ['Agro Vet', 'agro vet', 'Agro vet', 'Agro-vet', 'Agro-Vet', 'agrovet', 'एग्रो-भेट', 'एग्रोभेट'],
    icon: 'http://silvapharma.com/wp-content/uploads/2019/07/agro.jpg',
  },
  sahakari: {
    title: 'सहकारी',
    possible_values: ['sahakari', 'Co-operatives', 'co-operatives', 'cooperatives', 'Cooperatives', 'सहकारी', 'स.स.लि.', 'स‍‌‌.स.लि.', 'स. स. लि .', 'स. स. लि.'],
    icon: 'https://images.unsplash.com/photo-1524486361537-8ad15938e1a3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  chisyan_kendra: {
    title: 'चिस्यान केन्द्र',
    possible_values: ['चिस्यान केन्द्र', 'Chisyan Kendra', 'chisyan kendra'],
    icon: 'https://live.staticflickr.com/7803/32433791507_6f67a7e993_b.jpg',
  },
  farm: {
    title: 'फार्म',
    possible_values: ['farm', 'Farm', 'फारम', 'फार्म'],
    icon: 'https://images.unsplash.com/photo-1548460468-0e6f57732e8e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  maasu_pasal: {
    title: 'मासु पसल',
    possible_values: ['मासु पसल', 'मासु', 'मिट', 'मीट'],
    icon: 'https://images.unsplash.com/photo-1606677661991-446cea8ee182?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  tarkari_pasal: {
    title: 'तरकारी पसल',
    possible_values: ['तरकारी', 'vegetable'],
    icon: 'https://images.unsplash.com/photo-1610636996379-4d184e2ef20a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
  godam_ghar: {
    title: 'गोदाम',
    possible_values: ['godam', 'godaam', 'गोदाम'],
    icon: 'https://live.staticflickr.com/8293/7798749870_7ecf667d5e_b.jpg',
  },
  nursery: {
    title: 'नर्सरी',
    possible_values: ['nursery', 'nursary', 'नर्सरी', 'नरसरी', 'फूल', 'फुल'],
    icon: 'https://images.unsplash.com/photo-1598902468171-0f50e32a7bf2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=345&q=80',
  },
};

export default sewaPradayakItems;
