import React from 'react';

import {Container} from '@material-ui/core';

import Banner from 'components/Banner';
import Copyright from 'components/Copyright';
import SearchAppBar from 'components/Appbar';

import SamuhaSahakariFarmForm from 'components/Questions/SamuhaSahakariFarm';

export default () => {
	return <div>
		<Banner />
	    <SearchAppBar title="कृषि तथा पशुपालनमा सक्रिय सेवा प्रदायक (समूह/सहकारी/फरम)को विवरण"/>
	    <Container style={{width: 1024, marginTop: 50,}}>
	    	<SamuhaSahakariFarmForm />
	    </Container>
	    <Copyright website="उदयपुरगढी गाउँपालिका" />
    </div>
}
